import React, { useState, useEffect, useCallback, useContext } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useAccount, useProvider } from "wagmi";
import FilterSelect from "../components/myEnsPage/FilterSelect";
import MyEnsTable from "../components/myEnsPage/MyEnsTable";
import { getMyEns } from "../api/requests";
import { resolveOwnerAddress } from '../utils/ens-service';
import { APIContext } from '../utils/APIContext';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import MembershipStatus from "../components/myEnsPage/MembershipStatus";
import { EnsProfileInfo } from "../components/myEnsPage/EnsProfileInfo";
import { MyPinnedEnsList } from "../components/myEnsPage/MyPinnedEnsList";

const textFieldStyle = {
  width: '100%',
  background: '#FBFBFB',
  height: '40px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '19px',
  color: '#5F5A5A',
}

const textFieldInputProps = {
  style: {
    width: '100%',
    background: '#FBFBFB',
    height: '40px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#5F5A5A',
  }
}

const selectStyle = {
  background: '#FBFBFB',
  height: '40px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '19px',
  color: '#5F5A5A',
}

const MyEnsPageV2 = () => {
  const { myAddress: myAddressParams } = useParams();
  const { address, isConnected, isReconnecting, status, connector, isConnecting, isDisconnected } = useAccount();
  // console.log(isConnected, isReconnecting, status, connector, isConnecting, isDisconnected)
  const { openConnectModal } = useConnectModal();
  // const [myRealAddress, setMyRealAddress] = useState();
  const [membershipDetails, setMembershipDetails] = useState({});
  const [isBulkListingModalOpen, setIsBulkListingModalOpen] = useState(false);
  const provider = useProvider();
  const navigate = useNavigate();
  const { myEns } = useContext(APIContext);
  const [paramsAddress, setParamsAddress] = useState()

  useEffect(() => {
    let searchaddress = myAddressParams || address
    setParamsAddress(searchaddress)

    if (window.location.pathname.startsWith('/my-ens')) {
      navigate(window.location.pathname.replace('/my-ens', '/ens'))
    }
    else {
      if (searchaddress) {
        navigate(`/ens/${searchaddress}`)
      } else {
        navigate(`/ens/`)
      }
    }
  }, [myAddressParams, address, navigate])

  const SortFilterValues = {
    "Name (A-Z)": "name_A",
    "Name (Z-A)": "name_D",
    "Price (↓)": "price_D",
    "Price (↑)": "price_A",
    "Expires (↓)": "registrationExpiresAt_D",
    "Expires (↑)": "registrationExpiresAt_A",
    "Last Sale Price (↓)": "lastSalePrice_D",
    "Last Sale Price (↑)": "lastSalePrice_A",
  };
  const [ensName, setEnsName] = useState("");

  let [searchParams, setSearchParams] = useSearchParams();
  const [searchNameScop, setSearchNameScop] = useState(
    searchParams.get("startsWith")?.length ? "start_with" :
      searchParams.get("endsWith")?.length ? "end_with" :
        searchParams.get("notContain")?.length ? "not_contain" :
          "contains"
  );
  const [searchCriteria, setSearchCriteria] = useState({
    club: searchParams.get("club") ? searchParams.get("club") : "all",
    contains: searchParams.get("contains") ? searchParams.get("contains") : "",
    startsWith: searchParams.get("startsWith") ? searchParams.get("startsWith") : "",
    endsWith: searchParams.get("endsWith") ? searchParams.get("endsWith") : "",
    notContain: searchParams.get("notContain") ? searchParams.get("notContain") : "",
    sortBy: searchParams.get("sortBy") ? searchParams.get("sortBy") : "name",
    sortDirection: searchParams.get("sortDirection") || 1,
    limitPerPage:
      searchParams.get("limitPerPage") === 50
        ? searchParams.get("limitPerPage")
        : 50,
  });

  const [clubs, setClubs] = useState([]);
  const [allClubs, setAllClubs] = useState([]);
  const [myEnsNames, setMyEnsNames] = useState([]);
  const [myEnsProfileData, setMyEnsProfileData] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [myEnsCount, setMyEnsCount] = useState(0)
  const [myEnsListingCount, setMyEnsListingCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [productLoading, setProductLoading] = useState(true);
  const [searchCriteriaValue] = useDebounce(searchCriteria, 1000);
  const [showSelectedTable, setShowSelectedTable] = useState(false);

  const handlePageClick = async (event, value) => {
    setCurrentPage(value);
  };

  const getParamsQueryStr = useCallback(() => {
    const _searchCriteria = { ...searchCriteria };

    const newParams = {
      ...(_searchCriteria?.club && { club: _searchCriteria?.club }),
      ...(_searchCriteria?.contains && { contains: _searchCriteria?.contains }),
      ...(_searchCriteria?.startsWith && { startsWith: _searchCriteria?.startsWith }),
      ...(_searchCriteria?.endsWith && { endsWith: _searchCriteria?.endsWith }),
      ...(_searchCriteria?.notContain && { notContain: _searchCriteria?.notContain }),
      // ...(_searchCriteria?.status && { status: _searchCriteria?.status }),
      ...(_searchCriteria?.sortBy && { sortBy: _searchCriteria?.sortBy }),
      ...(_searchCriteria?.sortDirection && {
        sortDirection: _searchCriteria?.sortDirection,
      }),
      ...(_searchCriteria?.page && { page: currentPage }),
      ...(_searchCriteria?.limitPerPage && {
        limitPerPage: 50,
      }),
    }
    const queryStr = new URLSearchParams(newParams).toString()
    return queryStr;
  }, [currentPage, searchCriteria])

  const fetchMyEnsNames = useCallback(async () => {
    setProductLoading(true);
    try {
      if (!paramsAddress) {
        setProductLoading(false);
        return;
      };
      let theAddress = paramsAddress;
      if (!paramsAddress.toLowerCase().startsWith('0x')) {
        const resolveAddress = await resolveOwnerAddress(paramsAddress.replace('-eth', '.eth'));
        if (resolveAddress) {
          theAddress = resolveAddress.ownerAddress;
        }
      }
      setParamsAddress(theAddress)
      // setMyRealAddress(theAddress);
      const { data } = await getMyEns(theAddress, searchCriteria, currentPage);
      const queryStr = getParamsQueryStr()
      if (paramsAddress?.toLowerCase().startsWith('0x') && ensName && !ensName.startsWith('0x')) {
        window.history.replaceState(null, "Name Apes - ENS & .eth Domains Marketplace with over 300 ENS Clubs", `/ens/${ensName.replace('.eth', '-eth')}?${queryStr}`)
      } else {
        window.history.replaceState(null, "Name Apes - ENS & .eth Domains Marketplace with over 300 ENS Clubs", `${window.location.pathname}?${queryStr}`)
      }
      setMyEnsNames(data?.myEns);
      setMyEnsCount(data?.totalAllData);
      setMyEnsListingCount(data?.listingCount)
      setMyEnsProfileData(data?.profiles)
      setpageCount(data?.totalPages);
      setMembershipDetails({
        isMember: data?.isMember,
        memberSince: data?.memberSince
      })
      setAllClubs(data?.allClubs)
      const clubsObj = {}
      data?.allClubs?.forEach(f => {
        clubsObj[`${f?.name} (${f?.totalNames})`] = f?.slug
      })

      setClubs({
        [`All (${data?.totalAllData})`]: "all",
        [`Uncategorized (${data?.totalUncategorized})`]: "uncategorized",
        ...clubsObj
      });
    } catch (e) {
      console.log(e.message);
    } finally {
      setProductLoading(false);
    }
  }, [paramsAddress, searchCriteria, currentPage, getParamsQueryStr, ensName]);

  useEffect(() => {
    async function lookupAddress(address) {
      if (!address) {
        return address;
      }

      const lookupName = await provider.lookupAddress(address);
      if (lookupName) {
        setEnsName(lookupName);
      }
    }

    if (paramsAddress) {
      if (paramsAddress.startsWith('0x')) {
        lookupAddress(paramsAddress);
      } else {
        setEnsName(paramsAddress)
      }
    }
  }, [paramsAddress, provider]);

  const checkIfConnected = useCallback(() => {
    if (!isConnected && !isReconnecting && !myAddressParams && !isConnecting) {
      openConnectModal();
    }
  }, [isConnected, isReconnecting, myAddressParams, isConnecting, openConnectModal])

  useEffect(() => {
    if (myAddressParams?.toLowerCase().startsWith('0x') && ensName && !ensName?.toLowerCase().startsWith('0x')) {
      const queryStr = getParamsQueryStr()
      window.history.replaceState(null, "Name Apes - ENS & .eth Domains Marketplace with over 300 ENS Clubs", `/ens/${ensName.replace('.eth', '-eth')}?${queryStr}`)
    }
  }, [myAddressParams, ensName, navigate, getParamsQueryStr])

  useEffect(() => {
    fetchMyEnsNames();
  }, [currentPage, searchCriteriaValue, paramsAddress, fetchMyEnsNames]);

  useEffect(() => {
    checkIfConnected();
  }, [productLoading, paramsAddress, checkIfConnected])

  const onSelectedChanged = (row) => {
    const updatedMyEnsNames = [...myEnsNames];
    for (let i = 0; i < updatedMyEnsNames.length; i += 1) {
      if (row.token === updatedMyEnsNames[i].token) {
        updatedMyEnsNames[i].isChecked = !updatedMyEnsNames[i].isChecked;
      }
    }
    setMyEnsNames(updatedMyEnsNames);
  };

  const handleFilterChange = (searchCriteriaValue) => {
    setCurrentPage(1)
    setSearchCriteria(searchCriteriaValue);
  }

  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: "100%",
        alignItems: "center",
        flexDirection: "column",
        margin: "auto",
        width: { xs: "100%", md: "65%", xl: "50%" }
      }}
    >
      <EnsProfileInfo ensName={ensName} paramsAddress={paramsAddress} membershipDetails={membershipDetails} myEnsCount={myEnsCount} myEnsListingCount={myEnsListingCount} allClubs={allClubs} />
      <MyPinnedEnsList ensPinData={myEnsProfileData} />

      <MembershipStatus style={{ marginBottom: "20px" }} isMember={!!myEns?.isMember} isMyPage={address?.toLowerCase() === paramsAddress?.toLowerCase()} />
      <Box sx={{ width: "100%", textAlign: "right" }}>
        {showSelectedTable && (
          <>
            <Typography sx={{ textAlign: "right" }}>
              You have selected {myEnsNames.filter((m) => m.isChecked).length}{" "}
              tokens for bulk listing.
            </Typography>
            <button
              style={{ marginRight: "20px" }}
              onClick={() => setIsBulkListingModalOpen(true)}
            >
              Continue
            </button>
            <button onClick={() => setShowSelectedTable(false)}>
              Cancel
            </button>
          </>
        )}
        {false && !showSelectedTable && (
          <Button onClick={() => setShowSelectedTable(true)}
            sx={{
              width: "200px",
              height: "52px",
              background: "linear-gradient(91.87deg, #88b1fd -5.48%, #d190fc 100%)",
              borderRadius: "4px",
              border: "none",
              textAlign: "center",
              color: "#ffffff",
              fontWeight: "bold",
            }}>
            Bulk List
          </Button>
        )}
      </Box>

      <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 2, md: 3 }} mb={4}>
        <Grid item xs={6} lg={3}>
          <TextField
            style={textFieldStyle}
            InputProps={textFieldInputProps}
            label="Name"
            placeholder='Name'
            InputLabelProps={{ shrink: true }}
            variant="outlined"

            value={
              searchNameScop ? (
                searchCriteria?.contains ||
                searchCriteria?.notContain ||
                searchCriteria?.startsWith ||
                searchCriteria?.endsWith) : searchCriteria?.contains
            }
            onChange={(e) => {
              const _searchCriteria = { ...searchCriteria }
              const inputValue = e.target.value

              if (searchNameScop === "start_with")
                _searchCriteria.startsWith = inputValue;
              else if (searchNameScop === "end_with")
                _searchCriteria.endsWith = inputValue;
              else if (searchNameScop === "not_contain")
                _searchCriteria.notContain = inputValue;
              else
                _searchCriteria.contains = inputValue;

              setSearchCriteria(_searchCriteria)
              _searchCriteria.page = 1;
            }}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <FormControl fullWidth >
            <InputLabel id="scop-select-label">Scope</InputLabel>
            <Select
              labelId="scop-select-label"
              label="Scope"
              sx={selectStyle}

              value={searchNameScop}
              onChange={(e) => {
                const _searchCriteria = { ...searchCriteria }

                const nameScope = e.target.value;
                const valueScope = searchCriteria?.contains || searchCriteria?.notContain || searchCriteria?.startsWith || searchCriteria?.endsWith || "";

                setSearchNameScop(nameScope);
                _searchCriteria.startsWith = "";
                _searchCriteria.endsWith = "";
                _searchCriteria.contains = "";
                _searchCriteria.notContain = "";

                if (nameScope === "start_with")
                  _searchCriteria.startsWith = valueScope;
                else if (nameScope === "end_with")
                  _searchCriteria.endsWith = valueScope;
                else if (nameScope === "not_contain")
                  _searchCriteria.notContain = valueScope;
                else
                  _searchCriteria.contains = valueScope

                _searchCriteria.page = 1;
                setSearchCriteria(_searchCriteria)
              }}
            >
              <MenuItem value="contains">Contains</MenuItem>;
              <MenuItem value="not_contain">Not Contain</MenuItem>;
              <MenuItem value="start_with">Starts With</MenuItem>;
              <MenuItem value="end_with">Ends With</MenuItem>;
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={3}>
          <FilterSelect
            selectName={"club"}
            selectValues={clubs}
            searchCriteria={searchCriteria}
            setSearchCriteria={handleFilterChange}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <FilterSelect
            selectName={"Sort By"}
            selectValues={SortFilterValues}
            searchCriteria={searchCriteria}
            setSearchCriteria={handleFilterChange}
          />
        </Grid>
      </Grid>

      <MyEnsTable
        MyEnsNames={myEnsNames}
        pageCount={pageCount}
        productLoading={productLoading}
        handlePageClick={handlePageClick}
        showSelected={showSelectedTable}
        onSelectedChanged={onSelectedChanged}
        paramsAddress={paramsAddress}
        currentPage={currentPage}
      />
    </Box>
  );
};

export default MyEnsPageV2;