import { useState, useEffect, useMemo, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, DialogActions, Typography } from "@mui/material";
import Iconify from '../iconify'
import styled from "styled-components";
import moment from 'moment';
import { generateTokenDetailUrl } from "../../utils/token-detail";
import { Tooltip } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import { CartContext } from "../../utils/CartContext";
import lg from "../assets/NameApes-icon.png";
import { BsArrowDownCircle } from "react-icons/bs";
import { FaArrowCircleDown } from "react-icons/fa";
import { FiArrowDownCircle } from "react-icons/fi";

const PStyled = styled.p`
  margin-bottom: 2px;
`

const PStyledSmaller = styled(PStyled)`
  font-size: 13px;
`

const PStyledBold = styled(PStyled)`
  font-weight: bold;
`;

const marketPriceCircle = (orderbook, price, name) => {
  let url = '';

  if (orderbook === 'reservoir') {
    url = lg;
  } else if (orderbook === 'opensea') {
    url = '/opensea.svg'
  } else if (orderbook === 'looks-rare') {
    url = 'https://raw.githubusercontent.com/reservoirprotocol/indexer/v5/src/models/sources/looksrare-logo.svg'
  } else if (orderbook === 'x2y2') {
    url = "https://raw.githubusercontent.com/reservoirprotocol/indexer/v5/src/models/sources/x2y2-logo.png"
  }

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }}>
      <Box sx={{
        width: "20px",
        height: "20px",
        lineHeight: "20px",
        borderRadius: "50%",
        fontSize: "9px",
        color: "#fff",
        textAlign: "center",
        background: orderbook === 'reservoir' ? "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)" : "#000",
        marginRight: "5px",
        backgroundImage: orderbook === 'reservoir' ? "" : `url(${url})`,
        backgroundSize: orderbook === 'reservoir' ? "" : "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
        {orderbook === 'reservoir' && (
          <img src={lg} style={{ width: "15px", height: "15px", borderRadius: "50%" }} />
        )}
      </Box>
      <Tooltip title={parseFloat(price).toFixed(10)} placement="top" tri arrow>
        <Box>
          <span>{name}</span>
          <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>{parseFloat(price).toFixed(4)}Ξ</span>
        </Box>
      </Tooltip>
    </Box>
  )
}

const EthIcon = () => {
  return (
    <svg
      style={{ height: "16px", width: "16px", display: "inline-block" }}
      viewBox="0 0 33 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3576 0.666687L16.0095 1.85009V36.1896L16.3576 36.5371L32.2976 27.115L16.3576 0.666687Z"
        fill="#343434"
      />
      <path
        d="M16.3578 0.666687L0.417816 27.115L16.3578 36.5372V19.8699V0.666687Z"
        fill="#8C8C8C"
      />
      <path
        d="M16.3575 39.5552L16.1613 39.7944V52.0268L16.3575 52.6L32.307 30.1378L16.3575 39.5552Z"
        fill="#3C3C3B"
      />
      <path
        d="M16.3578 52.5998V39.5551L0.417816 30.1377L16.3578 52.5998Z"
        fill="#8C8C8C"
      />
      <path
        d="M16.3575 36.537L32.2973 27.1151L16.3575 19.8699V36.537Z"
        fill="#141414"
      />
      <path
        d="M0.417816 27.1151L16.3576 36.537V19.8699L0.417816 27.1151Z"
        fill="#393939"
      />
    </svg>
  )
}

const BulkSellingDialog = ({
  open,
  setOpen,
  numberOfItems,
  totalListItems,
  totalEthPrice,
  totalUsdPrice,
  status,
  tx,
  setCartModalOpen,
  sellItems,
  tokensData,
  nonPurchasedItems,
  currentItemToSign,
  ethToUsdRate,
  isLowerPrice,
  onStopBulkList,
  allItemsToSign,
  marketplacesToApprove,
  handleCloseBag,
}) => {
  const cart = useContext(CartContext);
  const scollToRef = useRef(null);
  // const [totalItems, setTotalItems] = useState();
  const [ethPrice, setEthPrice] = useState();
  const [usdPrice, setUsdPrice] = useState();
  const [items, setItems] = useState();
  const [tData, setTData] = useState();
  const getValidItems = () => {
    const compare = (a, b) => a.token.tokenId === b;
    const validItems = tData?.filter((i) => items.some((d) => compare(i, d)));
    return validItems;
  };
  const handleClose = (event, reason) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
      return;
    }
    onStopBulkList();
    setOpen(false);
  };

  const getMarketFee = (item) => {
    const { orderbook } = item || {};
    if (orderbook === 'reservoir') {
      return `${item.naFee * 100}%`
    } else if (orderbook === 'opensea') {
      return '2.5%';
    } else if (orderbook === 'looks-rare') {
      return '2%'
    } else if (orderbook === 'x2y2') {
      return '0.5%'
    } else {
      return '1.5%'
    }
  }

  const getMarketFeeAndPrice = (item) => {
    const { orderbook, price, osPrice, lrPrice, x2y2Price } = item || {};
    if (orderbook === 'reservoir') {
      return { fee: item.naFee, price }
    } else if (orderbook === 'opensea') {
      return { fee: 0.025, price };
    } else if (orderbook === 'looks-rare') {
      return { fee: 0.02, price };
    } else if (orderbook === 'x2y2') {
      return { fee: 0.005, price };
    } else {
      return { fee: item.naFee, price }
    }
  }

  const calculateNAFeePercentage = (club) => {
    return ['999 Club', '10k-club', '10k Club', '999-club'].includes(club) ? 0 : 0.015
  }

  const calculateNet = (club, price) => {
    const fee = calculateNAFeePercentage(club);
    return (parseFloat(price) - (parseFloat(price) * fee)).toFixed(3);
  }

  const calculateSale = (orderbook, price, club) => {
    const net = calculateNet(club, price);
    if (orderbook === 'reservoir') {
      return price;
    } else if (orderbook === 'opensea') {
      return net * 1.025;
    } else if (orderbook === "looks-rare") {
      return net * 1.020
    } else if (orderbook === "x2y2") {
      return net * 1.020
    } else {
      return price;
    }
  }

  const navigate = useNavigate();
  // const { data, isError, isLoading } = useTransaction({
  //   hash: "0x22fc040c5336909fcf710a93e388843786e014337c8bd9799d20b096fdf3b826",
  // });
  // console.log(data.value._hex);
  useEffect(() => {
    setTimeout(() => {
      // setTotalItems(numberOfItems);
      // setTotalItems(totalListItems);
      setEthPrice(totalEthPrice);
      setUsdPrice(totalUsdPrice);
      setItems(sellItems);
      setTData(tokensData);
    }, 1000);
  }, [open]);

  const title = useMemo(() => {
    let theTitle = 'Preparing Sale';

    if (status.listing !== 'complete' && status.listing !== 'processing') {
      theTitle = 'Preparing Sale';
    } else if (status.listing === 'processing') {
      theTitle = `${currentItemToSign && currentItemToSign[0]?.sequence || 1} of ${totalListItems} listings`;
    } else if (status.listing === 'complete') {
      theTitle = `${totalListItems} of ${totalListItems} listed`
    }
    return theTitle;
  }, [status, currentItemToSign, totalListItems])

  const getMarketplaceNameByOrderbook = (orderbook) => {
    let market = 'NameApes';
    if (orderbook === 'reservoir') {
      market = 'NameApes';
    } else if (orderbook === 'opensea') {
      market = 'Opensea';
    } else if (orderbook === 'looks-rare') {
      market = 'LooksRare'
    } else if (orderbook === 'x2y2') {
      market = 'X2Y2'
    }
    return market;
  }

  const generateIconProgress = (progress) => {
    let icon = <Iconify
      icon={`material-symbols:check-circle`}
      sx={{ width: 24, height: 24, marginRight: "4px", color: '#E8E8E8' }}
    />
    if (progress === 'processing' || progress === 'preparing' || progress === 'approving') {
      icon = <Iconify
        icon={`material-symbols:change-circle-rounded`}
        sx={{ width: 24, height: 24, marginRight: "4px", color: '#6b97f6' }}
      />
    } else if (progress === 'complete') {
      icon = <Iconify
        icon={`material-symbols:check-circle`}
        sx={{ width: 24, height: 24, marginRight: "4px", color: '#32CE5E' }}
      />
    } else if (progress === 'failed') {
      icon = <Iconify
        icon={`material-symbols:do-not-disturb-on`}
        sx={{ width: 24, height: 24, marginRight: "4px", color: '#ba3329' }}
      />
    }
    return icon;
  }

  const goToTokenDetail = (item) => {
    const url = generateTokenDetailUrl({
      token: item.token.split(':')[1],
      name: item.name,
    })
    window.open(url);
    return url;
  }

  const generateApprovalProgressText = (status, marketplace) => {
    let text = `Checking ${marketplace} contract`;
    if (status === 'approving') {
      text = `Please approve ${marketplace} contract`
    }

    return text;
  }
  const onSectionScroll = () => {
    const scrollTop = scollToRef.current.scrollTop;
    scollToRef.current.scrollTop = scrollTop + scollToRef.current.clientHeight - 20;
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="purchase-dialog"
        PaperProps={{
          sx: {
            position: { lg: "absolute", xs: "relative" }, right: { lg: "700px", xs: "unset" }
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            background: "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
            padding: "10px 20px",
            alignItems: "center",
            height: "68px",
          }}
        >
          <DialogTitle id="alert-dialog-title"
            sx={{
              display: "flex",
              fontSize: "20px",
              color: "#FFFFFF",
              fontWeight: "500",
            }}
          >
            {title}
            {status.listing !== 'complete' && status.nftApproval === 'complete' &&
              <Typography>{tokensData?.token}</Typography>
            }
          </DialogTitle>
          <Button
            onClick={handleClose}
            sx={{
              fontSize: "20px",
              color: "#FFFFFF",
              fontWeight: "500",
              padding: "0px",
              height: "27px",
              minWidth: "unset",
            }}
          >
            &times;
          </Button>
        </Box>
        <DialogContent
          sx={{
            padding: "20px 15px 0px", position: "relative",
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,.2)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.2)'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(136,177,253,1)',
            }
          }}
          ref={scollToRef}
        >
          {(status.listing === 'incomplete' || status.listing === 'preparing') && (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "flex-start",
                  marginBottom: "15px",
                  marginTop: "10px",
                  overflowY: "auto",
                }}
              >
                <Box sx={{
                  marginBottom: '10px',
                  width: '100%'
                }}>
                  {generateIconProgress(status.preflight)}{`Rechecking ${isLowerPrice ? 1 : cart.sellBagItems?.length} names`}
                </Box>
                <Box sx={{
                  marginBottom: '10px',
                  width: '100%'
                }}>
                  {generateIconProgress(status.connectingWallet)}{`Connecting to wallet`}
                </Box>
                {/* <Box sx={{
                  marginBottom: '10px',
                  width: '100%'
                }}>
                    {generateIconProgress(status.nftApproval)}{`Contract Approval (${marketplacesToApprove?.join(', ')})`}
                </Box> */}
                {marketplacesToApprove?.map((m) => (
                  <Box sx={{
                    marginBottom: '10px',
                    width: '100%'
                  }}>
                    {generateIconProgress(status.nftApproval === 'complete' ? 'complete' : status[`nftApproval_${m}`])}{generateApprovalProgressText(status.nftApproval === 'complete' ? 'complete' : status[`nftApproval_${m}`], m)}
                  </Box>
                ))}
                <Box sx={{
                  marginBottom: '10px',
                  width: '100%'
                }}>
                  {generateIconProgress(status.listing)}{`Sign ${numberOfItems} listings for ${isLowerPrice ? 1 : cart.sellBagItems?.length} names on wallet`}
                </Box>
              </Box>
            </Box>
          )}

          {(status.listing === 'processing' && !currentItemToSign) && (
            <Box>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: 300,
                }}
              />
            </Box>
          )}

          {(status.listing === 'processing' && currentItemToSign) && (
            <>
              {
                currentItemToSign?.map((item, index) => (
                  <>
                    <Box style={{ marginBottom: '20px' }}>
                      <Typography
                        variant="h4"
                        sx={{
                          textAlign: 'center',
                          marginBottom: '5px',
                        }}
                      >
                        {
                          !item?.name
                            ? `${item?.token?.slice(
                              0,
                              5
                            )}...${item?.token?.slice(
                              item?.token?.length - 3,
                              item?.token?.length
                            )}`
                            : item?.name
                        }
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          wordBreak: 'break-word',
                          fontSize: '13px',
                          textAlign: 'center',
                          mb: '10px',
                          padding: { md: '0px 40px', xs: '0px 10px' }
                        }}
                      >
                        {item?.token?.split(':')[1]}
                        <Iconify
                          icon={"material-symbols:file-copy-outline"}
                          sx={{ width: 20, height: 20, marginLeft: "5px", cursor: 'pointer' }}
                          onClick={() => {
                            navigator.clipboard.writeText(item?.token?.split(':')[1]);
                          }}
                        />
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: "46%",
                            background: "#F4F4F4",
                            borderRadius: "5px",
                            padding: { md: "15px", xs: "10px" }
                          }}>
                            <PStyled>Sale</PStyled>
                            <Tooltip title={item?.price} placement="top" tri arrow>
                              <PStyledBold style={{ fontSize: "16px" }}>{parseFloat(item?.price).toFixed(4)} <EthIcon /></PStyledBold>
                            </Tooltip>
                            <PStyledSmaller>~${(item?.price * ethToUsdRate).toFixed(2)}</PStyledSmaller>
                          </Box>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: "46%",
                            background: "#F4F4F4",
                            borderRadius: "5px",
                            padding: { md: "15px", xs: "10px" }
                          }}>
                            <PStyled>List on</PStyled>
                            <PStyledBold>{getMarketplaceNameByOrderbook(item?.orderbook)}</PStyledBold>
                            <PStyledSmaller>for <strong>{item ? moment(parseInt(item?.duration), "X").fromNow().replace('in', '') : "-"}</strong></PStyledSmaller>
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: { sm: '20px', xs: "10px" }, justifyContent: 'space-around' }}>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: "46%",
                            background: "#F4F4F4",
                            borderRadius: "5px",
                            padding: { md: "15px", xs: "10px" }
                          }}>
                            <PStyled>Net</PStyled>
                            <Tooltip title={getMarketFeeAndPrice(item).price * (1-getMarketFeeAndPrice(item).fee)} placement="top" tri arrow>
                              <PStyledBold>{parseFloat(getMarketFeeAndPrice(item).price * (1-getMarketFeeAndPrice(item).fee)).toFixed(4)} <EthIcon /></PStyledBold>
                            </Tooltip>
                            <PStyledSmaller>~${(getMarketFeeAndPrice(item).price * (1-getMarketFeeAndPrice(item).fee) * ethToUsdRate).toFixed(2)}</PStyledSmaller>
                          </Box>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: "46%",
                            background: "#F4F4F4",
                            borderRadius: "5px",
                            padding: { md: "15px", xs: "10px" }
                          }}>
                            <PStyled>Fee</PStyled>
                            <PStyledBold>{getMarketFee(item)}</PStyledBold>
                            <PStyledSmaller>{getMarketplaceNameByOrderbook(item?.orderbook)}</PStyledSmaller>
                          </Box>
                        </Box>
                      </Box>

                    </Box>
                  </>))}

            </>
          )}

          {status.listing === 'complete' && (
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>All items listed</Typography>
                <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {allItemsToSign?.map((item) => (
                    <Box onClick={() => goToTokenDetail(item)}
                      style={{ padding: '10px', borderRadius: '20px', border: '1px solid lightgray', fontSize: '13px', marginRight: '20px', marginBottom: '15px', cursor: 'pointer' }}>
                      {/* <span>{item?.name}</span>
                      <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>{parseFloat(item?.price).toFixed(4)} Ξ  </span> */}
                      {marketPriceCircle(item.orderbook, item.price, item.name)}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box
                style={{
                  // marginTop: '30px',
                  background: "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                  height: "68px",
                  borderRadius: "4px",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  cursor: 'pointer',
                  color: '#FFFFFF',
                  fontSize: "18px",
                }}
                onClick={() => {
                  navigate(isLowerPrice ? goToTokenDetail(allItemsToSign[0]) : "/ens");
                  handleClose();
                  handleCloseBag();
                }}
              >
                {isLowerPrice ? 'Token Detail' : 'My ENS'}
              </Box>
            </Box>
          )}

        </DialogContent >
        {(status.listing === 'processing' && currentItemToSign) && (
          <DialogActions sx={{ justifyContent: "space-between", px:"15px" }}>
            <Box style={{ marginTop: '10px' }}>
              <Typography variant="subtitle1" sx={{ textAlign: 'center' }} gutterBottom>Please approve this listing on your wallet. No gas required.</Typography>
            </Box>
            <Box sx={{
              cursor: "pointer",
              color: "#5d5d5d",
              '&:hover': {
                color: "#88B1FD",
              },
              zIndex: 999,
            }} onClick={() => onSectionScroll()}
            >
              <FiArrowDownCircle size={30} />
            </Box>
          </DialogActions>
        )}
      </Dialog >
    </>
  );
};

export default BulkSellingDialog;