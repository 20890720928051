import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const ExpandableClubView = ({ clubs }) => {

    const [isExpand, setIsExpand] = useState(false);
    const filteredClubs = clubs;
    const VISIBLE_COUNT = 3
    const minItems = filteredClubs && filteredClubs?.length > 0 ? Math.min(filteredClubs?.length, 4) : 1;
    return (
        <div className="club-img-block" style={{
            minWidth: `calc((${minItems} - 1) * 3px + 35px * ${minItems})`
        }}
        >
            {
                filteredClubs &&
                filteredClubs?.length > 0 &&
                <>
                    {filteredClubs?.map((i, position) => {
                        if (!isExpand && position > (VISIBLE_COUNT - 1)) return <></>

                        return (
                            <>
                                {i !== "null" && i !== null && i && (
                                    <div className="club-img">
                                        <Link
                                            reloadDocument
                                            style={{
                                                textDecoration: 'none',
                                            }}
                                            to={{
                                                pathname: "/" + i,
                                                search: `?club=${i}`
                                                // search: `?club=All&period=7`
                                            }}
                                        >
                                            <img
                                                src={
                                                    "https://ens-production.s3.amazonaws.com/" +
                                                    i +
                                                    ".jpg"
                                                }
                                                alt={i}
                                                width={35}
                                            />
                                            <span>{i}</span>
                                        </Link>
                                    </div>
                                )}
                            </>
                        );
                    })}

                    {filteredClubs.length > VISIBLE_COUNT &&
                        <div
                            onClick={() => {
                                const _isExpand = !isExpand
                                setIsExpand(_isExpand)
                            }}
                            style={{
                                cursor: "pointer",
                                width: "35px",
                                height: "35px",
                                background: "rgb(107 178 235 / 20%)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "5px"
                            }}>
                            {!isExpand && "+" + (filteredClubs.length - VISIBLE_COUNT)}
                            {isExpand && <i class="fa fa-sort-asc" ></i>}

                        </div>
                    }
                </>

            }

            {(!filteredClubs[0] || !filteredClubs || !filteredClubs?.length) && (
                <>
                    <div className="club-img">
                        <Link
                            style={{
                                textDecoration: 'none',
                            }}
                            to={{
                                pathname: "/All/sold",
                            }}
                        >
                            <img
                                src={
                                    "https://ens-production.s3.amazonaws.com/uncategorized.jpg"
                                }
                                alt={"Uncategorized"}
                                width={35}
                            />
                            <span>{"Uncategorized"}</span>
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
}

export default ExpandableClubView;