import { ETHEREUM_NETWORK } from "../config";

export const getReservoirApiUrl = () => {
  // console.log('ETHEREUM_NETWORK', ETHEREUM_NETWORK)
  let apiUrl = "";
  switch (ETHEREUM_NETWORK) {
    case "mainnet":
      apiUrl = "https://api.reservoir.tools";
      break;
    case "goerli":
      apiUrl = "https://api-goerli.reservoir.tools";
      break;
    default:
      apiUrl = "";
      break;
  }

  // console.log('apiurl', apiUrl)
  return apiUrl;
};

export * as EnsService from "./ens-service";
export * as CheckoutServices from "./checkout-services";
export * as registrationService from "./registration-service";
export * from "./misc-utils.service";
