import { BigNumber, Contract, ethers } from "ethers";
import { bulkRegistrationTestnet } from "../configurations/contract-addresses-testnet";
import { bulkRegistrationMainnet } from "../configurations/contract-addresses-mainnet.enum";
import { ENSBulkRegistrationContractTestnetABI } from "../configurations/ens-bulk-reg-contract-abi-testnet";
import { ENSBulkRegistrationContractMainnetABI } from "../configurations/ens-bulk-registration-mainnet.abi";
import { getNameLength } from "./ens-service";
import { generalConfigurations } from "../configurations/general-configs";
import { ETHEREUM_NETWORK } from "../config";

const payNoMarketAddress = "0x0000000000000000000000000000000000000000";
const REGISTER_GAS = 175000;
const REGISTER_CONFIG_GAS = 300000;
const COMMIT_GAS = 25000;
const COMMIT_SINGLE_GAS = 55000;
export function registrationDomainsToCommitmentPacket(
  owner,
  duration,
  secret,
  resolver,
  commitments
) {
  let compiled = [];
  // console.log(duration);
  for (const c of commitments) {
    // const nameDuration = duration.find(
    //   (n) => n.labelName === c.labelName
    // ).duration;
    const nc = {
      name: c.labelName,
      owner: owner,
      duration: BigNumber.from(duration),
      secret: secret,
      resolver: resolver,
      data: [],
      reverseRecord: resolver !== payNoMarketAddress ? true : false,
      fuses: 0,
      wrapperExpiry: 0,
      metadata: c,
    };
    compiled.push(nc);
  }
  return compiled;
}

export async function createRegistrationCommitmentPacket(
  commitments,
  payer,
  provider,
  signer
) {
  const contract = await getEnsBulkRegistrationContract(signer);
  const comitmentsPrepared = [];
  for (const c of commitments) {
    const nCP = [
      c.name,
      c.owner,
      c.duration,
      c.secret,
      c.resolver,
      c.data,
      c.reverseRecord,
      c.fuses,
      c.wrapperExpiry,
    ];
    nCP.name = c.name;
    nCP.owner = c.owner;
    nCP.duration = c.duration;
    nCP.secret = c.secret;
    nCP.resolver = c.resolver;
    nCP.data = c.data;
    nCP.reverseRecord = c.reverseRecord;
    nCP.fuses = c.fuses;
    nCP.wrapperExpiry = c.wrapperExpiry;
    comitmentsPrepared.push(nCP);
  }
  const isResolverSet = comitmentsPrepared[0].resolver !== payNoMarketAddress;
  const duration = comitmentsPrepared[0].duration;
  let commitmentResult;
  let gasLimit;
  let createCommitments;
  if (ETHEREUM_NETWORK === "goerli") {
    createCommitments = contract.createCommitmentsForRegistration(
      comitmentsPrepared,
      duration
    );
  } else if (ETHEREUM_NETWORK === "mainnet") {
    createCommitments = contract.createCommitmentsForRegistration(
      comitmentsPrepared,
      duration,
      isResolverSet
    );
  }
  return createCommitments
    .then(async (r) => {
      commitmentResult = r;
      gasLimit = await getGasLimitEstimation(
        provider,
        "requestRegistration",
        [commitmentResult[0]],
        payer,
        bulkRegistrationContractAddress(),
        BulkRegistrationContractABI()
      ).catch((e) => {
        gasLimit = ethers.BigNumber.from(comitmentsPrepared.length).mul(
          comitmentsPrepared.length === 1 ? COMMIT_GAS : COMMIT_SINGLE_GAS
        );
        return gasLimit;
      });
      gasLimit = BigNumber.from(
        ethers.utils.parseUnits(
          (ethers.utils.formatEther(gasLimit) * 1.05).toFixed(18),
          18
        )
      );
      return await contract
        .requestRegistration(commitmentResult[0], {
          gasLimit,
        })
        .catch((e) => {
          throw e;
        });
    })
    .then((r) => {
      return [
        {
          commitments: commitmentResult[0],
          priceRanges: commitmentResult[1].map((pr) =>
            pr.mul(generalConfigurations.maxTotalCostBuffer).div(100)
          ),
        },
        r,
      ];
    })
    .catch((e) => {
      console.log(e);
      throw e;
    });
}

export async function createRegistrationRequestPaymentPacket(
  commitmentsPrepared,
  provider
) {
  const contract = await getEnsBulkRegistrationContract(provider);
  const dataInput = contract.interface.encodeFunctionData(
    "requestRegistration",
    [commitmentsPrepared]
  );
  return dataInput;
}

export async function completeRegistration(
  commitments,
  payer,
  // priceRanges,
  totalCost,
  provider,
  signer
) {
  const contract = await getEnsBulkRegistrationContract(signer);
  const names = [];
  const namesLengths = [];
  const secret = commitments[0].secret;
  const resolver = commitments[0].resolver;
  const owner = commitments[0].owner;
  const duration = commitments[0].duration;
  for (const c of commitments) {
    names.push(c.name);
    namesLengths.push(getNameLength(c.name));
  }
  let dataMethod =
    commitments[0].resolver === payNoMarketAddress
      ? "completeRegistration"
      : "completeRegistrationWithConfigs";
  let gasLimit;
  // gasLimit = ethers.BigNumber.from(names.length * "200000");
  let dataParams =
    commitments[0].resolver === payNoMarketAddress
      ? [names, owner, duration, secret]
      : [names, owner, duration, secret, resolver];
  if (ETHEREUM_NETWORK === "goerli") {
    dataMethod = "completeRegistration";
    dataParams = [names, owner, duration, secret, commitments];
  }

  const latestGasLimit = await provider.getBlock("latest");

  gasLimit = await getGasLimitEstimation(
    provider,
    dataMethod,
    dataParams,
    payer,
    bulkRegistrationContractAddress(),
    BulkRegistrationContractABI(),
    false,
    totalCost
  ).catch((e) => {
    if (names.length === 1) {
      gasLimit =
        resolver === payNoMarketAddress
          ? ethers.BigNumber.from(350000)
          : ethers.BigNumber.from(300000);
    } else {
      gasLimit =
        resolver === payNoMarketAddress
          ? ethers.BigNumber.from(names.length).mul(REGISTER_CONFIG_GAS)
          : ethers.BigNumber.from(names.length).mul(REGISTER_GAS);
    }
    // console.log(Number(latestGasLimit.gasLimit?._hex));
    // console.log(gasLimit);
    // console.log(Number(gasLimit?._hex));
    return gasLimit;
  });
  // console.log(Number(gasLimit?._hex));
  if (names.length <= 100) {
    gasLimit = BigNumber.from(
      ethers.utils.parseUnits(
        (ethers.utils.formatEther(gasLimit) * 1.07).toFixed(18),
        18
      )
    );
  } else {
    gasLimit = BigNumber.from(
      ethers.utils.parseUnits(
        (ethers.utils.formatEther(gasLimit) * 1.2).toFixed(18),
        18
      )
    );
  }
  gasLimit =
    gasLimit > Number(latestGasLimit.gasLimit?._hex)
      ? latestGasLimit.gasLimit
      : gasLimit;
  // console.log(Number(gasLimit));

  let completeReg;
  try {
    if (ETHEREUM_NETWORK === "goerli") {
      completeReg = await contract.completeRegistration(
        names,
        owner,
        duration,
        secret,
        commitments,
        { value: totalCost, gasLimit }
      );
    } else if (ETHEREUM_NETWORK === "mainnet") {
      if (commitments[0].resolver === payNoMarketAddress) {
        completeReg = await contract.completeRegistration(
          names,
          owner,
          duration,
          secret,
          { value: totalCost, gasLimit }
        );
      } else {
        completeReg = await contract.completeRegistrationWithConfigs(
          names,
          owner,
          duration,
          secret,
          resolver,
          { value: totalCost, gasLimit }
        );
      }
    }
  } catch (e) {
    console.log(e);
    throw e;
  }

  return [completeReg, gasLimit];
}

export async function getGasLimitEstimation(
  provider = null,
  method,
  params,
  payer,
  contractAddress = null,
  contractABI = null,
  providerFunction = false,
  value = "0x0"
) {
  let c;
  if (
    providerFunction === false &&
    (contractAddress === null || contractABI === null)
  ) {
    return false;
  }
  if (providerFunction === false) {
    c = new Contract(contractAddress, contractABI, provider);
  }
  if (providerFunction === false) {
    return await c.estimateGas[method](...params, {
      value,
      from: payer,
    })
      .then((r) => {
        // console.log(r);
        if (r === null) {
          return false;
        }
        return r;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  } else {
    return await provider
      .estimateGas(params)
      .then((r) => {
        if (r === null) {
          return false;
        }
        return r;
      })
      .catch((e) => {
        throw e;
      });
  }
}

export function getEnsBulkRegistrationContract(provider = null) {
  const c = new Contract(
    bulkRegistrationContractAddress(),
    BulkRegistrationContractABI(),
    provider
  );
  return c;
}

export function BulkRegistrationContractABI() {
  if (ETHEREUM_NETWORK === "goerli") {
    return ENSBulkRegistrationContractTestnetABI;
  }
  return ENSBulkRegistrationContractMainnetABI;
  // return ENSBulkRegistrationContractTestnetABI;
}

export function bulkRegistrationContractAddress() {
  if (ETHEREUM_NETWORK === "goerli") {
    return bulkRegistrationTestnet;
  }
  return bulkRegistrationMainnet;
  // return bulkRegistrationTestnet;
}
