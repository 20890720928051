import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Navigation } from "swiper";
import { Box, Stack, Typography } from '@mui/material';
import { generateTokenDetailUrl } from '../../utils/token-detail';
import { Link } from 'react-router-dom';
import { MarketPriceCircle } from '../common/MarketPriceCircle';
import useWindowDimensions from '../useWindowDimensions';

export const MyPinnedEnsList = ({ ensPinData }) => {
    const { width } = useWindowDimensions()
    return (
        <>
            {
                ensPinData.length > 0 &&
                <>
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={15}
                        grid={{
                            rows: ensPinData?.length < 3 ? 1 : ensPinData?.length < 5 ? 2 : 3,

                        }}
                        slidesPerGroup={2}
                        navigation={{
                            enabled: true,
                        }}
                        modules={[Grid, Navigation]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                grid: {
                                    rows: ensPinData?.length < 2 ? 1 : ensPinData?.length < 3 ? 2 : 3,
                                },
                                slidesPerGroup: 1,
                                spaceBetween: 10,
                                navigation: {
                                    enabled: true,
                                }
                            },
                            640: {
                                slidesPerView: 2,
                                grid: {
                                    rows: ensPinData?.length < 3 ? 1 : ensPinData?.length < 5 ? 2 : 3,
                                },
                                slidesPerGroup: 2,
                                spaceBetween: 10,
                                navigation: {
                                    enabled: true,
                                }
                            },
                            1024: {
                                slidesPerView: 2,
                                grid: {
                                    rows: ensPinData?.length < 3 ? 1 : ensPinData?.length < 5 ? 2 : 3,
                                },
                                slidesPerGroup: 2,
                                navigation: {
                                    enabled: true,
                                }
                            }
                        }}
                        style={{ marginBottom: ensPinData?.length === 0 ? "7px" : "30px", height: width < 640 && ensPinData?.length < 2 ? "88px" : width < 640 && ensPinData?.length < 3 ? "191px" : width < 640 && ensPinData?.length < 4 ? "294px" : width > 640 && ensPinData?.length < 3 ? "88px" : width > 640 && ensPinData?.length < 5 ? "191px" : "294px" }}
                    >
                        {
                            ensPinData?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <Link
                                        to={{
                                            pathname: generateTokenDetailUrl({ token: item?.token, name: item.name }),
                                        }}
                                        style={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            border: "1px solid rgba(144, 144, 144, 0.37)",
                                            background: "#FFF",
                                            minHeight: "70px"
                                        }}
                                    >
                                        <Stack
                                            direction={"row"}
                                            spacing={1}
                                            sx={{
                                                padding: "8px",
                                                alignItems: "flex-start",
                                                justifyContent: "space-between",
                                                width: "100%",

                                            }}
                                        >
                                            <Stack direction={"row"} spacing={1} sx={{ fontWeight: "normal", alignItems: "flex-start", width: "100%" }}>
                                                <Box sx={{ position: "relative", height: "70px", width: "70px" }}>
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            height: "70px",
                                                            width: "70px",
                                                            borderRadius: '5px',
                                                        }}
                                                        alt={`token-${index}`}
                                                        src={item?.imagePath}
                                                    />
                                                    {/* {item?.listingPrice &&
                                                <Typography
                                                    sx={{
                                                        color: "#007fff",
                                                        whiteSpace: "nowrap",
                                                        position: "absolute",
                                                        bottom: "0px",
                                                        right: "0px",
                                                        zIndex: "999"
                                                    }}
                                                >
                                                    {item?.listingPrice}&nbsp; Ξ
                                                </Typography>
                                            } */}
                                                </Box>
                                                <Stack direction={"column"} sx={{ fontWeight: "normal", alignItems: "flex-start", width: "100%" }}>
                                                    <Stack direction={"row"} spacing={1}
                                                        sx={{
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                        }}>
                                                        <Typography color="#484343" variant="subtitle2" sx={{ fontWeight: "bold" }}>{item?.name?.length > 30
                                                            ? `${item?.name?.slice(0, 30)}....`
                                                            : item?.name}
                                                        </Typography>
                                                        {item?.listing?.price &&
                                                            <>
                                                                <Stack sx={{ alignItems: "center" }} direction={"row"} spacing={1}>
                                                                    <Typography sx={{ color: "#707070", fontWeight: "bold", whiteSpace: "nowrap" }}>{item?.listing?.price}&nbsp; Ξ</Typography>
                                                                    <MarketPriceCircle market={item?.listing?.source} />
                                                                </Stack>
                                                            </>
                                                        }
                                                    </Stack>
                                                    <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: "left" }} className='two-line-description'>{item?.description}</Typography>
                                                </Stack>
                                            </Stack>
                                            {/* <Stack direction={"row"} alignItems={'center'} sx={{ alignSelf: "center" }}>
                                    {item?.listingPrice && <Typography sx={{ color: "#007fff", whiteSpace: "nowrap" }}>{item?.listingPrice}&nbsp; Ξ</Typography>}
                                </Stack> */}
                                        </Stack>
                                    </Link>
                                </SwiperSlide>
                            ))
                        }

                    </Swiper >
                </>
            }
        </>
    )
}