

import React from "react";
import { Stack, Typography } from "@mui/material";
import Link from '@mui/material/Link';

const MembershipStatus = ({ style, isMember, isMyPage }) => {
    return (
        <>
            {isMyPage && !isMember &&
                <Link
                    href="https://blog.ensdom.com/blog/nameapes-membership"
                    target="_blank"
                    sx={{textDecorationLine:'none'}}
                >
                    <Stack style={style} direction="row"  >
                        <Typography color={'#635e5e'}>Complete tasks and be added as a NameApes member to enjoy Member benefits. </Typography>

                        &nbsp; See Details

                    </Stack>
                </Link>
            }
        </>
    );
};

export default MembershipStatus;
