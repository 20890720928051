export const ETHEREUM_NETWORK = process.env.REACT_APP_ETHEREUM_MAINNET || 'mainnet';
export const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY || 'h5CxFQ2-cUVHQsMGmF0fb0DBvmSDYldh';
export const FEATURE_FLAG_SELL_TOKEN = process.env.REACT_APP_FEATURE_FLAG_SELL_TOKEN === 'true' || false;
export const FEATURE_FLAG_REGISTRATION = process.env.REACT_APP_FEATURE_FLAG_REGISTRATION === 'true' || false;
export const FEATURE_FLAG_BUY_TOKEN = process.env.REACT_APP_FEATURE_FLAG_BUY_TOKEN === 'true' || false;
export const FEATURE_FLAG_WHITELIST_LISTING = process.env.REACT_APP_FEATURE_FLAG_WHITELIST_LISTING === 'true' || false;
export const FEATURE_FLAG_SALE_CLEARANCE = process.env.REACT_APP_FEATURE_FLAG_SALE_CLEARANCE === 'true' || false;
export const FEATURE_FLAG_SALE_CLEARANCE_USE_TESTING_DATA = process.env.REACT_APP_FEATURE_FLAG_SALE_CLEARANCE_USE_TESTING_DATA === 'true' || false;
export const FEATURE_FLAG_SALE_OFFER = process.env.REACT_APP_FEATURE_FLAG_SALE_OFFER === 'true' || false;
export const FEATURE_FLAG_TOKEN_ACTIVITY = process.env.REACT_APP_FEATURE_FLAG_TOKEN_ACTIVITY === 'true' || false;
export const RESERVOIR_API_KEY = process.env.REACT_APP_RESERVOIR_API_KEY || "2a8fe4da-e260-5417-9a44-a7179b13e81f"

export const MARKETPLACE_FEE_RECIPIENT = process.env.REACT_APP_MARKETPLACE_FEE_RECIPIENT || '0xb931592eEd3f82681E00bfd05ceD4C11bE957115';
export const CLEARANCE_FEE_RECIPIENT = process.env.REACT_APP_CLEARANCE_FEE_RECIPIENT || '0x9463562d413D23c46a3A751eCfd1064D8e143040';

export const CLEARANCE_SALT = process.env.REACT_APP_CLEARANCE_SALT || '2359';
export const CLEARANCE_SALT_IN_HEX = process.env.REACT_APP_CLEARANCE_SALT_IN_HEX || '0x360c6ebe';
