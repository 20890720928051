import { useState, useContext, useEffect } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  useAccount,
  useProvider,
  useSigner,
  useBalance,
  useConnect,
  useDisconnect,
} from "wagmi";
import { ethers } from "ethers";
import { getClient } from "@reservoir0x/reservoir-sdk";
import { CartContext } from "../../utils/CartContext";
import useFetch from "../../hooks/fetch";
import BulkBuyingDialog from "./BulkBuyingDialog";
import { getTokenListing, getTokensInfoV2 } from "../../api/reservoir";
import Loader from "../common/Loader";
import toast from "react-hot-toast";
import Link from "@mui/material/Link";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { ETHEREUM_NETWORK } from "../../config";
import Iconify from "../iconify";
import { generateTokenDetailUrl } from "../../utils/token-detail";
import lg from "../assets/NameApes-icon.png";
import { ens_beautify, ens_normalize } from "@adraffy/ens-normalize";
import { getTokensData } from "../../api/requests";
import { getNameLength, normalize } from "../../utils/ens-service";
import { ENS_COLLECTION_ID, ENS_WRAPPED_COLLECTION_ID } from "../../constants";
import { Tooltip } from "@mui/material";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import ClickAwayListener from "@mui/material/ClickAwayListener";
// import Grow from "@mui/material/Grow";
// import Paper from "@mui/material/Paper";
// import Popper from "@mui/material/Popper";
// import MenuList from "@mui/material/MenuList";
// import MenuItem from "@mui/material/MenuItem";

const style = {
  position: "absolute",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "10px",
};

const ethPriceBox = (price) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box sx={{ marginRight: "5px" }}>
        <svg
          style={{ height: "20px", width: "20px" }}
          viewBox="0 0 33 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3576 0.666687L16.0095 1.85009V36.1896L16.3576 36.5371L32.2976 27.115L16.3576 0.666687Z"
            fill="#343434"
          />
          <path
            d="M16.3578 0.666687L0.417816 27.115L16.3578 36.5372V19.8699V0.666687Z"
            fill="#8C8C8C"
          />
          <path
            d="M16.3575 39.5552L16.1613 39.7944V52.0268L16.3575 52.6L32.307 30.1378L16.3575 39.5552Z"
            fill="#3C3C3B"
          />
          <path
            d="M16.3578 52.5998V39.5551L0.417816 30.1377L16.3578 52.5998Z"
            fill="#8C8C8C"
          />
          <path
            d="M16.3575 36.537L32.2973 27.1151L16.3575 19.8699V36.537Z"
            fill="#141414"
          />
          <path
            d="M0.417816 27.1151L16.3576 36.537V19.8699L0.417816 27.1151Z"
            fill="#393939"
          />
        </svg>
      </Box>
      {price}
    </Box>
  );
};

const BuyCartModal = ({ open, setOpen, cart }) => {
  const [ethToUsdRate, setEthToUsdRate] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tokensData, setTokensData] = useState([]);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [txStatus, setTxStatus] = useState("");
  const [buyItems, setBuyItems] = useState(cart.buyCartItems);
  const [totalCost, setTotalCost] = useState(0);
  const [tx, setTx] = useState("");
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [nonPurchasedItems, setNonPurchasedItems] = useState([]);

  const { address, isConnected, isReconnecting, connector, status } =
    useAccount();
  // const { connect, connectors, error, isLoading, pendingConnector } =
  //   useConnect();

  const { data: balance, isError } = useBalance({ address });
  // const provider = useProvider();
  const provider = new ethers.getDefaultProvider(
    ETHEREUM_NETWORK !== "goerli" ? "homestead" : "goerli"
  );
  const { data: signer } = useSigner();

  const invalidCharacters = ["'", "@", "#", "%"];

  const getExpirationDate = (tokenDate) => {
    // const attr = tokenData?.attributes?.find(
    //   (a) => a.key === "Expiration Date"
    // );
    if (!tokenDate) {
      return null;
    }

    return moment(new Date(tokenDate)).fromNow();
  };

  const calculateTotalcost = (items) => {
    setTotalCost(
      parseFloat(
        cart.getTotalCostForBuyCart(
          items?.map((t) => {
            return (
              t.market?.floorAsk?.price?.amount?.decimal ||
              t.lowestTokenListing?.price?.amount?.decimal
            );
          })
        )
      )
    );
  };

  const checkCartItemsStatus = (checkoutCheck = false) => {
    const buyCartTokens = cart.buyCartItems?.map((c) => c.token);
    if (buyCartTokens?.length > 0) {
      getTokensData(buyCartTokens)
        .then((res) => {
          return res.data.data;
        })
        .then((data) => {
          const buyCartWrappedTokens = cart.buyCartItems.map((s) => [
            s.token,
            s?.wrappedToken,
          ]);
          getTokensInfoV2(buyCartWrappedTokens)
            .then(async (res) => {
              const items = res.data.tokens;
              const tokensListings = await Promise.all(
                items.map((i) =>
                  getTokenListing(i.token.tokenId, i.token.contract)
                )
              );
              for (let i = 0; i < items.length; i++) {
                const tokenData = data?.find(
                  (d) =>
                    d._id === items[i].token.tokenId ||
                    d?.wrappedDomain?.token === items[i].token.tokenId
                );
                const tokenListings = tokensListings.find((t) =>
                  t.data?.orders[0]?.tokenSetId?.includes(
                    items[i].token.tokenId
                  )
                )?.data?.orders;
                const sortedListing = tokenListings?.sort(
                  (a, b) => a.price.amount.decimal - b.price.amount.decimal
                );
                // console.log(items)
                const lowestTokenListing = sortedListing[0];
                if (tokenData) {
                  items[i].name = tokenData.tokenName;
                  items[i].expiryDate = tokenData.expiryDate;
                  items[i].originalToken = tokenData._id;
                  items[i].lowestTokenListing = lowestTokenListing;
                } else {
                  const cartItem = cart.buyCartItems?.find(
                    (d) =>
                      d.token === items[i].token.tokenId ||
                      d?.wrappedToken === items[i].token.tokenId
                  );
                  items[i].name = cartItem?.name;
                  const expiryDate = items[i].token.attributes?.find(
                    (a) => a.key === "Expiration Date"
                  )?.value;
                  items[i].expiryDate = expiryDate ? new Date(expiryDate) : "";
                  items[i].originalToken = cartItem.token;
                  items[i].lowestTokenListing = lowestTokenListing;
                }
              }
              setTokensData(items);
              // console.log(res.data.tokens);
              setIsCartLoading(false);
              calculateTotalcost(items);
              const invalidItems = items.filter((d) => {
                return (
                  (d.market.floorAsk.id === null &&
                    !d.lowestTokenListing?.price?.amount?.decimal) ||
                  d.token?.owner?.toLowerCase() === address?.toLowerCase()
                );
              });
              const compare = (a, b) =>
                a.token === b.token.tokenId ||
                a?.wrappedToken === b.token.tokenId;
              if (invalidItems.length > 0) {
                const validItems = cart.buyCartItems.filter(
                  (i) => !invalidItems.some((d) => compare(i, d))
                );

                if (checkoutCheck) {
                  if (validItems.length === buyItems.length) {
                    buyItems.sort();
                    validItems.sort();
                    let isNotValid;
                    for (let i = 0; i < buyItems.length; ++i) {
                      if (buyItems[i] !== validItems[i]) {
                        isNotValid = true;
                      }
                    }
                    if (isNotValid) {
                      // setIsCheckoutLoading(false);
                      // setIsAbleToCheckout(false);
                      setBuyItems(validItems);
                      return toast.error(
                        "Check failed for some names. They have been marked in grey and will not be part of Checkout.",
                        {
                          style: {
                            maxWidth: "100%",
                          },
                        }
                      );
                    } else {
                      // setIsCheckoutLoading(false);
                      bulkBuy(validItems);
                      setDialogOpen(true);
                      // setIsAbleToCheckout(true);
                    }
                    // setIsCheckoutLoading(false);
                    // if (isAbleToCheckout) {
                    // }
                  } else if (validItems.length !== buyItems.length) {
                    // setIsCheckoutLoading(false);
                    // setIsAbleToCheckout(false);
                    setBuyItems(validItems);
                    return toast.error(
                      "Check failed for some names. They have been marked in grey and will not be part of Checkout.",
                      {
                        style: {
                          maxWidth: "100%",
                        },
                      }
                    );
                  }
                  if (validItems.length === 0) {
                    setIsCheckoutLoading(false);
                    // setIsAbleToCheckout(false);
                    // setIsAbleToCheckout(false);
                    setBuyItems(validItems);
                    return toast.error("There is no names to purchase.", {
                      style: {
                        maxWidth: "100%",
                      },
                    });
                  }
                }

                setBuyItems(validItems);
              } else {
                setBuyItems(cart.buyCartItems);
                if (checkoutCheck) {
                  bulkBuy(cart.buyCartItems);
                  setDialogOpen(true);
                }
              }
            })
            .catch((err) => {
              setIsCheckoutLoading(false);
              console.log(err.message);
            });
        });
    }
  };

  useEffect(() => {
    if (cart.buyCartItems.length > 0 && open) {
      checkCartItemsStatus(false);
      setIsCartLoading(true);
    }
  }, [
    open,
    // btnDisabled,
    // buyItems.length,
    // cart.buyCartItems,
    signer,
  ]);
  const ethToUsdRateApi = useFetch(
    "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
  );
  useEffect(() => {
    setEthToUsdRate(ethToUsdRateApi.data?.USD);
  }, [ethToUsdRateApi]);

  const handleClose = () => setOpen(false);
  const bulkBuy = async (items) => {
    const buyCartTokens = cart.buyCartItems?.map((c) => c.token);
    const tokensObj = items.map((d) => {
      return {
        // tokenId: d,
        // contract: "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85",
        token: d.wrappedToken
          ? `${ENS_WRAPPED_COLLECTION_ID}:${d.wrappedToken}`
          : `${ENS_COLLECTION_ID}:${d.token}`,
        quantity: 1,
      };
    });
    try {
      await getClient()?.actions.buyToken({
        items: [...tokensObj],
        signer,
        onProgress: async (steps) => {
          console.log(steps);
          if (
            steps[0].items[0].status === "incomplete" &&
            !steps[0].items[0].txHash
          ) {
            setTxStatus("incomplete");
          } else if (
            steps[0].items[0].status === "incomplete" &&
            steps[0].items[0].txHash
          ) {
            setTxStatus("validating");
            setTx(steps[0].items[0].txHash);
          } else if (
            steps[0].items[0].status === "complete" &&
            steps[0].items[0].txHash
          ) {
            setTxStatus("complete");
            const txReceipt = await provider.getTransactionReceipt(
              steps[0].items[0].txHash
            );
            const purchasedItems = txReceipt.logs.map((log) => {
              const arr = buyItems.map((i) => {
                let hashedToken = ethers.BigNumber.from(
                  i?.wrappedToken || i?.token
                ).toHexString();
                // let index = hashedToken.indexOf("0x");
                // let newHashedToken;
                // if (index === 0) {
                //   newHashedToken = hashedToken.replace("0x", "");
                // }
                if (
                  log.topics.includes(hashedToken) ||
                  log.data.includes(hashedToken)
                ) {
                  return i;
                } else {
                  return;
                }
              });
              return arr;
              // const lastReceiptLogs = txReceipt.logs.at(-1);
              // if (steps[1].items[0].data.data.includes(newHashedToken)) {
              //   return i;
              // }
            });
            const filteredPurchasedItems = purchasedItems
              .flat()
              .filter((n) => n !== undefined);
            const compare = (a, b) => a === b;
            const filteredItems = cart.buyCartItems.filter(
              (i) =>
                !filteredPurchasedItems.some((d) =>
                  compare(
                    i?.wrappedToken || i?.token,
                    d?.wrappedToken || d?.token
                  )
                )
            );
            if (filteredPurchasedItems.length !== buyItems.length) {
              const nonPurchItems = cart.buyCartItems.filter(
                (i) =>
                  !filteredPurchasedItems.some((d) =>
                    compare(
                      i?.wrappedToken || i?.token,
                      d?.wrappedToken || d?.token
                    )
                  )
              );
              setNonPurchasedItems(nonPurchItems);
            }
            cart.addFilteredItemsToBuyCart(filteredItems);
          }
        },
      });
    } catch (e) {
      // console.log(e.response.data.message);
      setIsCheckoutLoading(false);
      let error;
      if (e?.message?.startsWith("Request failed")) {
        error = e.response.data.message;
      } else {
        error = e.message;
      }
      console.log(e);
      setDialogOpen(false);
      setTxStatus("");
      return toast.error(error, {
        style: {
          maxWidth: "100%",
        },
      });
    }
  };
  // let theToken = ethers.BigNumber.from(
  //   // ethers.utils.keccak256(ethers.utils.toUtf8Bytes("mos977"))
  //   ethers.utils.keccak256(ethers.utils.toUtf8Bytes("sasa998"))
  // ).toHexString();
  // console.log(ethers.BigNumber.from("45536804155900426384806474883901423571482121158731178799159796146566838728049").toHexString());
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="buy-cart-modal" sx={style}>
          <Box className="header">
            {/* <button className="close" onClick={handleClose}>
              &times;
            </button> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                    display: "block",
                    padding: "2px 5px",
                    lineHeight: "20px",
                    fontSize: "24px",
                    background: "transparent",
                    borderRadius: "9px",
                    border: "0px",
                    color: "#484343",
                    minWidth: "35px",
                  }}
                  onClick={handleClose}
                >
                  <Iconify
                    icon={"ic:twotone-arrow-back"}
                    sx={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </Button>
              </Box>
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h1"
                  style={{ textAlign: "center" }}
                >
                  Buy Cart ({cart.buyCartItems.length})
                </Typography>
              </Box>
              <Box style={{ textAlign: "right" }}>
                {cart.buyCartItems.length > 0 && (
                  <Button
                    sx={{
                      padding: "8px",
                      border: "1px solid #AAAAAA",
                      borderRadius: "4px",
                      // width: "60%",
                      textTransform: "unset",
                      fontSize: "15px",
                      color: "#484343",
                      lineHeight: 1,
                      // marginBottom: "15px",
                    }}
                    onClick={() => cart.deleteAllFromBuyCart()}
                  >
                    Remove All
                    <Iconify
                      icon={"bi:dash-circle"}
                      sx={{
                        width: 15,
                        height: 15,
                        marginLeft: "10px",
                      }}
                    />
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            id="modal-modal-description"
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              height: "80%",
            }}
          >
            <Box
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              {cart.buyCartItems.length > 0 ? (
                !isCartLoading ? (
                  <>
                    {/* <Box
                    // sx={{
                    //   display: "flex",
                    //   flexDirection: "row",
                    //   justifyContent: "space-between",
                    // }}
                    >
                      
                      <Button
                        sx={{
                          padding: "8px",
                          border: "1px solid #AAAAAA",
                          borderRadius: "4px",
                          width: "100%",
                          textTransform: "unset",
                          fontSize: "15px",
                          color: "#484343",
                        }}
                        onClick={() => cart.deleteAllFromBuyCart()}
                      >
                        Remove All
                      </Button>
                    </Box> */}
                    <Box
                      className="names-container"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: { sm: "80%", xs: "70%" },
                        overflowY: "auto",
                        borderBottom: "1px solid rgba(0,0,0,0.1)",
                      }}
                    >
                      {tokensData.map((d, i) => (
                        // {/* cart item box */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            // borderBottom: "1px solid rgba(0,0,0,0.1)",
                            alignItems: "center",
                          }}
                          key={i}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                fontSize: "13px",
                                color: "#99A3B3",
                                // marginLeft: "10px",
                                width:
                                  (d.market.floorAsk.id ||
                                    d.lowestTokenListing?.id) &&
                                  d.token?.owner?.toLowerCase() !==
                                    address?.toLowerCase()
                                    ? "23px"
                                    : "0px",
                                marginRight: "10px",
                              }}
                            >
                              {constructMarketplaceIcon(
                                d?.lowestTokenListing?.source ||
                                  d?.market?.floorAsk?.source
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                // alignItems: "center",
                                color:
                                  (d.market.floorAsk.id ||
                                    d.lowestTokenListing?.id) &&
                                  d.token?.owner?.toLowerCase() !==
                                    address?.toLowerCase()
                                    ? "unset"
                                    : "grey",
                                fontStyle:
                                  (d.market.floorAsk.id ||
                                    d.lowestTokenListing?.id) &&
                                  d.token?.owner?.toLowerCase() !==
                                    address?.toLowerCase()
                                    ? "unset"
                                    : "italic",
                              }}
                            >
                              <Box sx={{ fontWeight: 600 }}>
                                {/* {d.token.name ||
                                  `${d.token.tokenId.slice(
                                    0,
                                    5
                                  )}...${d.token.tokenId.slice(
                                    d.token.tokenId.length - 3,
                                    d.token.tokenId.length
                                  )}`} */}
                                <Link
                                  style={{
                                    color:
                                      (d.market.floorAsk.id ||
                                        d.lowestTokenListing?.id) &&
                                      d.token?.owner?.toLowerCase() !==
                                        address?.toLowerCase()
                                        ? "black"
                                        : "grey",
                                  }}
                                  href={generateTokenDetailUrl({
                                    token: d.token?.tokenId,
                                    name: d.token?.name?.split(".eth")[0],
                                  })}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {!d?.name && !d?.token?.name
                                    ? `${d.token?.tokenId?.slice(
                                        0,
                                        5
                                      )}...${d.token?.tokenId?.slice(
                                        d.token?.tokenId?.length - 3,
                                        d.token?.tokenId?.length
                                      )}`
                                    : d?.token?.name?.length > 35 ||
                                      d?.name?.length > 35
                                    ? `${d?.name?.slice(0, 35)}...` ||
                                      `${d?.token?.name?.slice(0, 35)}...`
                                    : d?.name || d?.token?.name}
                                </Link>
                              </Box>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                {(!d?.name && !d?.token?.name) ||
                                normalize(d?.name).isNameValid === false ? (
                                  <Tooltip
                                    placement="top"
                                    enterTouchDelay={0}
                                    title={
                                      <>
                                        <p
                                          style={{
                                            "font-size": "14px",
                                            marginBottom: "2px",
                                          }}
                                        >
                                          Name is Malformed
                                        </p>
                                        {normalize(d?.name)?.normalizedName && (
                                          <p
                                            style={{
                                              "font-size": "14px",
                                              marginBottom: "2px",
                                            }}
                                          >
                                            Normalized form:
                                            <Link
                                              style={{
                                                textDecoration: "none",
                                                color: "#fff",
                                              }}
                                              to={{
                                                pathname: `/eth/${
                                                  normalize(d?.name)
                                                    ?.normalizedName
                                                }/`,
                                              }}
                                            >
                                              {` ${
                                                normalize(d?.name)
                                                  ?.normalizedName
                                              }`}
                                            </Link>
                                          </p>
                                        )}
                                      </>
                                    }
                                  >
                                    <Iconify
                                      icon={"ic:outline-warning"}
                                      sx={{
                                        width: 24,
                                        height: 24,
                                        marginRight: "4px",
                                        color: "rgb(255, 196, 0)",
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  ""
                                )}{" "}
                                {getExpirationDate(d.expiryDate)
                                  ? "Expires " +
                                    getExpirationDate(d.expiryDate) +
                                    "."
                                  : ""}{" "}
                                {(d?.name || d?.token?.name) &&
                                normalize(d?.name).isNameValid
                                  ? getNameLength(
                                      d?.name?.split(".eth")[0] ||
                                        d?.token?.name.split(".eth")[0]
                                    ) + " characters"
                                  : ""}
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "8px",
                              paddingRight: "0px",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "8px",
                                alignItems: "end",
                              }}
                            >
                              {(d.market.floorAsk.id ||
                                d.lowestTokenListing?.id) &&
                              d.token?.owner?.toLowerCase() !==
                                address?.toLowerCase() ? (
                                <>
                                  {ethPriceBox(
                                    d?.market?.floorAsk?.price?.amount
                                      ?.decimal ||
                                      d?.lowestTokenListing?.price?.amount
                                        ?.decimal
                                  )}
                                  <Box>
                                    $
                                    {(
                                      ethToUsdRate *
                                        d?.market?.floorAsk?.price?.amount
                                          ?.decimal ||
                                      d?.lowestTokenListing?.price?.amount
                                        ?.decimal
                                    ).toFixed(2)}
                                  </Box>
                                </>
                              ) : d.token?.owner?.toLowerCase() ===
                                address?.toLowerCase() ? (
                                <Box
                                  sx={{
                                    color: "grey",
                                    fontStyle: "italic",
                                  }}
                                >
                                  Can not buy this name
                                </Box>
                              ) : (
                                !d.market.floorAsk.id &&
                                !d.lowestTokenListing?.id && (
                                  <Box
                                    sx={{
                                      color: "grey",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    No listing
                                  </Box>
                                )
                              )}
                            </Box>
                            <Button
                              sx={{
                                minWidth: "0px",
                                color: "black",
                                // paddingRight: "0px",
                              }}
                              onClick={() => {
                                cart.removeOneFromBuyCart(d.originalToken);
                                const newTokensData = tokensData.filter(
                                  (t) => t.originalToken !== d.originalToken
                                );
                                calculateTotalcost(newTokensData);
                                setTokensData(newTokensData);
                              }}
                            >
                              <Iconify
                                icon={"bi:dash-circle"}
                                sx={{
                                  width: 24,
                                  height: 24,
                                }}
                              />
                            </Button>
                          </Box>
                        </Box>
                        // {/* cart item box */}
                      ))}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <Box>Total Amount</Box>
                        <Link
                          href="https://blog.ensdom.com/blog/buy-cart-buy-many-ens-names"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#484343",
                            lineHeight: "29px",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          FAQ
                        </Link>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                        }}
                      >
                        {ethPriceBox(totalCost.toFixed(6))}
                        <Box sx={{ paddingLeft: "4px" }}>
                          ${(ethToUsdRate * totalCost)?.toFixed(2)}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      {isConnected && !isReconnecting ? (
                        <Button
                          sx={{
                            width: "100%",
                            background:
                              "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                            color: "#FFFFFF",
                            height: "54px",
                            "&:hover": {
                              backgroundColor: "#C993FC",
                            },
                          }}
                          disabled={
                            buyItems.length === 0 ||
                            parseFloat(balance?.formatted).toFixed(4) <=
                              totalCost
                          }
                          onClick={() => {
                            checkCartItemsStatus(true);
                            setIsCheckoutLoading(true);
                            setTimeout(() => {
                              // if (isAbleToCheckout) {
                              //   bulkBuy();
                              //   setDialogOpen(true);
                              //   // setIsAbleToCheckout(false);
                              // }
                              setIsCheckoutLoading(false);
                            }, 2000);
                          }}
                        >
                          {parseFloat(balance?.formatted).toFixed(4) >
                          totalCost ? (
                            isCheckoutLoading ? (
                              <CircularProgress />
                            ) : (
                              "Checkout"
                            )
                          ) : (
                            "Insufficient balance"
                          )}
                          {/* {isLoading &&
                        pendingConnector?.id === connectors.at(-1).id
                          ? " (connecting)"
                          : "Connect"}
                        {parseFloat(data?.formatted).toFixed(4) > totalCost
                          ? "Checkout"
                          : "Insufficient balance"} */}
                        </Button>
                      ) : (
                        <ConnectButton.Custom>
                          {({
                            account,
                            chain,
                            openAccountModal,
                            openChainModal,
                            openConnectModal,
                            authenticationStatus,
                            mounted,
                          }) => {
                            // Note: If your app doesn't use authentication, you
                            // can remove all 'authenticationStatus' checks
                            const ready =
                              mounted && authenticationStatus !== "loading";
                            const connected =
                              ready &&
                              account &&
                              chain &&
                              (!authenticationStatus ||
                                authenticationStatus === "authenticated");

                            return (
                              <Box
                                {...(!ready && {
                                  "aria-hidden": true,
                                  style: {
                                    opacity: 0,
                                    pointerEvents: "none",
                                    userSelect: "none",
                                  },
                                })}
                              >
                                {(() => {
                                  if (!connected) {
                                    return (
                                      <Button
                                        sx={{
                                          width: "100%",
                                          backgroundColor: "#C993FC",
                                          color: "#FFFFFF",
                                          "&:hover": {
                                            backgroundColor: "#C993FC",
                                          },
                                          minWidth: "unset",
                                        }}
                                        variant="contained"
                                        size="large"
                                        onClick={openConnectModal}
                                      >
                                        Ape In
                                      </Button>
                                    );
                                  }

                                  if (chain.unsupported) {
                                    return (
                                      <button
                                        onClick={openChainModal}
                                        type="button"
                                        style={{
                                          /*background: "linear-gradient(90deg, #7a9ff3 0%, #46b6ed 100%)",*/
                                          boxShadow: 0,
                                          border: "0px solid #4880C8",
                                          color: "rgba(72, 67, 67, 1)",
                                          background: "rgba(255, 255, 255, 1)",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          minWidth: "130px",
                                          wordWrap: "break-word",
                                          whiteSpace: "normal",
                                          height: "100%",
                                          textAlign: "center",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        Wrong network
                                      </button>
                                    );
                                  }
                                })()}
                              </Box>
                            );
                          }}
                        </ConnectButton.Custom>
                      )}
                    </Box>
                  </>
                ) : (
                  <Loader />
                )
              ) : (
                // : !isConnected ? (
                //   <Box
                //     sx={{
                //       display: "flex",
                //       flexDirection: "column",
                //       width: "100%",
                //       alignItems: "center",
                //       marginTop: "50%",
                //     }}
                //   >
                //     <Typography variant="h6">
                //       Please connect your wallet to checkout
                //     </Typography>
                //     <Button
                //       sx={{
                //         width: "50%",
                //         backgroundColor: "#C993FC",
                //         color: "#FFFFFF",
                //         "&:hover": {
                //           backgroundColor: "#C993FC",
                //         },
                //       }}
                //       onClick={() => {
                //         const connector = connectors.at(-1);
                //         connect({ connector });
                //       }}
                //     >
                //       {isLoading && pendingConnector?.id === connectors.at(-1).id
                //         ? " (connecting)"
                //         : "Connect"}
                //     </Button>
                //   </Box>
                // )
                cart.buyCartItems.length === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: "25px",
                    }}
                  >
                    <p style={{ textAlign: "center" }}>
                      Buy Cart Empty. Add items via Cart mode in Buy Tab or ENS
                      Detail page.{" "}
                      <Link
                        href="https://blog.ensdom.com/blog/buy-cart-buy-many-ens-names"
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        FAQ
                      </Link>
                    </p>
                    {
                      <ConnectButton.Custom>
                        {({
                          account,
                          chain,
                          openAccountModal,
                          openChainModal,
                          openConnectModal,
                          authenticationStatus,
                          mounted,
                        }) => {
                          // Note: If your app doesn't use authentication, you
                          // can remove all 'authenticationStatus' checks
                          const ready =
                            mounted && authenticationStatus !== "loading";
                          const connected =
                            ready &&
                            account &&
                            chain &&
                            (!authenticationStatus ||
                              authenticationStatus === "authenticated");

                          return (
                            <Box
                              {...(!ready && {
                                "aria-hidden": true,
                                style: {
                                  opacity: 0,
                                  pointerEvents: "none",
                                  userSelect: "none",
                                  display: "flex",
                                  justifyContent: "center",
                                },
                              })}
                            >
                              {(() => {
                                if (!connected) {
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Button
                                        sx={{
                                          boxShadow: 0,
                                          backgroundColor: "#C993FC",
                                          color: "#FFFFFF",
                                          "&:hover": {
                                            backgroundColor: "#C993FC",
                                          },
                                          fontSize: "14px",
                                          fontWeight: 600,
                                          // "&:hover": {
                                          //   backgroundColor: "#fff",
                                          //   color: "black",
                                          // },
                                        }}
                                        variant="contained"
                                        size="large"
                                        onClick={openConnectModal}
                                      >
                                        Ape In
                                      </Button>
                                    </Box>
                                  );
                                }
                              })()}
                            </Box>
                          );
                        }}
                      </ConnectButton.Custom>
                    }
                  </Box>
                )
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
      <BulkBuyingDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        numberOfItems={buyItems.length}
        totalEthPrice={totalCost}
        totalUsdPrice={ethToUsdRate * totalCost}
        status={txStatus}
        tx={tx}
        setCartModalOpen={setOpen}
        buyItems={buyItems}
        tokensData={tokensData}
        nonPurchasedItems={nonPurchasedItems}
      />
    </Box>
  );
};

export default BuyCartModal;

function constructMarketplaceIcon(source) {
  const market = () => {
    switch (source?.name) {
      case "Clubs":
        return source?.icon;
      case "ENSVision":
        return lg;
      case "ens.vision":
        return lg;
      case "Element: Buy NFTs, Crypto Collectibles, GameFi Asset":
        return source?.icon;
      case "LooksRare":
        return source?.icon;
      case "OpenSea":
        return source?.icon;
      case "Rarible – Create, sell or collect digital items secured with #blockchain":
        return source?.icon;
      case "Reservoir":
        return lg;
      case "Reservoir Market":
        return lg;
      case "sales.ensdom.com":
        return lg;
      case "nameapes.com":
        return lg;
      case "NameApes":
        return lg;
      case "dev.reservoir.market":
        return lg;
      case "rare.id":
        return lg;
      case "godid.io":
        return lg;
      case "Didex.xyz":
        return source?.icon; /// "UN"
      case "Powersweep.metastreet.xyz":
        return source?.icon; /// "UN"
      case "X2Y2":
        return source?.icon;
      case "atomic0":
        return source?.icon;
      case 'element.market"':
        return source?.icon;
      case "ens.domains":
        return source?.icon;
      case "rarible.com":
        return source?.icon;
      default:
        return source?.icon;
    }
  };

  return <img src={market()} style={{ width: "100%" }} />;
}
