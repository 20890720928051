import { useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useAccount, useSigner } from "wagmi";
import { v4 as UUIDv4 } from "uuid";
import useFetch from "../../hooks/fetch";
import Loader from "../common/Loader";
import toast from "react-hot-toast";
import Link from "@mui/material/Link";
import moment from "moment";
import { ethers } from "ethers";
import { Chip, InputAdornment, OutlinedInput, Tab, Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Iconify from "../iconify";
import { normalizeEth } from "../../utils/eth";
import BulkSellingDialog from "./BulkSellingDialog";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { generateTokenDetailUrl } from "../../utils/token-detail";
import lg from "../assets/NameApes-icon.png";
import { usePriceCalculator } from "../../hooks/priceCalculator";
import {
  MARKETPLACE_FEE_RECIPIENT,
  FEATURE_FLAG_WHITELIST_LISTING,
} from "../../config";
import { getTokensInfo, getTokensInfoV2 } from "../../api/reservoir";
import { findDomainsByWalletAddress, resolveOwnerAddresses } from "../../utils/ens-service";
import { getClient } from "@reservoir0x/reservoir-sdk"
import { Avatar } from "react-ui";
import _, { chunk } from "underscore";
import { AntTab, AntTabs } from "../customMui/AntTabs";
import { generateNewClearanceCalculation } from "../../utils/clearance-service";
import { StartClearanceDialog } from "../clearance/StartClearanceDialog";
import { convertTokenToHex } from "../../utils";
import { ENS_COLLECTION_ID, ENS_WRAPPED_COLLECTION_ID } from "../../constants";

const style = {
  position: "absolute",
  bgcolor: "background.paper",
  boxShadow: 24,
  // padding: "30px",
};

const BULK_LISTING_PROCESS_ID_KEY = "bulk-listing-process-id";

const wait = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
const CheckMark = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "15px",
        height: "15px",
        left: "70%",
        top: "70%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#78b13f",
        borderRadius: "50%",
      }}
    >
      <Box
        sx={{
          display: "inline-block",
          transform: "rotate(45deg)",
          width: "5px",
          height: "9px",
          borderBottom: "2px solid white",
          borderRight: "2px solid white",
        }}
      ></Box>
    </Box>
  );
};

const EthIcon = () => {
  return (
    <svg
      style={{ height: "20px", width: "20px" }}
      viewBox="0 0 33 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3576 0.666687L16.0095 1.85009V36.1896L16.3576 36.5371L32.2976 27.115L16.3576 0.666687Z"
        fill="#343434"
      />
      <path
        d="M16.3578 0.666687L0.417816 27.115L16.3578 36.5372V19.8699V0.666687Z"
        fill="#8C8C8C"
      />
      <path
        d="M16.3575 39.5552L16.1613 39.7944V52.0268L16.3575 52.6L32.307 30.1378L16.3575 39.5552Z"
        fill="#3C3C3B"
      />
      <path
        d="M16.3578 52.5998V39.5551L0.417816 30.1377L16.3578 52.5998Z"
        fill="#8C8C8C"
      />
      <path
        d="M16.3575 36.537L32.2973 27.1151L16.3575 19.8699V36.537Z"
        fill="#141414"
      />
      <path
        d="M0.417816 27.1151L16.3576 36.537V19.8699L0.417816 27.1151Z"
        fill="#393939"
      />
    </svg>
  );
};

const marketPriceCircle = (market, price, isClicked) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "4px",
      }}
    >
      <Tooltip title={parseFloat(price).toFixed(10)} placement="top" tri arrow>
        <Chip
          avatar={<Avatar src={market} />}
          label={`${parseFloat(price).toFixed(3)} Ξ`}
        />
      </Tooltip>
    </Box>
  );
};

const SellBagModalV2 = ({ open, setOpen, cart }) => {
  const [currentBagType, setCurrentBagType] = useState('Listing');
  const [isClearanceModalOpen, setIsClearanceModalOpen] = useState(false);
  const [clearanceCalculation, setClearanceCalculation] = useState([]);

  const [ethToUsdRate, setEthToUsdRate] = useState(0);
  const [isBagLoading, setIsBagLoading] = useState(false);
  const [priceForAll, setPriceForAll] = useState(5);
  const [alsoOS, setAlsoOS] = useState(true);
  const [alsoLR, setAlsoLR] = useState(false);
  const [alsoX2Y2, setAlsoX2Y2] = useState(false);
  const [alsoNA, setAlsoNA] = useState(true);
  const [alsoEV, setAlsoEV] = useState(true);
  const handleClose = () => setOpen(false);
  const [selectedDuration, setSelectedDuration] = useState(720);

  const defaultStatus = {
    preflight: "incomplete",
    connectingWallet: "incomplete",
    nftApproval: "incomplete",
    listing: "incomplete",
  };
  const [txStatus, setTxStatus, txStatusRef] = useStateRef(defaultStatus);
  const [isDialogCheckoutOpen, setIsDialogCheckoutOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalListItems, setTotalListItems] = useState(0);
  const [currentItemToSign, setCurrentItemToSign] = useState([]);
  const [isInputError, setIsInputError] = useState(false);
  const [stopListing, setStopListing] = useState(false);
  const [allItemsToSign, setAllItemsToSign] = useState([]);
  const [marketplacesToApprove, setMarketplacesToApprove] = useState([]);

  const { address, isConnected, connector, status, isReconnecting } = useAccount();
  const { marketFee, calculatePriceInfo, syncMarketFee } = usePriceCalculator();

  const [isWhitelistUser, setIsWhitelistUser] = useState(false);

  const ethToUsdRateApi = useFetch(
    "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
  );

  useEffect(() => {
    window?.ethereum?.on('message', (msg) => console.log('ETH CHANGE MSG ', msg));
  }, [window?.ethereum])

  useEffect(() => {
    syncMarketFee();
  });

  useEffect(() => {
    setEthToUsdRate(ethToUsdRateApi.data?.USD);
  }, [ethToUsdRateApi]);

  useEffect(() => {
    async function run() {
      if (address) {
        if (FEATURE_FLAG_WHITELIST_LISTING) {
          const names = await findDomainsByWalletAddress(address);
          const isWhitelist = names?.domains?.some((n) =>
            n.name.includes("eapes.eth")
          );
          setIsWhitelistUser(isWhitelist);
        } else {
          setIsWhitelistUser(true);
        }
      }
    }
    run();
  }, [address]);

  const { data: signer } = useSigner();

  const preflightCheck = async (forceFalse) => {
    if (cart.sellBagItems?.length > 0 && address && isConnected && !isReconnecting) {
      try {
        setIsBagLoading(true);
        let tokensData
        if (cart.sellBagItems.length > 50) {
          const sellBagTokens = cart.sellBagItems.map((s) => [s.token, s.wrappedDomain?.token]);
          const chunckedTokens = chunk(sellBagTokens, 50);
          const response = await Promise.all(chunckedTokens.map((c) => getTokensInfoV2(c)));
          tokensData = response.map((r) => r.data?.tokens).flat()
        } else {
          const response = await getTokensInfoV2(
            cart.sellBagItems.map((s) => [s.token, s.wrappedDomain?.token])
          );
          tokensData = response.data?.tokens;
        }

        const compare = (a, b) => a.token === b.token.tokenId;
        const compareWrapped = (a, b) => a.wrappedDomain?.token === b.token.tokenId;
        const invalidCartTokens = cart.sellBagItems?.filter(
          (c) => !tokensData.some((t) => compare(c, t) || compareWrapped(c, t))
        );

        let inValidTokens = [];
        if (tokensData.length === 0) {
          inValidTokens.push(...cart.sellBagItems?.map((s) => s.token));
        } else if (invalidCartTokens.length > 0) {
          inValidTokens.push(...invalidCartTokens.map((s) => s.token));
        }

        tokensData
          .filter(
            (r) => r.token?.owner?.toLowerCase() !== address.toLowerCase()
          )
          .forEach((r) => inValidTokens.push(r.token?.tokenId));

        for (let i = 0; i < cart.sellBagItems.length; i += 1) {
          if (
            inValidTokens.length > 0 &&
            (inValidTokens.includes(cart.sellBagItems[i].token) || inValidTokens.includes(cart.sellBagItems[i]?.wrappedDomain?.token))
          ) {
            cart.sellBagItems[i].isValid = false;
          } else {
            cart.sellBagItems[i].isValid = true;
          }
          // for testing purpose
          // if (forceFalse) {
          //   cart.sellBagItems[i].isValid = false;
          // }
        }

        setIsBagLoading(false);
      } catch (e) {
        console.log(e)
        setIsBagLoading(false);
      }
    }
  };

  useEffect(() => {
    if (cart.sellBagItems.length > 0) {
      preflightCheck();
    } else {
      setIsBagLoading(false);
    }
  }, [open, signer, isConnected, isReconnecting]);

  const isValidListingProcess = (bulkListIdLocalState) => {
    const bulkListIdInLocalStorage = localStorage.getItem(
      BULK_LISTING_PROCESS_ID_KEY
    );
    const shouldProceed = !(
      !bulkListIdInLocalStorage ||
      bulkListIdInLocalStorage !== bulkListIdLocalState
    );
    // console.log(`**************** shouldProceedListing ***********
    //   bulkListIdLocalState: ${bulkListIdLocalState}
    //   bulkListIdInLocalStorage: ${bulkListIdInLocalStorage}
    //   shouldProceedListing: ${shouldProceed}
    // `);

    return shouldProceed;
  };

  const bulkList = async () => {
    localStorage.setItem("stop-listing", false);
    localStorage.removeItem("items-to-sign");


    const bulkListIdLocalState = UUIDv4();
    localStorage.setItem(BULK_LISTING_PROCESS_ID_KEY, bulkListIdLocalState);

    for (let i = 0; i < cart.sellBagItems.length; i += 1) {
      cart.sellBagItems[i].duration = selectedDuration;
    }

    const marketplaces = ["NameApes"];
    if (alsoOS) {
      marketplaces.push("OpenSea");
    }
    if (alsoLR) {
      marketplaces.push("LooksRare");
    }
    if (alsoX2Y2) {
      marketplaces.push("X2Y2");
    }
    setMarketplacesToApprove(marketplaces);
    localStorage.setItem("marketplace-to-approve", marketplaces);

    const itemsToSign = [];
    const tokenListingsMap = {};
    cart.sellBagItems.forEach((s) => {
      if (!tokenListingsMap[s.token]) {
        tokenListingsMap[s.token] = [];
      }

      const token = s.wrappedDomain
        ? `${ENS_WRAPPED_COLLECTION_ID}:${s.wrappedDomain?.token}`
        : `${ENS_COLLECTION_ID}:${s.token}`
      let itemToList = {
        // basic params for resv listing sdk
        token: token,
        weiPrice: normalizeEth(parseFloat(s.price).toFixed(6)),
        orderbook: "reservoir",
        orderKind: "seaport-v1.5",
        duration: moment().add(selectedDuration, "hours").format("X"),
        fees: [`${MARKETPLACE_FEE_RECIPIENT}:${s.naFee * 10000}`],
        // other info
        name: s.name,
        price: s.price,
        club: s.club,
        naFee: s.naFee,
        osPrice: s.osPrice,
        lrPrice: s.lrPrice,
        x2y2Price: s.x2y2Price,
        netPrice: s.netPrice,
        rawToken: s.token,
        marketplace: "NameApes",
      };

      if (s.naFee > 0) {
        itemToList = {
          ...itemToList,
          fees: [`${MARKETPLACE_FEE_RECIPIENT}:${s.naFee * 10000}`],
        };
      }
      tokenListingsMap[s.token].push(itemToList);
      itemsToSign.push(itemToList);

      if (alsoOS) {
        itemToList = {
          ...itemToList,
          orderbook: "opensea",
          orderKind: "seaport-v1.5",
          weiPrice: normalizeEth(parseFloat(s.osPrice).toFixed(5)),
          price: itemToList.osPrice,
          marketplace: "Opensea",
          // fees: [`${MARKETPLACE_FEE_RECIPIENT}:50`],
        };
        tokenListingsMap[s.token].push(itemToList);
        itemsToSign.push(itemToList);
      }

      if (alsoLR) {
        itemToList = {
          ...itemToList,
          orderbook: "looks-rare",
          orderKind: "looks-rare",
          weiPrice: normalizeEth(parseFloat(s.lrPrice).toFixed(5)),
          price: itemToList.lrPrice,
          marketplace: "LooksRare",
        };
        tokenListingsMap[s.token].push(itemToList);
        itemsToSign.push(itemToList);
      }
      if (alsoX2Y2) {
        itemToList = {
          ...itemToList,
          orderbook: "x2y2",
          orderKind: "x2y2",
          weiPrice: normalizeEth(parseFloat(s.x2y2Price).toFixed(5)),
          price: itemToList.x2y2Price,
          marketplace: "X2Y2",
        };
        tokenListingsMap[s.token].push(itemToList);
        itemsToSign.push(itemToList);
      }
    });

    localStorage.setItem("items-to-sign", JSON.stringify(itemsToSign));
    if (!isValidListingProcess(bulkListIdLocalState)) {
      return;
    }
    const listingItems = itemsToSign.filter((item) => {
      return item.marketplace === "LooksRare" || item.marketplace === "X2Y2"
    })

    setTotalItems(listingItems?.length + 1);
    setIsDialogCheckoutOpen(true);

    if (!isValidListingProcess(bulkListIdLocalState)) {
      return;
    }
    setTxStatus({
      ...defaultStatus,
      preflight: "processing",
      connectingWallet: "incomplete",
      nftApproval: "incomplete",
      listing: "incomplete",
    });
    await preflightCheck();
    await wait(1000);

    if (!isValidListingProcess(bulkListIdLocalState)) {
      return;
    }
    if (cart.sellBagItems?.some((i) => !i.isValid)) {
      setTxStatus({
        ...txStatus,
        preflight: "failed",
        connectingWallet: "incomplete",
        nftApproval: "incomplete",
        listing: "incomplete",
      });
      return;
    }

    setTxStatus({
      ...txStatus,
      preflight: "complete",
      connectingWallet: "processing",
      nftApproval: "incomplete",
      listing: "incomplete",
    });

    if (address && isConnected && !isReconnecting) {
      await wait(3000);
    }
    if (!isValidListingProcess(bulkListIdLocalState)) {
      return;
    }
    setTxStatus({
      ...txStatus,
      preflight: "complete",
      connectingWallet: "complete",
      nftApproval: "processing",
      listing: "incomplete",
    });

    if (!isValidListingProcess(bulkListIdLocalState)) {
      return;
    }

    // trigger list only for contract approval
    const tempListingsForApproval = Object.values(tokenListingsMap)[0];
    const approvalResults = await Promise.all(
      tempListingsForApproval.map(async (tl) => {
        setTxStatus({
          ...txStatusRef.current,
          [`nftApproval_${tl.marketplace}`]: "processing",
        });
        let isApprove = false;
        const theTl = { ...tl, fees: undefined };
        try {
          await getClient()?.actions.listToken({
            listings: [theTl],
            signer,
            onProgress: (steps) => {
              let status = steps[0].items[0]?.status;
              if (status === "complete") {
                setTxStatus({
                  ...txStatusRef.current,
                  [`nftApproval_${tl.marketplace}`]: status,
                });
                isApprove = true;
                throw new Error("Approval complete");
              } else {
                setTxStatus({
                  ...txStatusRef.current,
                  [`nftApproval_${tl.marketplace}`]: "approving",
                });
              }
            },
          });
        } catch (err) {
          if (err.message === "Approval complete") {
            isApprove = true;
          } else {
            // console.log("err", err);
            if (!isValidListingProcess(bulkListIdLocalState)) {
              return;
            }
            setTxStatus({
              ...txStatusRef.current,
              [`nftApproval_${tl.marketplace}`]: "failed",
            });
            isApprove = false;
          }

          return isApprove;
        }
      })
    );


    // stop process if any of approval is rejected / failed
    if (approvalResults.some((a) => !a)) {
      return;
    }

    if (!isValidListingProcess(bulkListIdLocalState)) {
      return;
    }
    setTxStatus({
      ...txStatus,
      preflight: "complete",
      connectingWallet: "complete",
      nftApproval: "complete",
      listing: "preparing",
    });

    await wait(2000);
    if (!isValidListingProcess(bulkListIdLocalState)) {
      return;
    }
    setTxStatus({
      ...txStatus,
      preflight: "complete",
      connectingWallet: "complete",
      nftApproval: "complete",
      listing: "processing",
    });


    let isAllSuccess = true;

    const listingParams = itemsToSign?.map((item) => {
      return {
        token: item.token,
        weiPrice: item.weiPrice,
        orderbook: item.orderbook,
        orderKind: item.orderKind,
        expirationTime: item.duration,
        fees: item.orderbook === "reservoir" ? item.fees : undefined,
      }
    })
    try {
      await getClient()?.actions.listToken({
        listings: listingParams,
        signer,
        onProgress: (steps) => {
          // console.log(steps)
          setTotalListItems(steps[1].items.length)
          const lastUnsignedIndex = _.findIndex(steps[1].items, { status: "incomplete" });
          const lastsignedIndex = _.findLastIndex(steps[1].items, { status: "complete" });
          if (lastUnsignedIndex !== -1 && steps[1].items[lastUnsignedIndex].status === "incomplete") {
            const orderData = steps[1].items[lastUnsignedIndex].orderIndexes.map((item) => {
              return {
                ...itemsToSign[item],
                sequence: lastUnsignedIndex + 1,
              }
            })
            setCurrentItemToSign(orderData)
          }
          if (lastsignedIndex !== -1 && steps[1].items[lastsignedIndex].status === "complete") {
            let orderIndexes = steps[1].items[lastsignedIndex].orderIndexes;
            orderIndexes.forEach((oIndex) => {
              itemsToSign[oIndex].isComplete = true;
            })
            localStorage.setItem("items-to-sign", JSON.stringify(itemsToSign));
          }
        },
      });
    } catch (err) {
      // console.log(err)
      if (!isValidListingProcess(bulkListIdLocalState)) {
        return false;
      }
      isAllSuccess = false;
      onStopBulkList();
      toast.error(err.message, {
        style: {
          maxWidth: "100%",
        },
      });

      return false;
    }

    if (!isValidListingProcess(bulkListIdLocalState)) {
      return false;
    }
    setTxStatus({
      ...txStatus,
      preflight: "complete",
      connectingWallet: "complete",
      nftApproval: "complete",
      listing: "complete",
    });
    if (isAllSuccess) {
      cart.deleteAllFromSellBag();
      localStorage.removeItem("items-to-sign");
      setAllItemsToSign(itemsToSign);
    }
    setCurrentItemToSign([]);
  };

  const handleDurationChange = (durationValue) => {
    setSelectedDuration(durationValue);
    for (let i = 0; i < cart.sellBagItems.length; i += 1) {
      cart.sellBagItems[i].duration = durationValue;
    }
    cart.addFilteredItemsToSellBag(cart.sellBagItems);
  };

  const handlePriceChange = (e, price, item) => {
    if (price === "" || price === 0) {
      e.target.parentElement.classList.add("Mui-error");
      // setIsInputError(true)
      // // document.getElementById("set-all-price").style.display = "block"
      // toast.error("Please enter valid price", {
      //   style: {
      //     maxWidth: "100%",
      //   },
      // });
    } else if (price > 999999) {
      price = 999999;
    } else if (!price?.toString()?.includes(".") && price.length > 6) {
      price = Number(price?.toString()?.slice(0, 6));
    } else if (price?.toString()?.split(".")[1]?.length > 4) {
      let newNumber =
        price?.toString()?.split(".")[0] +
        "." +
        price?.toString()?.split(".")[1]?.slice(0, 4);
      price = +newNumber;
    } else {
      e.target.parentElement.classList.remove("Mui-error");
    }
    const index = cart.sellBagItems.findIndex((n) => n.token === item.token);

    const { naFee, naPrice, osPrice, lrPrice, netPrice, x2y2Price } =
      calculatePriceInfo(price);

    cart.sellBagItems[index].price = price;
    cart.sellBagItems[index].naFee = naFee;
    cart.sellBagItems[index].netPrice = netPrice;
    cart.sellBagItems[index].osPrice = osPrice;
    cart.sellBagItems[index].lrPrice = lrPrice;
    cart.sellBagItems[index].naPrice = naPrice;
    cart.sellBagItems[index].x2y2Price = x2y2Price;

    cart.addFilteredItemsToSellBag(cart.sellBagItems);
  };

  const validateInput = (e) => {
    if (e.target.value === "" || e.target.value === 0) {
      e.target.parentElement.classList.add("Mui-error");
      setIsInputError(true);
      // document.getElementById("set-all-price").style.display = "block"
      toast.error("Please enter valid price", {
        style: {
          maxWidth: "100%",
        },
      });
    } else if (
      e.target.value < 0.0001 ||
      e.target.value?.toString()?.split(".")[1]?.length > 4
    ) {
      e.target.parentElement.classList.add("Mui-error");
      setIsInputError(true);
      // document.getElementById("set-all-price").style.display = "block"
      toast.error("Price must be greater than or equal to 0.0001", {
        style: {
          maxWidth: "100%",
        },
      });
    } else if (e.target.value > 999999) {
      e.target.parentElement.classList.add("Mui-error");
      setIsInputError(true);
      // document.getElementById("set-all-price").style.display = "block"
      toast.error("Price must be less than or equal to 999,999", {
        style: {
          maxWidth: "100%",
        },
      });
    } else {
      setIsInputError(false);
      // document.getElementById("set-all-price").style.display = "none"
      e.target.parentElement.classList.remove("Mui-error");
    }
  };

  const handleSetAllPrice = (e) => {
    // if (e.target.value?.toString()?.split('.')[0]?.length > 6) {
    //   console.log("1")
    //   console.log(e.target.value?.toString()?.split('.')[0]?.length)
    //   let newNumber = e.target.value?.toString()?.split('.')[0]?.slice(0, 6)
    //   // + "." + e.target.value?.toString()?.split('.')[1]?.slice(0, 4)
    //   e.target.value = Number(newNumber)
    // } else

    if (e.target.value === "" || e.target.value === 0) {
      e.target.parentElement.classList.add("Mui-error");
      // setIsInputError(true)
      // // document.getElementById("set-all-price").style.display = "block"
      // toast.error("Please enter valid price", {
      //   style: {
      //     maxWidth: "100%",
      //   },
      // });
    } else if (e.target.value > 999999) {
      e.target.value = 999999;
    } else if (
      !e.target.value?.toString()?.includes(".") &&
      e.target.value.length > 6
    ) {
      e.target.value = Number(e.target.value?.toString()?.slice(0, 6));
    } else if (e.target.value?.toString()?.split(".")[1]?.length > 4) {
      let newNumber =
        e.target.value?.toString()?.split(".")[0] +
        "." +
        e.target.value?.toString()?.split(".")[1]?.slice(0, 4);
      e.target.value = +newNumber;
    } else {
      e.target.parentElement.classList.remove("Mui-error");
    }

    const price = e.target.value;
    setPriceForAll(price);

    const { naFee, naPrice, osPrice, lrPrice, netPrice, x2y2Price } =
      calculatePriceInfo(price);

    cart.sellBagItems.forEach((d) => {
      d.price = price;
      d.naFee = naFee;
      d.netPrice = netPrice;
      d.osPrice = osPrice;
      d.lrPrice = lrPrice;
      d.evPrice = naPrice;
      d.naPrice = naPrice;
      d.x2y2Price = x2y2Price;
    });
    cart.addFilteredItemsToSellBag(cart.sellBagItems);
  };

  const onStopBulkList = () => {
    setIsDialogCheckoutOpen(false);
    localStorage.setItem("stop-listing", true);
    localStorage.removeItem(BULK_LISTING_PROCESS_ID_KEY);
  };

  const handleTabChange = (event, type) => {
    setCurrentBagType(event.target.value);
  };

  const handleStartClearanceClicked = () => {
    const clearanceCalculation = generateNewClearanceCalculation();
    setClearanceCalculation(clearanceCalculation);
    setIsClearanceModalOpen(true);
  }

  return (
    <>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="buy-cart-modal" sx={style}>
            {/* =============modal header============ */}
            <Box className="header">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    cursor: "pointer",
                    display: "block",
                    padding: "2px 5px",
                    lineHeight: "20px",
                    fontSize: "24px",
                    background: "transparent",
                    borderRadius: "9px",
                    border: "0px",
                    color: "#484343",
                    minWidth: "35px",
                  }}
                  onClick={handleClose}
                >
                  <Iconify
                    icon={"ic:twotone-arrow-back"}
                    sx={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </Button>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  Sell Bag ({cart.sellBagItems.length})
                </Typography>
                <Box style={{ textAlign: "right" }}>
                  {cart.sellBagItems.length > 0 && isConnected && !isReconnecting && (
                    <Button
                      sx={{
                        padding: "8px",
                        border: "1px solid #AAAAAA",
                        borderRadius: "4px",
                        // width: "60%",
                        textTransform: "unset",
                        fontSize: "15px",
                        color: "#484343",
                        lineHeight: 1,
                        // marginBottom: "15px",
                      }}
                      onClick={() => cart.deleteAllFromSellBag()}
                    >
                      Remove All
                      <Iconify
                        icon={"bi:dash-circle"}
                        sx={{
                          width: 15,
                          height: 15,
                          marginLeft: "10px",
                        }}
                      />
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
            {(isConnected && !isReconnecting) || cart.sellBagItems.length > 0 ? (
              <>
                {/* ===========Sell Bag Tab Section========= */}
                {/* <Box sx={{ width: '100%' }}>
                  <AntTabs variant="fullWidth" scrollButtons={false} value={currentBagType} onChange={handleTabChange}>
                    <AntTab label="Listing" value="Listing" style={{ flexGrow: 1, fontSize: "1rem" }} />
                    <AntTab label="Clearance" value="Clearance" style={{ flexGrow: 1, fontSize: "1rem" }} />
                  </AntTabs>
                </Box> */}
                {/* ===========Sell Bag Tab Content Section========= */}
                <Box
                  id="modal-modal-description"
                  sx={{
                    height: "calc(100vh - 70px)",
                    padding: { xs: "10px 20px", sm: "10px 30px" },
                  }}
                >
                  {/* ====== Listing Content======== */}
                  {currentBagType === "Listing" &&
                    <>
                      {!isBagLoading ? (
                        <>
                          <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            {cart.sellBagItems.length > 0 ? (
                              <>
                                <Box
                                  sx={{
                                    height: "100%",
                                    overflow: 'hidden',
                                    overflowY: 'hidden',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      marginTop: "10px",
                                      height: "97%",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          width: "50%",
                                        }}
                                      >
                                        <FormControl fullWidth>
                                          <InputLabel id="demo-simple-select-label">
                                            Listing Type
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currentBagType}
                                            sx={{ height: { sm: "55px", xs: "45px" } }}
                                            label="Listing Type"
                                            onChange={handleTabChange}
                                          >
                                            <MenuItem value={"Listing"}>Listing</MenuItem>
                                            <MenuItem value={"Clearance"}>Clearance</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          width: "50%",
                                        }}
                                      >
                                        <FormControl fullWidth>
                                          <InputLabel id="demo-simple-select-label">
                                            Listing Duration
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={cart.sellBagItems[0]?.duration || 720}
                                            sx={{ height: { sm: "55px", xs: "45px" } }}
                                            label="Listing Duration"
                                            onChange={(e) => handleDurationChange(e.target.value)}
                                          >
                                            <MenuItem value={1}>1 Hour</MenuItem>
                                            <MenuItem value={24}>24 Hours</MenuItem>
                                            <MenuItem value={168}>7 Days</MenuItem>
                                            <MenuItem value={720}>30 Days</MenuItem>
                                            <MenuItem value={2160}>3 Months</MenuItem>
                                            <MenuItem value={4320}>6 Months</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          width: "50%",
                                        }}
                                      >
                                        <FormControl fullWidth sx={{ alignItems: "flex-end" }}>
                                          <InputLabel
                                            id="demo-simple-input-label"
                                            shrink
                                            sx={{
                                              top: "0px",
                                              right: "7px",
                                              left: "unset",
                                              "&.MuiInputLabel-shrink": {
                                                background: "transparent",
                                              },
                                            }}
                                          >
                                            Set all Price
                                          </InputLabel>
                                          <OutlinedInput
                                            id="outlined-adornment-sell"
                                            endAdornment={
                                              <InputAdornment position="end">
                                                <EthIcon />
                                              </InputAdornment>
                                            }
                                            label="Set all Price"
                                            labelId="demo-simple-input-label"
                                            aria-describedby="outlined-sell-helper-text"
                                            type="number"
                                            value={priceForAll}
                                            placeholder="Maximum of 4 decimal places"
                                            // error={isInputError}
                                            inputProps={{
                                              "aria-label": "sell price",
                                              min: 0.0001,
                                              max: 999999,
                                              sx: {
                                                textAlign: "end",
                                                height: "0.4375em",
                                                "&::placeholder": {
                                                  fontSize: "10px",
                                                },
                                              },
                                            }}
                                            sx={{
                                              width: "100%",
                                              height: { sm: "55px", xs: "45px" },
                                              "& .MuiOutlinedInput-notchedOutline legend": {
                                                textAlign: "right",
                                              },
                                            }}
                                            onChange={handleSetAllPrice}
                                            onBlur={validateInput}
                                            onWheel={(e) => e.target.blur()}
                                            onKeyDown={(e) => {
                                              if (e.code === "ArrowUp" || e.code === "ArrowDown") {
                                                e.preventDefault();
                                              }
                                            }}
                                          // onInput={(e) => {
                                          //   const label = e.target.parentElement.previousElementSibling
                                          //   label.style.top = "0px";
                                          //   label.style.right = "18px";
                                          //   label.style.left = "unset";
                                          // }}
                                          // onBlur={(e) => {
                                          //   const label = e.target.parentElement.previousElementSibling
                                          //   if (e.target.value == "") {
                                          //     label.style.top = "-7px";
                                          //   } else {
                                          //     label.style.top = "0px";
                                          //   }
                                          // }}
                                          // onFocus={(e) => {
                                          //   const label = e.target.parentElement.previousElementSibling
                                          //   label.style.top = "0px";
                                          // }}
                                          />
                                          {/* {isInputError && (
                        <FormHelperText id="set-all-price" sx={{ color: "red" }}>
                          invalid price
                        </FormHelperText>
                      )} */}
                                        </FormControl>
                                      </Box>
                                    </Box>
                                    
                                    <Stack
                                      direction="row"
                                      spacing={1}
                                      mb={0}
                                      sx={{
                                        borderBottom: "1px solid lightgray",
                                        padding: "10px 0px 10px 0px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          fontSize: "17px",
                                          marginTop: "10px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        List On
                                      </Box>
                                      <Tooltip title="NameApes" placement="top" arrow>
                                        <IconButton
                                          sx={{
                                            position: "relative",
                                            color: "#fff",
                                            // backgroundColor: alsoNA ? "#000" : "#C1C1C1",
                                            fontSize: "10px",
                                            width: { xs: "35px", md: "39px" },
                                            height: { xs: "35px", md: "39px" },
                                            border: "1px solid #E8E8E8",
                                            "&:hover": {
                                              backgroundColor: "#7a7878",
                                            },
                                            // backgroundImage: `url(${lg})`,
                                            // backgroundSize: "cover",
                                            background:
                                              "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                                          }}
                                        // onClick={() => setAlsoNA(!alsoNA)}
                                        >
                                          {/* NA */}
                                          <CheckMark />
                                          <img
                                            src={lg}
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                              borderRadius: "15px",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="ENSVision" placement="top" arrow>
                                        <IconButton
                                          sx={{
                                            position: "relative",
                                            color: "#fff",
                                            backgroundColor: "#000",
                                            fontSize: "10px",
                                            width: { xs: "35px", md: "39px" },
                                            height: { xs: "35px", md: "39px" },
                                            "&:hover": {
                                              backgroundColor: "#7a7878",
                                            },

                                            // backgroundImage: "url(/ev.svg)",
                                            // backgroundSize: "cover",
                                          }}
                                        >
                                          <CheckMark />
                                          <img
                                            src={"/ev.svg"}
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              borderRadius: "15px",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="OpenSea" placement="top" arrow>
                                        <IconButton
                                          sx={{
                                            position: "relative",
                                            color: "#fff",
                                            //backgroundColor: alsoOS ? "#000" : "#C1C1C1",
                                            backgroundColor: "#000",
                                            fontSize: "10px",
                                            width: { xs: "35px", md: "39px" },
                                            height: { xs: "35px", md: "39px" },
                                            "&:hover": {
                                              backgroundColor: "#7a7878",
                                            },
                                            background: "url(/opensea.svg)",
                                            backgroundSize: "cover",
                                            //opacity: alsoOS ? 1 : 0.5,
                                          }}
                                          onClick={() => setAlsoOS(!alsoOS)}
                                        >
                                          {/* OS */}
                                          {alsoOS && <CheckMark />}
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="LooksRare" placement="top" arrow>
                                        <IconButton
                                          sx={{
                                            position: "relative",
                                            color: "#fff",
                                            backgroundColor: "#000",
                                            //backgroundColor: alsoLR ? "#000" : "#C1C1C1",
                                            fontSize: "10px",
                                            width: { xs: "35px", md: "39px" },
                                            height: { xs: "35px", md: "39px" },
                                            "&:hover": {
                                              backgroundColor: "#7a7878",
                                            },
                                            background:
                                              "url(https://raw.githubusercontent.com/reservoirprotocol/indexer/v5/src/models/sources/looksrare-logo.svg)",
                                            backgroundSize: "cover",
                                            //opacity: alsoLR ? 1 : 0.5,
                                          }}
                                          onClick={() => setAlsoLR(!alsoLR)}
                                        >
                                          {/* LR */}
                                          {alsoLR && <CheckMark />}
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="x2y2" placement="top" arrow>
                                        <IconButton
                                          sx={{
                                            position: "relative",
                                            color: "#fff",
                                            backgroundColor: "#000",
                                            //backgroundColor: alsoLR ? "#000" : "#C1C1C1",
                                            fontSize: "10px",
                                            width: { xs: "35px", md: "39px" },
                                            height: { xs: "35px", md: "39px" },
                                            "&:hover": {
                                              backgroundColor: "#7a7878",
                                            },
                                            background:
                                              "url(https://raw.githubusercontent.com/reservoirprotocol/indexer/v5/src/models/sources/x2y2-logo.png)",
                                            backgroundSize: "cover",
                                            //opacity: alsoLR ? 1 : 0.5,
                                          }}
                                          onClick={() => setAlsoX2Y2(!alsoX2Y2)}
                                        >
                                          {/* LR */}
                                          {alsoX2Y2 && <CheckMark />}
                                        </IconButton>
                                      </Tooltip>
                                    </Stack>

                                    <Box
                                      className="names-container"
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexGrow: 1,
                                        overflowY: "auto",
                                      }}
                                    >
                                      {cart.sellBagItems.map((d, i) => (
                                        ///////// cart item box /////////////////
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            // borderBottom: "1px solid rgba(0,0,0,0.1)",
                                            alignItems: "center",
                                            marginBottom: "15px",
                                          }}
                                          // onClick={() => console.log(cart.sellBagItems)}
                                          key={i}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              width: { sm: "65%", xs: "60%" },
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <Link
                                                style={{
                                                  color: d.isValid ? "#484343" : "#9a9898",
                                                  fontWeight: 600,
                                                  fontSize: "18px",
                                                }}
                                                href={generateTokenDetailUrl({
                                                  token: d.token,
                                                  name: d.name,
                                                })}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {!d.name
                                                  ? `${d.token.slice(0, 5)}...${d.token.slice(
                                                    d.token.length - 3,
                                                    d.token.length
                                                  )}`
                                                  : d.name?.length > 35
                                                    ? `${d.name?.slice(0, 35)}...`
                                                    : d.name}
                                              </Link>
                                              <Box
                                                style={{
                                                  fontSize: "14px",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  alignItems: "center",
                                                  marginTop: "5px",
                                                  color: d.isValid ? "#484343" : "#9a9898",
                                                }}
                                              >
                                                {d.isValid && (
                                                  <>
                                                    {!d.price || parseFloat(d.price) === 0 ? (
                                                      "Enter Price"
                                                    ) : alsoOS || alsoLR || alsoX2Y2 ? (
                                                      <>
                                                        Also
                                                        {alsoOS &&
                                                          marketPriceCircle(
                                                            "/opensea.svg",
                                                            d.osPrice
                                                          )}
                                                        {alsoLR &&
                                                          marketPriceCircle(
                                                            "https://raw.githubusercontent.com/reservoirprotocol/indexer/v5/src/models/sources/looksrare-logo.svg",
                                                            d.lrPrice
                                                          )}
                                                        {alsoX2Y2 &&
                                                          marketPriceCircle(
                                                            "https://raw.githubusercontent.com/reservoirprotocol/indexer/v5/src/models/sources/x2y2-logo.png",
                                                            d.x2y2Price
                                                          )}
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                )}

                                                {!d.isValid && "⚠️ Name cannot be sold"}

                                                {/* {
                                    alsoNA && (marketPriceCircle(`${lg}`, d.naPrice))
                                  } */}
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "row",
                                              padding: "8px 0px",
                                              alignItems: "center",
                                              justifyContent: "flex-end",
                                              width: { sm: "35%", xs: "40%" },
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                // padding: "8px 0px",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              {/* <Box>NA Price: </Box> */}
                                              <FormControl fullWidth>
                                                <InputLabel
                                                  id="demo-simple-price-label"
                                                  shrink
                                                  sx={{
                                                    top: "0px",
                                                    right: "18px",
                                                    left: "unset",
                                                    "&.MuiInputLabel-shrink": {
                                                      background: "white",
                                                    },
                                                    // "&.Mui-focused": {
                                                    //   top: "0px",
                                                    //   right: "18px",
                                                    // },
                                                  }}
                                                >
                                                  Price
                                                </InputLabel>
                                                <OutlinedInput
                                                  id="outlined-adornment-sell"
                                                  label="Price"
                                                  labelId="demo-simple-price-label"
                                                  aria-describedby="outlined-sell-helper-text"
                                                  placeholder="Set Price"
                                                  type="number"
                                                  disabled={!d.isValid}
                                                  inputProps={{
                                                    // "aria-label": "Price",
                                                    min: 0.0001,
                                                    max: 999999,
                                                    sx: {
                                                      textAlign: "end",
                                                      "&::placeholder": {
                                                        fontSize: "10px",
                                                        alignText: "left",
                                                      },
                                                    },
                                                  }}
                                                  onChange={(e) =>
                                                    handlePriceChange(e, e.target.value, d)
                                                  }
                                                  onBlur={validateInput}
                                                  onWheel={(e) => e.target.blur()}
                                                  onKeyDown={(e) => {
                                                    if (e.code === "ArrowUp" || e.code === "ArrowDown") {
                                                      e.preventDefault();
                                                    }
                                                  }}
                                                  value={d.price}
                                                  sx={{
                                                    width: "100%",
                                                    height: "45px",
                                                    "& .MuiOutlinedInput-notchedOutline legend":
                                                    {
                                                      textAlign: "right",
                                                    },
                                                    // "& .MuiOutlinedInput-root": {
                                                    // }
                                                  }}
                                                />
                                                {/* {isInputError && (
                                    <FormHelperText id="set-price" sx={{ color: "red" }}>
                                      invalid price
                                    </FormHelperText>
                                  )} */}
                                              </FormControl>
                                            </Box>
                                            {/* </Box> */}

                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                padding: "0px",
                                                alignItems: "center",
                                                marginLeft: "10px",
                                              }}
                                            >
                                              <Button
                                                sx={{
                                                  minWidth: "0px",
                                                  color: "#484343",
                                                  padding: "0px",
                                                }}
                                                onClick={() =>
                                                  cart.removeOneFromSellBag(d.token)
                                                }
                                              >
                                                <Iconify
                                                  icon={"bi:dash-circle"}
                                                  sx={{
                                                    width: 20,
                                                    height: 20,
                                                  }}
                                                />
                                              </Button>
                                            </Box>
                                            {/* <TextField
                                    id="outlined-basic"
                                    label="Sell Price"
                                    variant="outlined"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    //   InputLabelProps={{ sx: { fontSize: "13px" } }}
                                    size={"small"}
                                    sx={{ width: "47%", marginRight: "10px" }}
                                  />{" "}
                                  Eth */}
                                          </Box>
                                        </Box>
                                        ///////////// cart item box ends /////////////
                                      ))}
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ) : (
                              <Box
                                sx={{
                                  height: "100%",
                                  overflow: 'hidden',
                                  overflowY: 'hidden',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    // padding: "25px",
                                  }}
                                >
                                  <p style={{ textAlign: "center", fontSize: "14px" }}>
                                    Start adding names to sell from{" "}
                                    <Link
                                      href="/ens"
                                      style={{ textDecoration: "underline" }}
                                    >
                                      My ENS
                                    </Link>
                                  </p>
                                  <p style={{ textAlign: "center", fontSize: "14px" }}>
                                    List your ENS names on NameApes, ENS Vision, Opensea
                                    and LooksRare for maximum visibility.
                                  </p>
                                  {/* <p style={{ textAlign: "center", fontSize: "14px" }}>
                            1.5% marketplace fee on NameApes. <br />
                            <u>0% marketplace fee</u> on NameApes for 999/10K holders.
                          </p> */}
                                  <Link
                                    href="https://blog.ensdom.com/blog/listing-ens-domains-for-sale"
                                    target="_blank"
                                    style={{
                                      textDecoration: "underline",
                                      textAlign: "center",
                                    }}
                                  >
                                    FAQ
                                  </Link>
                                </Box>
                              </Box>
                            )}


                            {/* bottom */}
                            <Box
                              sx={{
                                // height: "25%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                borderTop: "1px solid lightgray",
                                marginTop: "15px"
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  // marginTop:'100px',
                                  // borderTop: "1px solid rgba(0,0,0,0.1)",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                    marginTop: { xs: "10px", sm: "10px" },
                                  }}
                                >
                                  <Link
                                    href="https://blog.ensdom.com/blog/listing-ens-domains-for-sale"
                                    target="_blank"
                                    style={{
                                      textAlign: "left",
                                      color: "#484343",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <span style={{fontWeight:"bold"}}>Fee: </span>
                                   {marketFee * 100}%
                                  </Link>
                                  <Box style={{ marginTop: "5px" }}>
                                    <Link
                                      href="https://blog.ensdom.com/blog/listing-ens-domains-for-sale"
                                      target="_blank"
                                      style={{
                                        textDecoration: "underline",
                                        color: "#D190FC",
                                      }}
                                    >
                                      Want 0%?
                                    </Link>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  {/* <Box
                                    style={{ fontWeight: "bold", marginTop: "10px" }}
                                  >
                                    Total Net
                                  </Box> */}
                                  <Tooltip
                                    title={cart.getTotalCostForSellBag(
                                      cart.sellBagItems.map((d) => d.netPrice)
                                    )}
                                    placement="top"
                                    arrow
                                  >
                                    <Box style={{ marginTop: "5px" }}>
                                    <span style={{fontWeight:"bold"}}>Net:</span> {cart
                                        .getTotalCostForSellBag(
                                          cart.sellBagItems.map((d) => d.netPrice)
                                        )
                                        .toFixed(4)}{" "}
                                      Ξ
                                    </Box>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      cart.getTotalCostForSellBag(
                                        cart.sellBagItems.map((d) => d.netPrice)
                                      ) * ethToUsdRate
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <Box style={{ marginTop: "5px" }}>
                                      ~$
                                      {(
                                        cart.getTotalCostForSellBag(
                                          cart.sellBagItems.map((d) => d.netPrice)
                                        ) * ethToUsdRate
                                      ).toFixed(2)}{" "}
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {isConnected && !isReconnecting && !isWhitelistUser && (
                                  <Button
                                    sx={{
                                      width: "100%",
                                      background:
                                        "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                                      color: "#FFFFFF",
                                      height: "54px",
                                      textTransform: "unset",
                                      "&:hover": {
                                        backgroundColor: "#C993FC",
                                      },
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      window.open(
                                        "https://blog.ensdom.com/blog/listing-ens-domains-for-sale",
                                        "_blank"
                                      );
                                    }}
                                  >
                                    Request for White List
                                  </Button>
                                )}
                                {isConnected && !isReconnecting && isWhitelistUser ? (
                                  cart.sellBagItems.length > 0 ? (
                                    <Button
                                      sx={{
                                        width: "100%",
                                        background:
                                          "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                                        color: "#FFFFFF",
                                        height: "54px",
                                        textTransform: "unset",
                                        "&:hover": {
                                          backgroundColor: "#C993FC",
                                        },
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        if (isInputError) {
                                          return toast.error(
                                            "Please enter valid price",
                                            {
                                              style: {
                                                maxWidth: "100%",
                                              },
                                            }
                                          );
                                        }
                                        bulkList();
                                      }}
                                      disabled={cart.sellBagItems?.some(
                                        (i) => !i.isValid
                                      )}
                                    >
                                      List {cart.sellBagItems.length} Names
                                    </Button>
                                  ) : (
                                    <Link
                                      sx={{
                                        width: "100%",
                                        border: "0px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: "flex",
                                        background:
                                          "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                                        color: "#FFFFFF",
                                        borderRadius: "4px",
                                        height: "54px",
                                      }}
                                      href="/ens"
                                    >
                                      My ENS
                                    </Link>
                                  )
                                ) : (
                                  <ConnectButton.Custom>
                                    {({
                                      account,
                                      chain,
                                      openAccountModal,
                                      openChainModal,
                                      openConnectModal,
                                      authenticationStatus,
                                      mounted,
                                    }) => {
                                      const ready =
                                        mounted && authenticationStatus !== "loading";
                                      const connected =
                                        ready &&
                                        account &&
                                        chain &&
                                        (!authenticationStatus ||
                                          authenticationStatus === "authenticated");

                                      return (
                                        <Box
                                          {...(!ready && {
                                            "aria-hidden": true,
                                            style: {
                                              opacity: 0,
                                              pointerEvents: "none",
                                              userSelect: "none",
                                            },
                                          })}
                                        >
                                          {(() => {
                                            if (!connected) {
                                              return (
                                                <Button
                                                  sx={{
                                                    width: "100%",
                                                    backgroundColor: "#C993FC",
                                                    color: "#FFFFFF",
                                                    "&:hover": {
                                                      backgroundColor: "#C993FC",
                                                    },
                                                  }}
                                                  variant="contained"
                                                  size="large"
                                                  onClick={openConnectModal}
                                                >
                                                  Ape In
                                                </Button>
                                              );
                                            }
                                          })()}
                                        </Box>
                                      );
                                    }}
                                  </ConnectButton.Custom>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        < Loader />
                      )}
                    </>}
                  {/* =========Clearance Content============= */}
                  {currentBagType === "Clearance" &&
                    <>
                      {!isBagLoading ? (
                        <>
                          {cart.sellBagItems.length > 0 ? (
                            <>
                              <Box sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}>
                                <Box
                                  className="names-container"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flexGrow: 1,
                                    overflowY: "auto",
                                  }}
                                >
                                  {cart.sellBagItems.map((d, i) => (
                                    ///////// cart item box /////////////////
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "15px",
                                      }}
                                      key={i}
                                    >
                                      <Link
                                        style={{
                                          color: d.isValid ? "#484343" : "#9a9898",
                                          fontWeight: 600,
                                          fontSize: "18px",
                                        }}
                                        href={generateTokenDetailUrl({
                                          token: d.token,
                                          name: d.name,
                                        })}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {!d.name
                                          ? `${d.token.slice(0, 5)}...${d.token.slice(
                                            d.token.length - 3,
                                            d.token.length
                                          )}`
                                          : d.name?.length > 35
                                            ? `${d.name?.slice(0, 35)}...`
                                            : d.name}
                                      </Link>
                                      <Button
                                        sx={{
                                          minWidth: "0px",
                                          color: "#484343",
                                          padding: "0px",
                                          marginLeft: "10px",
                                        }}
                                        onClick={() =>
                                          cart.removeOneFromSellBag(d.token)
                                        }
                                      >
                                        <Iconify
                                          icon={"bi:dash-circle"}
                                          sx={{
                                            width: 20,
                                            height: 20,
                                          }}
                                        />
                                      </Button>
                                    </Box>
                                  ))}
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    sx={{
                                      width: "100%",
                                      background:
                                        "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                                      color: "#FFFFFF",
                                      height: "54px",
                                      textTransform: "unset",
                                      "&:hover": {
                                        backgroundColor: "#C993FC",
                                      },
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                    }}
                                    onClick={handleStartClearanceClicked}
                                    disabled={cart.sellBagItems.length === 0}
                                  >
                                    List 0.2Ξ to 0.01Ξ
                                  </Button>
                                </Box>
                              </Box>
                            </>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                // padding: "25px",
                                height: "100%",
                                overflow: 'hidden',
                                overflowY: 'hidden',
                              }}
                            >
                              <p style={{ textAlign: "center", fontSize: "14px" }}>
                                Start adding names to sell from{" "}
                                <Link
                                  href="/ens"
                                  style={{ textDecoration: "underline" }}
                                >
                                  My ENS
                                </Link>
                              </p>
                              <p style={{ textAlign: "center", fontSize: "14px" }}>
                                List your ENS names on NameApes, ENS Vision, Opensea
                                and LooksRare for maximum visibility.
                              </p>
                              {/* <p style={{ textAlign: "center", fontSize: "14px" }}>
                            1.5% marketplace fee on NameApes. <br />
                            <u>0% marketplace fee</u> on NameApes for 999/10K holders.
                          </p> */}
                              <Link
                                href="https://blog.ensdom.com/blog/listing-ens-domains-for-sale"
                                target="_blank"
                                style={{
                                  textDecoration: "underline",
                                  textAlign: "center",
                                }}
                              >
                                FAQ
                              </Link>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Loader />
                      )}
                    </>
                  }
                </Box>
              </>
            )
              : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "calc(100vh - 70px)",
                      overflow: 'hidden',
                      overflowY: 'auto',
                      padding: { xs: "20px", sm: "30px" },
                    }}
                  >
                    <Box sx={{ padding: { xs: "0px 0px 20px", md: "0px 0px 30px" } }}>
                      <Typography
                        sx={{
                          textAlign: "start",
                          marginBottom: "10px",
                          fontSize: "18px",
                        }}
                      >
                        Connect Wallet to start selling your ENS names on NameApes,
                        ENS Vision, Opensea and LooksRare.
                      </Typography>
                      <Typography sx={{ textAlign: "start", fontSize: "18px" }}>
                      Only 1.5% marketplace fee. <br />
                        <Link
                          href="https://blog.ensdom.com/blog/listing-ens-domains-for-sale"
                          target="_blank"
                          style={{ textDecoration: "underline", color: "black" }}
                        >
                          0% marketplace fee
                        </Link>{" "}
                        for NameApes members.
                      </Typography>
                    </Box>
                    <ConnectButton.Custom>
                      {({
                        account,
                        chain,
                        openAccountModal,
                        openChainModal,
                        openConnectModal,
                        authenticationStatus,
                        mounted,
                      }) => {
                        // Note: If your app doesn't use authentication, you
                        // can remove all 'authenticationStatus' checks
                        const ready = mounted && authenticationStatus !== "loading";
                        const connected =
                          ready &&
                          account &&
                          chain &&
                          (!authenticationStatus ||
                            authenticationStatus === "authenticated");

                        return (
                          <Box
                            {...(!ready && {
                              "aria-hidden": true,
                              style: {
                                opacity: 0,
                                pointerEvents: "none",
                                userSelect: "none",
                                display: "flex",
                                justifyContent: "center",
                              },
                            })}
                          >
                            {(() => {
                              if (!connected) {
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      sx={{
                                        boxShadow: 0,
                                        background:
                                          "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                                        color: "#FFFFFF",
                                        // "&:hover": {
                                        //   backgroundColor: "#C993FC",
                                        // },
                                        fontSize: "18px",
                                        fontWeight: 600,
                                        // marginTop: "10px",
                                        marginBottom: { xs: "0px", md: "10px" },
                                        width: "100%",
                                        height: "60px",
                                        textTransform: "unset",
                                        // "&:hover": {
                                        //   backgroundColor: "#fff",
                                        //   color: "#484343",
                                        // },
                                      }}
                                      variant="contained"
                                      size="large"
                                      onClick={openConnectModal}
                                    >
                                      <Iconify
                                        icon={"ion:wallet-outline"}
                                        sx={{
                                          width: 29,
                                          height: 28,
                                          marginRight: "10px",
                                        }}
                                      />{" "}
                                      Ape In
                                    </Button>
                                  </Box>
                                );
                              }
                            })()}
                          </Box>
                        );
                      }}
                    </ConnectButton.Custom>
                    <Link
                      href="https://blog.ensdom.com/blog/listing-ens-domains-for-sale"
                      target="_blank"
                      underline="always"
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#484343",
                        textDecorationLine: "underline",
                        // marginTop: "10px",
                        textAlign: "start",
                        padding: "20px 0px",
                      }}
                    >
                      FAQ
                    </Link>
                  </Box>
                </>
              )}
          </Box>
        </Modal >

        <BulkSellingDialog
          open={isDialogCheckoutOpen}
          setOpen={setIsDialogCheckoutOpen}
          numberOfItems={totalItems}
          totalListItems={totalListItems}
          status={txStatus}
          tx={""}
          setCartModalOpen={setOpen}
          sellItems={[]}
          tokensData={[]}
          nonPurchasedItems={[]}
          currentItemToSign={currentItemToSign}
          ethToUsdRate={ethToUsdRate}
          onStopBulkList={onStopBulkList}
          allItemsToSign={allItemsToSign}
          cart={cart}
          marketplacesToApprove={marketplacesToApprove}
          handleCloseBag={handleClose}
        />
      </Box >
      {
        isClearanceModalOpen &&
        <StartClearanceDialog
          tokenInfos={cart.sellBagItems}
          cart={cart}
          open={isClearanceModalOpen}
          clearanceCalculation={clearanceCalculation}
          handleClose={() => setIsClearanceModalOpen(false)}
        />
      }

    </>
  );
};

export default SellBagModalV2;