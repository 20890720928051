import React from "react";
const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.sessionStorage.getItem(PageHasBeenForceRefreshed) || "false"
  );

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.sessionStorage.setItem(PageHasBeenForceRefreshed, "true");
    return window.location.reload();
  } else {
    window.sessionStorage.setItem(PageHasBeenForceRefreshed, "false");
  }
};
class ErrorBoundary extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.
  // }
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error)
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    if(error?.message?.includes("ChunkLoadError") || error?.includes("ChunkLoadError") || error?.Error?.includes("ChunkLoadError") ){
     retryPageLoading();
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;