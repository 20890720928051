import { useAccount } from "wagmi";
import { useEffect, useState, useContext } from "react";
import { APIContext } from "../utils/APIContext";

const osFee = 0.025;
const lrFee = 0.020;
const x2y2Fee = 0.005;

export function usePriceCalculator() {
    const { address, isConnected } = useAccount();
    const { myEns } = useContext(APIContext);
    const [marketFee, setMarketFee] = useState(0.015);
    
    let isMember = !!myEns?.isMember

    useEffect(() => {
        if (address && isConnected) {
            const fee = isMember ? 0 : 0.015;
            setMarketFee(fee)
        }
    }, [address, isConnected, isMember])

    const calculatePriceInfo = (price) => {
        const thePrice = parseFloat(price)
        const naPrice = thePrice;
        const netPrice = thePrice - (thePrice * marketFee);
        const osPrice = thePrice * 1.025;
        const lrPrice = thePrice * 1.025;
        const x2y2Price = thePrice * 1.025;
        return {
            naFee: marketFee,
            osFee,
            lrFee,
            x2y2Fee,
            naPrice,
            osPrice,
            lrPrice,
            netPrice,
            x2y2Price
        }
    }

    const syncMarketFee = () => {
        if (address && isConnected) {
            const fee = isMember ? 0 : 0.015;
            setMarketFee(fee)
        }
    }

    return {
        marketFee,
        calculatePriceInfo,
        syncMarketFee
    }
}