import { useMemo, useEffect, useState, useContext } from "react";
// import { useTable, useSortBy } from "react-table";
import { Link } from "react-router-dom";
import moment from "moment";
// import Checkbox from "@mui/material/Checkbox";
// import ReactPaginate from "react-paginate";
import Loader from "../common/Loader";
// import Left from "../assets/Left";
// import Rigth from "../assets/Rigth";
// import { IoBagRemoveSharp, IoBagAddSharp } from "react-icons/io5";
// import { decimalCount } from "../../api/calculation";
import ExpandableClubView from "../../components/common/ExpandableClubView";
// import CategoryBox from "../../components/common/CategoryBox";
import { generateTokenDetailUrl } from "../../utils/token-detail";
import { CartContext } from "../../utils/CartContext";
import { Button, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Iconify from "../iconify";

import { styled } from "@mui/system";
import { FEATURE_FLAG_BUY_TOKEN, FEATURE_FLAG_SELL_TOKEN } from "../../config";
import { BsFillCartPlusFill, BsFillCartDashFill, BsFillDashCircleFill, BsPlusCircleFill, BsDashCircleFill } from "react-icons/bs";

import { usePriceCalculator } from "../../hooks/priceCalculator";
import Pagination from '@mui/material/Pagination';
// import SellBagModal from "../bulkListing/SellBagModal";
import BuyCartModal from "../bulkBuy/BuyCartModal";
import SellBagModalV2 from "../bulkListing/SellBagModalV2";
import { useAccount } from "wagmi";
import { MarketPriceCircle } from "../common/MarketPriceCircle";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  td: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F9FA",
  },
  "&:nth-of-type(odd) td:first-of-type": {
    backgroundColor: "white",
    backgroundClip: "padding-box",
  },
  "&:nth-of-type(odd) td:nth-of-type(2)": {
    backgroundColor: "white",
    backgroundClip: "padding-box",
  },
  "&:nth-of-type(even) td:nth-of-type(2)": {
    backgroundColor: "#F6F9FA",
    backgroundClip: "padding-box",
  },
  "&:nth-of-type(even) td:first-of-type": {
    backgroundColor: "#F6F9FA",
    backgroundClip: "padding-box",
  },
  "&:last-child td, &:last-child th": {
    border: "0",
  },
}));

const StyledTableBodyCell = styled(TableCell)({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: {
    xs: "400",
    md: "600",
  },
  fontSize: {
    xs: "13px",
    md: "16px",
  },
  lineHeight: {
    xs: "16px",
    md: "19px",
  },
  color: "#484343",
});

// const calculateFeePercentage = (club) => {
//   return ['999 Club', '10k-club'].includes(club) ? 0 : 0.015
// }

// const priceCell = (row) => {
//   const desion = decimalCount(row?.listingPrice);
//   let num = row?.listingPrice;
//   if (desion > 3) {
//     num = (Math.round(row?.listingPrice * 1000) / 1000).toFixed(3);
//   }

//   return (
//     <>
//       <Box
//         sx={{
//           textAlign: "left",
//           display: "flex",
//         }}
//       >
//         {/* <Box sx={{ marginRight: "5px" }}>
//           {row?.currency && row?.currency === "WETH" && (
//             <svg
//               style={{ height: "20px", width: "20px" }}
//               viewBox="0 0 33 53"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M16.8498 0.666687L16.4974 1.85009V36.1896L16.8498 36.5371L32.9842 27.115L16.8498 0.666687Z"
//                 fill="#DA3979"
//               />
//               <path
//                 d="M16.85 0.666687L0.715607 27.115L16.85 36.5372V19.8699V0.666687Z"
//                 fill="#E781A9"
//               />
//               <path
//                 d="M16.8497 39.5552L16.6511 39.7944V52.0268L16.8497 52.6L32.9937 30.1378L16.8497 39.5552Z"
//                 fill="#DA3979"
//               />
//               <path
//                 d="M16.85 52.5998V39.5551L0.7'15607 30.1377L16.85 52.5998Z"
//                 fill="#E781A9"
//               />
//               <path
//                 d="M16.8497 36.537L32.9838 27.1151L16.8497 19.8699V36.537Z"
//                 fill="#671334"
//               />
//               <path
//                 d="M0.715607 27.1151L16.8498 36.537V19.8699L0.715607 27.1151Z"
//                 fill="#DA3979"
//               />
//             </svg>
//           )}
//           {row?.currency === "ETH" ? (
//             row?.orderKind === "mint" ? (
//               <></>
//             ) : (
//               <svg
//                 style={{ height: "20px", width: "20px" }}
//                 viewBox="0 0 33 53"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M16.3576 0.666687L16.0095 1.85009V36.1896L16.3576 36.5371L32.2976 27.115L16.3576 0.666687Z"
//                   fill="#343434"
//                 />
//                 <path
//                   d="M16.3578 0.666687L0.417816 27.115L16.3578 36.5372V19.8699V0.666687Z"
//                   fill="#8C8C8C"
//                 />
//                 <path
//                   d="M16.3575 39.5552L16.1613 39.7944V52.0268L16.3575 52.6L32.307 30.1378L16.3575 39.5552Z"
//                   fill="#3C3C3B"
//                 />
//                 <path
//                   d="M16.3578 52.5998V39.5551L0.417816 30.1377L16.3578 52.5998Z"
//                   fill="#8C8C8C"
//                 />
//                 <path
//                   d="M16.3575 36.537L32.2973 27.1151L16.3575 19.8699V36.537Z"
//                   fill="#141414"
//                 />
//                 <path
//                   d="M0.417816 27.1151L16.3576 36.537V19.8699L0.417816 27.1151Z"
//                   fill="#393939"
//                 />
//               </svg>
//             )
//           ) : (
//             ""
//           )}
//         </Box> */}
//         {num}
//       </Box>
//     </>
//   );
// };

function compare(a, b) {
  if (a?.price < b?.price) {
    return -1;
  }
  if (a?.price > b?.price) {
    return 1;
  }
  return 0;
}

const lowestMarketPlacesLisitings = (row) => {
  if (row?.listings?.length > 0) {
    const allSources = row?.listings.map(r => r?.source)
    const filteredAllSources = allSources?.filter((x, pos) => {
      if (!!x) {
        return allSources?.indexOf(x) === pos;
      }
      return false;
    });
    let allSourcesLowestPrice = [];
    filteredAllSources.forEach(x => {
      const sourceListings = row?.listings?.filter((item) => item?.source === x);
      sourceListings?.sort(compare);
      const lowestSourceListing = sourceListings?.[0];
      allSourcesLowestPrice.push(lowestSourceListing);
    })
    return allSourcesLowestPrice;
  }
}

const lowestListing = (row) => {
  if (row?.listings?.length > 0) {
    row?.listings?.sort(compare);
    return row?.listings[0];
  } else {
    return null;
  }
}

// const buyerCell = (row) => {
//   return (
//     <>
//       <Box
//         style={{
//           background: "#E5EEFC",
//           borderRadius: "4px",
//           padding: "10px",
//           width: "fit-content",
//           minWidth: "115px",
//         }}
//       >
//         <Link
//           style={{
//             textDecoration: "none",
//             color: "#484343",
//           }}
//           to={`/ens/${row?.maker}`}
//         >
//           {row?.maker?.slice(0, 5)}...
//           {row?.maker?.slice(row?.maker?.length - 3, row?.maker?.length)}
//         </Link>
//       </Box>
//     </>
//   );
// };

const MyEnsTable = ({
  MyEnsNames,
  pageCount,
  handlePageClick,
  productLoading,
  showSelected,
  onSelectedChanged,
  paramsAddress,
  currentPage
  // sellMode,
}) => {
  // const [tableColumn, setTableColumn] = useState([]);
  const [isMyNames, setIsMyNames] = useState(true);
  const cart = useContext(CartContext);
  const { address, isConnected, isReconnecting, isConnecting } = useAccount();
  // console.log(address, isConnected, isReconnecting, isConnecting)

  const [buyCartOpen, setBuyCartOpen] = useState(false);
  const [sellBagOpen, setSellBagOpen] = useState(false);

  const handleSellBagOpen = () => {
    setSellBagOpen(true);
  };
  const handleBuyCartOpen = () => {
    setBuyCartOpen(true);
  };

  const { calculatePriceInfo } = usePriceCalculator();

  useEffect(() => {
    // let newTableColumn = [];
    // if (showSelected) {
    //   newTableColumn = [
    //     {
    //       Header: "Selected",
    //       Cell: ({ row }) => {
    //         // console.log('ROW ', row.original)
    //         return (
    //           <Checkbox
    //             value={row.original.token}
    //             checked={row.original.isChecked === true}
    //             inputProps={{ "aria-label": "controlled" }}
    //             onClick={() => onSelectedChanged(row.original)}
    //           />
    //         );
    //       },
    //     },
    //   ];
    // }

    // newTableColumn = [
    //   ...newTableColumn,
    //   // {
    //   //   Header: "",
    //   //   accessor: "sell",
    //   //   Cell: ({ row }) => {
    //   //     return (
    //   //       <>
    //   //         {cart.sellBagItems.find(
    //   //           // (d) => d.labelName === row?.name?.replace(".eth", "")
    //   //           (d) => d.token === row.original?.token
    //   //         ) ? (
    //   //           <Button
    //   //             sx={{
    //   //               minWidth: "unset",
    //   //               borderRadius: "6px",
    //   //               fontSize: "20px",
    //   //               color: "white",
    //   //               backgroundColor: "#157347",
    //   //               "&:hover": {
    //   //                 backgroundColor: "#38a169",
    //   //               },
    //   //             }}
    //   //             onClick={() => {
    //   //               // cart.removeOneFromBuyCart(row?.name?.replace(".eth", ""));
    //   //               // let cartBtn = document.getElementById("cart-btn");
    //   //               // let currentDeg = parseInt(
    //   //               //   cartBtn.style.transform
    //   //               //     .replace("rotate(", "")
    //   //               //     .replace("deg)", "")
    //   //               // );
    //   //               // let rot = currentDeg >= -360 ? currentDeg - 360 : -360;
    //   //               // cartBtn.style.transform = `rotate(${rot}deg)`;
    //   //               cart.removeOneFromSellBag(row.original?.token);
    //   //             }}
    //   //           >
    //   //             <IoBagRemoveSharp />
    //   //           </Button>
    //   //         ) : (
    //   //           <Button
    //   //             sx={{
    //   //               minWidth: "unset",
    //   //               borderRadius: "6px",
    //   //               fontSize: "20px",
    //   //               color: "black",
    //   //             }}
    //   //             onClick={(e) => {
    //   //               // const id =
    //   //               //   row?.name?.length > 35
    //   //               //     ? `${row?.name?.slice(0, 35)}...`
    //   //               //     : row?.name;

    //   //               // const bodyRect = document.body.getBoundingClientRect();
    //   //               // const elemRect = document
    //   //               //   .getElementById(id)
    //   //               //   .getBoundingClientRect();
    //   //               // const top = elemRect.top - bodyRect.top;
    //   //               // const left = elemRect.left - bodyRect.left;
    //   //               // let cloneItem = document.getElementById(id).cloneNode(true);
    //   //               // cloneItem.style.position = "absolute";
    //   //               // cloneItem.style.top = top + "px";
    //   //               // cloneItem.style.left = left + "px";
    //   //               // cloneItem.style.animation =
    //   //               //   "item-to-card-animation 2s ease forwards";
    //   //               // cloneItem.classList.add("item-to-card-animation");
    //   //               // document.querySelector("#root").appendChild(cloneItem);
    //   //               // setTimeout(() => {
    //   //               //   cloneItem.remove();
    //   //               // }, 2000);

    //   //               // let cartBtn = document.getElementById("cart-btn");
    //   //               // let currentDeg = parseInt(
    //   //               //   cartBtn.style.transform
    //   //               //     .replace("rotate(", "")
    //   //               //     .replace("deg)", "")
    //   //               // );
    //   //               // let rot = currentDeg >= 360 ? currentDeg + 360 : 360;
    //   //               // cartBtn.style.transform = `rotate(${rot}deg)`;

    //   //               // cell.style.position = "absolute";
    //   //               const item = {
    //   //                 name: row.values?.name,
    //   //                 token: row.original?.token,
    //   //                 price: 0,
    //   //               };
    //   //               cart.addOneToSellBag(item);
    //   //             }}
    //   //           >
    //   //             <IoBagAddSharp />
    //   //           </Button>
    //   //         )}
    //   //       </>
    //   //     );
    //   //   },
    //   // },
    //   {
    //     Header: "Name",
    //     accessor: "name",
    //     Cell: ({ row }) => {
    //       return (
    //         <Link
    //           style={{ color: "black" }}
    //           to={{
    //             pathname: generateTokenDetailUrl(row?.original),
    //           }}
    //         //target="_blank"
    //         // rel="noreferrer"
    //         >
    //           {!row.values?.name
    //             ? `${row.original?.token?.slice(
    //               0,
    //               5
    //             )}...${row.original?.token?.slice(
    //               row?.original?.token?.length - 3,
    //               row?.original?.token?.length
    //             )}`
    //             : row.values?.name?.length > 35
    //               ? `${row.values?.name?.slice(0, 35)}...`
    //               : row.values?.name}
    //         </Link>
    //       );
    //     },
    //   },
    //   {
    //     Header: "Expires",
    //     accessor: "registrationExpiresAt",
    //     Cell: ({ row }) => {
    //       if (
    //         moment(row.values?.registrationExpiresAt).isSame(moment(), "day")
    //       ) {
    //         return moment(row.values?.registrationExpiresAt).format("HH:mm");
    //       } else if (row.values?.registrationExpiresAt) {
    //         return moment(row.values?.registrationExpiresAt).format("DD/MM/YY");
    //       }
    //     },
    //   },
    //   {
    //     Header: "Price",
    //     accessor: "listingPrice",
    //     Cell: ({ row }) => {
    //       return <>{row.values?.listingPrice}</>;
    //     },
    //   },
    //   // {
    //   //   Header: "Listing Expires",
    //   //   accessor: "listingExpiresAt",
    //   //   Cell: ({ row }) => {
    //   //     if (moment(row.values?.listingExpiresAt).isSame(moment(), "day")) {
    //   //       return moment(row.values?.listingExpiresAt).format("HH:mm");
    //   //     } else if (row.values?.listingExpiresAt) {
    //   //       return moment(row.values?.listingExpiresAt).format("DD/MM/YY");
    //   //     }
    //   //   },
    //   // },
    //   {
    //     Header: "Club",
    //     accessor: "club",
    //     Cell: ({ cell: { value } }) => (
    //       <>
    //         <div className="club-img-block">
    //           {value !== null && (
    //             <div className="club-img">
    //               <img
    //                 src={
    //                   "https://ens-production.s3.amazonaws.com/" +
    //                   value +
    //                   ".jpg"
    //                 }
    //                 alt={value}
    //                 width={35}
    //               />
    //               <span>{value}</span>
    //             </div>
    //           )}

    //           {!value && (
    //             <>
    //               <div className="club-img">
    //                 <img
    //                   src={
    //                     "https://ens-production.s3.amazonaws.com/uncategorized.jpg"
    //                   }
    //                   alt={"Uncategorized"}
    //                   width={35}
    //                 />
    //                 <span>{"Uncategorized"}</span>
    //               </div>
    //             </>
    //           )}
    //         </div>
    //       </>
    //     ),
    //   },
    // ];

    // setTableColumn(newTableColumn);

    if (paramsAddress) {
      paramsAddress?.toLowerCase() === address?.toLowerCase() && isConnected && !isReconnecting  && !isConnecting ? setIsMyNames(true) : setIsMyNames(false);
    } else {
      setIsMyNames(true);
    }
  }, [showSelected, cart.sellBagItems.length, paramsAddress, isMyNames, address, isConnected, isReconnecting, isConnecting]);

  // const columns = useMemo(() => tableColumn, [tableColumn]);
  // const data = useMemo(() => MyEnsNames, [MyEnsNames]);

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   prepareRow,
  //   rows,
  //   toggleHideColumn,
  // } = useTable(
  //   {
  //     columns,
  //     data,
  //   },
  //   useSortBy
  // );

  // useEffect(() => {
  //   toggleHideColumn("sell", !sellMode);
  // }, [sellMode]);

  const tableHeader = [
    "",
    "Name",
    "Clubs",
    "Expires",
    "Listed (Ξ)",
    "Last (Ξ)",
    // "Listing Expires",
  ];

  // const clubInfo = {};

  return (
    <>
      {
        productLoading ?
          <Box
            sx={{
              // position: "relative",
              // left: "20%",
              marginBottom: "50px",
              width: "100%",
              paddingLeft: { xs: "100px", sm: "200px" },
            }}
          >
            <Loader />
          </Box>
          :
          MyEnsNames.length === 0 ?
            <Typography sx={{ textAlign: "center" }}>No Names Found</Typography>
            :
            <>
              <TableContainer
                sx={{
                  border: "1px solid rgba(145, 145, 145, 0.37)",
                  borderRadius: "8px",
                }}
              >
                <Table
                  sx={{
                    minWidth: "650",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: {
                      xs: "400",
                      md: "600",
                    },
                    fontSize: {
                      xs: "13px",
                      md: "16px",
                    },
                    lineHeight: {
                      xs: "16px",
                      md: "19px",
                    },
                    color: "#484343",
                  }}
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#FBFBFB",
                    }}
                  >
                    <TableRow sx={{}}>
                      {tableHeader.map((e, i) => (
                        <TableCell
                          sx={
                            i === 0
                              ? {
                                position: "sticky",
                                left: 0,
                                zIndex: "2",
                                width: "41px",

                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: {
                                  xs: "400",
                                  md: "600",
                                },
                                fontSize: {
                                  xs: "13px",
                                  md: "16px",
                                },
                                lineHeight: {
                                  xs: "16px",
                                  md: "19px",
                                },
                                color: "#484343",
                                backgroundColor: "#FBFBFB",
                                backgroundClip: "padding-box",
                                display: FEATURE_FLAG_SELL_TOKEN ? "" : "none",
                              }
                              : i === 1 && e === "Name"
                                ? {
                                  position: "sticky",
                                  left: FEATURE_FLAG_SELL_TOKEN && (MyEnsNames.find(n => n.listingExpiresAt !== null) || isMyNames)
                                    ? "52px"
                                    : (!MyEnsNames.find(n => n.listingExpiresAt !== null) && !isMyNames)
                                      ? "32px" : "0px",
                                  zIndex: "2",

                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontWeight: {
                                    xs: "400",
                                    md: "600",
                                  },
                                  fontSize: {
                                    xs: "13px",
                                    md: "16px",
                                  },
                                  lineHeight: {
                                    xs: "16px",
                                    md: "19px",
                                  },
                                  color: "#484343",
                                  backgroundColor: "#FBFBFB",
                                  backgroundClip: "padding-box",
                                  paddingLeft: "10px",
                                }
                                : {
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontWeight: {
                                    xs: "400",
                                    md: "600",
                                  },
                                  fontSize: {
                                    xs: "13px",
                                    md: "16px",
                                  },
                                  lineHeight: {
                                    xs: "16px",
                                    md: "19px",
                                  },
                                  color: "#484343",
                                  backgroundColor: "#FBFBFB",
                                  backgroundClip: "padding-box",
                                }
                          }
                        >
                          {e}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {MyEnsNames.map((row) => {

                      return <StyledTableRow sx={{
                        position: 'relative',
                        background: cart.sellBagItems.find((d) => d.token === row?.token) || cart.buyCartItems.find((d) => d === row?.token)
                          ? "#E6E3FC !important"
                          : "auto"
                      }}>
                        <StyledTableBodyCell
                          sx={{
                            position: "sticky",
                            left: 0,
                            zIndex: "2",
                            display: FEATURE_FLAG_SELL_TOKEN ? "" : "none",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            paddingY: "10px",
                            paddingLeft: "10px",
                            paddingRight: "0px",
                            backgroundColor: cart.sellBagItems.find((d) => d.token === row?.token) || cart.buyCartItems.find((d) => d === row?.token)
                              ? "#E6E3FC !important"
                              : "auto"
                            // width: FEATURE_FLAG_SELL_TOKEN ? "68px" : "auto",
                          }}
                        >
                          {FEATURE_FLAG_SELL_TOKEN && isMyNames && (cart.sellBagItems.find(
                            // (d) => d.labelName === row?.name?.replace(".eth", "")
                            (d) => d.token === row?.token
                          ) ? (
                            <Button
                              sx={{
                                minWidth: "42px",
                                borderRadius: "6px",
                                fontSize: "20px",
                                color: "#7E7E7E",
                                // backgroundColor: "#157347",
                                // "&:hover": {
                                //   backgroundColor: "#38a169",
                                // },
                                padding: "0px",
                                display: "grid",
                              }}
                              // disabled={!row.listingExpiresAt && isMyNames ? false : true}
                              onClick={() => {
                                cart.removeOneFromSellBag(row?.token);
                              }}
                            >
                              {/* {(!row.listingExpiresAt && isMyNames) && ( */}
                              <>
                                <Iconify
                                  icon={"ep:sell"}
                                  sx={{
                                    width: 24,
                                    height: 24,
                                    gridColumn: 1,
                                    gridRow: 1,
                                  }}
                                />
                                <span style={{
                                  width: "24px",
                                  height: "24px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  gridColumn: 1,
                                  gridRow: 1,
                                  color: "#7E7E7E",
                                  textAlign: "center",
                                  // padding: "px",
                                  lineHeight: "25px",
                                }}>
                                  -
                                </span>
                              </>
                              {/* )} */}
                            </Button>
                          ) : (
                            isMyNames && (
                              <Button
                                sx={{
                                  minWidth: "42px",
                                  borderRadius: "6px",
                                  fontSize: "20px",
                                  color: "#22A847",
                                  // backgroundColor: "#157347",
                                  // "&:hover": {
                                  //   backgroundColor: "#38a169",
                                  // },
                                  padding: "0px",
                                  display: "grid",
                                }}
                                // disabled={!row.listingExpiresAt && isMyNames ? false : true}
                                onClick={(e) => {
                                  const id = !row?.name
                                    ? `${row?.token?.slice(
                                      0,
                                      5
                                    )}...${row?.token?.slice(
                                      row?.token?.length - 3,
                                      row?.token?.length
                                    )}`
                                    : row?.name?.length > 35
                                      ? `${row?.name?.slice(0, 35)}...`
                                      : row?.name
                                  const bodyRect = document.body.getBoundingClientRect();
                                  const elemRect = document
                                    .getElementById(id)
                                    .getBoundingClientRect();
                                  const cartBtnRect = document
                                    .getElementById("cart-btn")
                                    .getBoundingClientRect();
                                  const item_to_card_animation = `@keyframes item-to-card-animation {
                                0% {
                                  opacity: 100%;
                                  transform: scale(1);
                                }

                                99% {
                                  top: ${cartBtnRect.top}px;
                                  left: ${cartBtnRect.left}px;
                                  transform: scale(0.2);
                                  z-index: 100;
                                }

                                100% {
                                  opacity: 0%;
                                }
                              }
                              `
                                  let dynamicStyles = document.createElement('style');
                                  dynamicStyles.type = 'text/css';
                                  document.head.appendChild(dynamicStyles);
                                  dynamicStyles.sheet.insertRule(item_to_card_animation);
                                  const top = elemRect.top - bodyRect.top;
                                  const left = elemRect.left - bodyRect.left;
                                  let cloneItem = document.getElementById(id).cloneNode(true);
                                  cloneItem.style.position = "absolute";
                                  cloneItem.style.top = top + "px";
                                  cloneItem.style.left = left + "px";
                                  cloneItem.style.animation =
                                    "item-to-card-animation 2s ease forwards";
                                  cloneItem.classList.add("item-to-card-animation");
                                  document.querySelector("#root").appendChild(cloneItem);
                                  setTimeout(() => {
                                    cloneItem.remove();
                                  }, 2000);

                                  const {
                                    naFee,
                                    naPrice,
                                    osPrice,
                                    lrPrice,
                                    netPrice,
                                    x2y2Price
                                  } = calculatePriceInfo(5);

                                  const item = {
                                    name: row?.name,
                                    token: row?.token,
                                    price: 5,
                                    netPrice,
                                    osPrice,
                                    lrPrice,
                                    duration: 720,
                                    naFee,
                                    naPrice,
                                    x2y2Price,
                                    club: row.club,
                                    wrappedDomain: row.wrappedDomain
                                  };
                                  if (cart.sellBagItems.length < 1) {
                                    handleSellBagOpen()
                                  }
                                  cart.addOneToSellBag(item);
                                }}
                              >
                                <>

                                  <Iconify
                                    icon={"ep:sell"}
                                    sx={{
                                      width: 24,
                                      height: 24,
                                      gridColumn: 1,
                                      gridRow: 1,
                                    }}
                                  />
                                  <span style={{
                                    width: "24px",
                                    height: "24px",
                                    fontSize: "11px",
                                    lineHeight: "25px",
                                    fontWeight: "600",
                                    gridColumn: 1,
                                    gridRow: 1,
                                    color: "#22A847",
                                    textAlign: "center",
                                    //padding: "3px",
                                  }}>
                                    +
                                  </span>
                                </>
                              </Button>
                            )
                          ))}

                          {FEATURE_FLAG_BUY_TOKEN && (row.listingExpiresAt && !isMyNames) && (cart.buyCartItems.find(
                            // (d) => d.labelName === row?.name?.replace(".eth", "")
                            (d) => d.token === row?.token
                          ) ? (
                            <Button
                              sx={{
                                minWidth: "42px",
                                borderRadius: "6px",
                                fontSize: "20px",
                                color: "#7E7E7E",
                                // backgroundColor: "#157347",
                                // "&:hover": {
                                //   backgroundColor: "#38a169",
                                // },
                                padding: "0px",
                                display: "grid",
                              }}
                              // disabled={!row.listingExpiresAt && isMyNames ? false : true}
                              onClick={() => {
                                cart.removeOneFromBuyCart(row?.token);
                              }}
                            >
                              {/* {(!row.listingExpiresAt && isMyNames) && ( */}
                              {/* <BsFillCartDashFill /> */}
                              {/* )} */}
                              <Iconify
                                icon={"material-symbols:shopping-cart-outline"}
                                sx={{
                                  width: 24,
                                  height: 24,
                                  // marginRight: "4px",
                                  color: '#A5A5A5'
                                }}
                              />
                              <BsDashCircleFill style={{
                                //BsDashCircle
                                position: 'absolute',
                                top: "-10%",
                                left: '65%',
                                fill: '#C993FC',
                                width: '12px',
                                height: '12px',
                                color: 'white',
                                //background: "white",
                                borderRadius: '50%',
                                strokeWidth: '0'
                              }} />
                              {/* <Box sx={{

                        }}>
                          <BsDashCircleFill style={{
                            position: 'absolute',
                            top: "-10%",
                            left: '50%',
                            background: 'linear-gradient(91.87deg, #88b1fd -5.48%, #d190fc 100%)',
                            width: '12px',
                            height: '12px',
                            //background: "white",

                            shapeRendering: "crispEdges",
                            // fill: "linear-gradient(91.87deg, #88b1fd -5.48%, #d190fc 100%)"
                          }} />
                          </Box> */}
                              {/* <Box sx={{
                          position: 'absolute',
                          top: "-12%",
                          left: '65%',
                        //  borderRadius: '50%',


                        }}>
                          <svg style={{fontSize:'12px', display: "flex", height: "1em", width: "1em"}} version="1.1" xmlns="http://www.w3.org/2000/svg">

                            <defs>
                              <linearGradient id='MyGradient' gradientUnits='userSpaceOnUse' x1='-1.58%' y1='48.32%' x2='101.58%' y2='51.68%'>
                                <stop stop-color='#88b1fd' />
                                <stop offset='1' stop-color='#d190fc' />
                              </linearGradient>
                            </defs>
                            <BsFillDashCircleFill style={{
                              fill: 'url(#MyGradient)',

                            }} />
                          </svg>
                        </Box> */}
                            </Button>
                          ) : (
                            row.listingExpiresAt && !isMyNames && (
                              <Button
                                sx={{
                                  minWidth: "42px",
                                  borderRadius: "6px",
                                  fontSize: "20px",
                                  color: "#22A847",
                                  // backgroundColor: "#157347",
                                  // "&:hover": {
                                  //   backgroundColor: "#38a169",
                                  // },
                                  padding: "0px",
                                  display: "grid",
                                }}
                                // disabled={!row.listingExpiresAt && isMyNames ? false : true}
                                onClick={(e) => {
                                  const id = !row?.name
                                    ? `${row?.token?.slice(
                                      0,
                                      5
                                    )}...${row?.token?.slice(
                                      row?.token?.length - 3,
                                      row?.token?.length
                                    )}`
                                    : row?.name?.length > 35
                                      ? `${row?.name?.slice(0, 35)}...`
                                      : row?.name
                                  const bodyRect = document.body.getBoundingClientRect();
                                  const elemRect = document
                                    .getElementById(id)
                                    .getBoundingClientRect();
                                  const cartBtnRect = document
                                    .getElementById("cart-btn")
                                    .getBoundingClientRect();
                                  const item_to_card_animation = `@keyframes item-to-card-animation {
                                0% {
                                  opacity: 100%;
                                  transform: scale(1);
                                }

                                99% {
                                  top: ${cartBtnRect.top}px;
                                  left: ${cartBtnRect.left}px;
                                  transform: scale(0.2);
                                  z-index: 100;
                                }

                                100% {
                                  opacity: 0%;
                                }
                              }
                              `
                                  let dynamicStyles = document.createElement('style');
                                  dynamicStyles.type = 'text/css';
                                  document.head.appendChild(dynamicStyles);
                                  dynamicStyles.sheet.insertRule(item_to_card_animation);
                                  const top = elemRect.top - bodyRect.top;
                                  const left = elemRect.left - bodyRect.left;
                                  let cloneItem = document.getElementById(id).cloneNode(true);
                                  cloneItem.style.position = "absolute";
                                  cloneItem.style.top = top + "px";
                                  cloneItem.style.left = left + "px";
                                  cloneItem.style.animation =
                                    "item-to-card-animation 2s ease forwards";
                                  cloneItem.classList.add("item-to-card-animation");
                                  document.querySelector("#root").appendChild(cloneItem);
                                  setTimeout(() => {
                                    cloneItem.remove();
                                  }, 2000);
                                  if (cart.buyCartItems.length < 1) {
                                    handleBuyCartOpen()
                                  }
                                  cart.addOneToBuyCart({ name: row?.name, token: row?.token, wrappedToken: row?.wrappedDomain ? row?.wrappedDomain?.token : null });
                                }}
                              >
                                <Iconify
                                  icon={"material-symbols:shopping-cart-outline"}
                                  sx={{

                                    width: "24px",
                                    height: "24px",
                                    //marginRight: "4px",
                                    color: '#22A847',

                                  }}
                                />
                                {/* <BsFillCartPlusFill /> */}
                                <BsPlusCircleFill style={{
                                  //BsDashCircle
                                  position: 'absolute',
                                  top: "-10%",
                                  left: '65%',
                                  fill: '#074017',
                                  width: '12px',
                                  height: '12px',
                                  color: 'white',
                                  //background: "white",
                                  borderRadius: '50%',
                                  strokeWidth: '0'
                                }} />
                              </Button>
                            )
                          ))}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell
                          sx={{
                            position: "sticky",
                            left: FEATURE_FLAG_SELL_TOKEN && (MyEnsNames.find(n => n.listingExpiresAt !== null) || isMyNames)
                              ? "52px"
                              : (!MyEnsNames.find(n => n.listingExpiresAt !== null) && !isMyNames)
                                ? "32px" : "0px",
                            zIndex: "2",
                            paddingLeft: "10px",
                            backgroundColor: cart.sellBagItems.find((d) => d.token === row?.token) || cart.buyCartItems.find((d) => d === row?.token)
                              ? "#E6E3FC !important"
                              : "auto",
                          }}
                          className="ens-name-col"
                          id={
                            !row?.name
                              ? `${row?.token?.slice(
                                0,
                                5
                              )}...${row?.token?.slice(
                                row?.token?.length - 3,
                                row?.token?.length
                              )}`
                              : row?.name?.length > 35
                                ? `${row?.name?.slice(0, 35)}...`
                                : row?.name
                          }
                        >
                          <Link
                            style={{ color: "black", overflowWrap: 'break-word' }}
                            to={{
                              pathname: generateTokenDetailUrl(row),
                            }}
                          // target="_blank"
                          // rel="noreferrer"
                          >
                            {!row?.name
                              ? `${row?.token?.slice(
                                0,
                                5
                              )}...${row?.token?.slice(
                                row?.token?.length - 3,
                                row?.token?.length
                              )}`
                              : row?.name?.length > 35
                                ? `${row?.name?.slice(0, 15)}...`
                                : row?.name}
                          </Link>
                        </StyledTableBodyCell>

                        <StyledTableBodyCell>
                          <ExpandableClubView clubs={row?.club} />
                        </StyledTableBodyCell>

                        <StyledTableBodyCell>
                          <Tooltip
                            title={
                              row?.registrationExpiresAt &&
                              moment(row?.registrationExpiresAt).format(
                                "DD MMMM YYYY \\a\\t HH:mm"
                              )
                            }
                            placement="top-start"
                          >
                            <Box>
                              {row.registrationExpiresAt &&
                                moment(row?.registrationExpiresAt)
                                  .fromNow()
                                  .replace("in ", "")
                                  .replace("months", "mo")
                                  .replace("month", "mo")
                                  .replace("years", "yr")
                                  .replace("year", "yr")
                                  .replace("days", "day")
                                  .replace("hours", "hr")
                                  .replace("minutes", "min")
                              }

                            </Box>
                          </Tooltip>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          <Box sx={{
                            display: { sm: 'flex', xs: "none" },
                            flexDirection: 'row',
                          }}>
                            {
                              lowestMarketPlacesLisitings(row)?.length > 0 && lowestMarketPlacesLisitings(row)?.map((m) => {
                                return <MarketPriceCircle market={m?.source} price={m?.price} />
                              })
                            }
                          </Box>
                          <Box sx={{
                            display: { sm: 'none', xs: "flex" },
                            flexDirection: 'row',
                          }}>
                            {
                              lowestListing(row) &&
                              <MarketPriceCircle market={lowestListing(row)?.source} price={lowestListing(row)?.price} />
                            }
                          </Box>
                          {/* {FEATURE_FLAG_SELL_TOKEN && isMyNames &&!cart.sellBagItems.find((d) => d.token === row?.token) ? <></>:<Box sx={{
                          zIndex: "3",
                          position: 'absolute',
                          left: '0',
                          top: '0',
                          right: '0',
                          bottom: '0',
                          background: 'linear-gradient(91.87deg, rgba(136, 177, 253, 0.25) -5.48%, rgba(209, 144, 252, 0.25) 100%) !important',
                          pointerEvents: 'none'
                        }}></Box>
                      } */}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          <Box sx={{
                            flexDirection: 'row',
                          }}>
                            {row.lastSalePrice ? `${row.lastSalePrice}Ξ` : ""}
                          </Box>
                        </StyledTableBodyCell>
                        {
                          // FEATURE_FLAG_BUY_TOKEN && (row.listingExpiresAt && !isMyNames) && 
                          // cart.buyCartItems.some((d) => d === row?.token) 
                        }
                        {/* <StyledTableBodyCell>
                      <Tooltip
                        title={
                          row?.listingExpiresAt &&
                          moment(row?.listingExpiresAt).format(
                            "DD MMMM YYYY \\a\\t HH:mm"
                          )
                        }
                        placement="top-start"
                      >
                        <Box>
                          {row.listingExpiresAt &&
                            moment(row?.listingExpiresAt)
                              .fromNow()
                              .replace("in ", "")
                              .replace("months", "mo")
                              .replace("years", "yr")
                              .replace("days", "day")
                              .replace("hours", "hr")
                              .replace("minutes", "min")
                          }
                          
                        </Box>
                      </Tooltip>
                    </StyledTableBodyCell> */}
                      </StyledTableRow>
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                className="flex_pegi"
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  className="margin"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                ></Box>
                {/* <ReactPaginate
              previousLabel={<Left />}
              nextLabel={<Rigth />}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            /> */}
                <Pagination
                  size="large"
                  color="primary"
                  page={currentPage}
                  count={pageCount}
                  onChange={handlePageClick}
                />
              </Box>
            </>
      }
      <SellBagModalV2
        open={sellBagOpen}
        setOpen={setSellBagOpen}
        cart={cart}
      // handleOpen={handleBuyCartOpen}
      />
      <BuyCartModal
        open={buyCartOpen}
        setOpen={setBuyCartOpen}
        cart={cart}
      // handleOpen={handleBuyCartOpen}
      />
    </>
  );
};

export default MyEnsTable;
