import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const selectStyle = {
  background: '#FBFBFB',
  height: '40px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '19px',
  color: '#5F5A5A',
}

const FilterBtn = (props) => {
  const { selectName, selectValues, searchCriteria, setSearchCriteria } = props;
  return (
    <>
      <FormControl fullWidth >
        <InputLabel id="scop-select-label">{selectName.charAt(0).toUpperCase() + selectName.slice(1)}</InputLabel>
        <Select
          labelId="scop-select-label"
          label={selectName.charAt(0).toUpperCase() + selectName.slice(1)}
          sx={selectStyle}

          value={selectName === "Sort By"
              ? [
              searchCriteria?.sortBy,
              searchCriteria?.sortDirection == 1 ? "A" : "D",
            ].join("_")
              : searchCriteria[selectName] ? searchCriteria[selectName] : selectValues.All}
          onChange={(e) => {
            const _searchCriteria = { ...searchCriteria };
            // console.log(e.target.value)
            if (selectName === "Sort By") {
              const selected_value = e.target.value.split("_");
              const sortBy = selected_value[0];
              const sortDirection = selected_value[1] === "A" ? 1 : -1;
              _searchCriteria.sortDirection = sortDirection;
              _searchCriteria.sortBy = sortBy;
            } else {
              _searchCriteria[selectName] = e.target.value;
            }
            setSearchCriteria(_searchCriteria);
          }}
        >
          {Object.keys(selectValues).map((key, i) => (
            <MenuItem value={selectValues[key]} key={i} >{key}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* <div style={{ width: "100%", marginLeft: "10px" }}>
        <label
          style={{ fontSize: "18px", fontWeight: "bold", display: "block" }}
          for="exampleInputEmail1"
        >
          {selectName.charAt(0).toUpperCase() + selectName.slice(1)}
        </label>
        <select
          className="form-select form-select-md input_top"
          style={{ display: "block" }}
          placeholder={selectName}
          value={
            selectName === "Sort By"
              ? searchCriteria.sortBy + "," + searchCriteria.sortDirection
              : searchCriteria[selectName]
          }
          onChange={(e) => {
            const _searchCriteria = { ...searchCriteria };
            if (selectName === "Sort By") {
              _searchCriteria.sortDirection = e.target.value.split(",")[1];
              _searchCriteria.sortBy = e.target.value.split(",")[0];
            } else {
              _searchCriteria[selectName] = e.target.value;
            }
            setSearchCriteria(_searchCriteria);
          }}
        >
          {Object.keys(selectValues).map((key) => (
            <option value={selectValues[key]}>{key}</option>
          ))}
        </select>
      </div> */}
    </>
  );
};

export default FilterBtn;
