import './polyfills';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import React, { useState, useContext, useEffect, Suspense, lazy } from "react";
import { ThemeProvider } from "react-ui";
import { Toaster, toast } from "react-hot-toast";
import useSWR, { SWRConfig } from 'swr'

import { RainbowKitProvider, connectorsForWallets, getDefaultWallets } from "@rainbow-me/rainbowkit";
import { injectedWallet, rainbowWallet, coinbaseWallet, metaMaskWallet, walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";
import { WagmiConfig, createClient, configureChains, mainnet, goerli } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from 'wagmi/providers/public';
// import {
//   ReservoirKitProvider,
//   darkTheme as defaultTheme,
// } from "@reservoir0x/reservoir-kit-ui";
import { createClient as createResvClient } from "@reservoir0x/reservoir-sdk";
import { getReservoirApiUrl } from "./utils";
import ErrorBoundary from "./utils/ErrorBoundary";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";

import "./App.css";
import "@rainbow-me/rainbowkit/styles.css";

import {
  ETHEREUM_NETWORK,
  ALCHEMY_API_KEY,
  MARKETPLACE_FEE_RECIPIENT,
  RESERVOIR_API_KEY,
} from "./config";
import { Box, CircularProgress } from "@mui/material";
import MyEnsPageV2 from "./pages/MyEnsPageV2";

const HomePage = lazy(() => import("./pages/HomePage"));
const Layout = lazy(() => import("./pages/Layout"));
const TokenDetailV2 = lazy(() => import("./pages/TokenDetailV2"));
const TokenDetailV3 = lazy(() => import("./pages/TokenDetailV3"));
const MyEnsPage = lazy(() => import("./pages/MyEnsPage"));
const BulkSearch = lazy(() => import("./pages/BulkSearch"));
const BulkRegisterPage = lazy(() => import("./pages/BulkRegisterPage"));
const SearchPage = lazy(() => import("./pages/SearchPage"));

const SalesSearch = lazy(() => import("./components/searchPage/sales/Sales"));
const AvailableSearch = lazy(() =>
  import("./components/searchPage/available/available")
);
const Listing = lazy(() => import("./components/searchPage/listing/Listing"));

const CartProvider = lazy(() => import("./utils/CartContext"));
const APIProvider = lazy(() => import("./utils/APIContext"));
createResvClient({
  chains: [
    {
      id: ETHEREUM_NETWORK === "mainnet" ? mainnet : goerli,
      baseApiUrl: getReservoirApiUrl(),
      default: true,
      apiKey: RESERVOIR_API_KEY,
    },
  ],
  source: "nameapes.com",
});

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECTID;

const { chains, provider } = configureChains(
  [ETHEREUM_NETWORK === "mainnet" ? mainnet : goerli],
  [
    alchemyProvider({ 
      alchemyId: "pckshlhkxrm62oxw",
      apiKey: ALCHEMY_API_KEY,
      priority: 0,
    }),
    publicProvider(),
  ]
);

// const { wallets } = getDefaultWallets({
//   appName: "Sales Ensdom",
//   projectId,
//   chains,
// });

const connectors = connectorsForWallets([
  // ...wallets,
  {
    groupName: 'Recommended',
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      walletConnectWallet({
        projectId,
      }),
      coinbaseWallet({appName: "Sales Ensdom", chains}),

      metaMaskWallet({projectId, 
      })
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

// export const ThemeSwitcherContext = React.createContext({
//   theme: defaultTheme(),
//   setTheme: null,
// });

// const ThemeSwitcher = ({ children }) => {
//   const [theme, setTheme] = useState(defaultTheme());

//   return (
//     <ThemeSwitcherContext.Provider value={{ theme, setTheme }}>
//       {children}
//     </ThemeSwitcherContext.Provider>
//   );
// };

const AppWrapper = ({ children }) => {
  // const { theme } = useContext(ThemeSwitcherContext);
  return (
    // <ReservoirKitProvider
    //   options={{
    //     apiBase: getReservoirApiUrl(),
    //     apiKey: RESERVOIR_API_KEY,
    //     marketplaceFee: 0,
    //     marketplaceFeeRecipient: MARKETPLACE_FEE_RECIPIENT,
    //     source: "NameApes.com",
    //     disablePoweredByReservoir: true,
    //   }}
    //   theme={theme}
    // >
    <ErrorBoundary fallback={
      <div style={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
        loading...
      </div>
    }>
      <ThemeProvider
        attribute="class"
        defaultTheme="light"
        value={{
          light: "light",
        }}
      >
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider chains={chains}>
            {children}
          </RainbowKitProvider>
        </WagmiConfig>
      </ThemeProvider>
    </ErrorBoundary>
    // </ReservoirKitProvider>
  );
};

const App = () => {
  return (
    <>
      <AppWrapper>
        <SWRConfig value={{ provider: () => new Map() }}>
          <CartProvider>
            <APIProvider>
              <BrowserRouter>
                <Suspense
                  fallback={
                    <Box
                      style={{
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  <ScrollToTop />
                  <Routes>
                    <Route path="" element={<Layout />}>
                      <Route path="" element={<HomePage />} />
                      <Route path=":clubslug" element={<SearchPage />}>
                        <Route index element={<Listing />} />
                        <Route path="buy" element={<Listing />} />
                        <Route path="sold" element={<SalesSearch />} />
                        <Route path="register" element={<AvailableSearch />} />
                      </Route>

                      <Route path="token/:token" element={<TokenDetailV3 />} />
                      <Route
                        path="eth/:name/:token"
                        element={<TokenDetailV3 />}
                      />
                      <Route path="eth/:name" element={<TokenDetailV3 />} />

                      {/* <Route path="/my-ens/:myAddress" element={<MyEnsPage />} />
                      <Route path="/ens/:myAddress" element={<MyEnsPage />} />
                      <Route path="/my-ens" element={<MyEnsPage />} />
                      <Route path="/ens" element={<MyEnsPage />} /> */}

                      <Route path="/my-ens/:myAddress" element={<MyEnsPageV2 />} />
                      <Route path="/ens/:myAddress" element={<MyEnsPageV2 />} />
                      <Route path="/my-ens" element={<MyEnsPageV2 />} />
                      <Route path="/ens" element={<MyEnsPageV2 />} />

                      <Route path="/bulk-search" element={<BulkSearch />} />
                      <Route
                        path="/bulk-register"
                        element={<BulkRegisterPage />}
                      />
                    </Route>
                  </Routes>
                </Suspense>
              </BrowserRouter>
            </APIProvider>
          </CartProvider>
        </SWRConfig>
      </AppWrapper>
      <Toaster position="bottom-center" />
    </>
  );
};

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}
