import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTransaction } from "wagmi";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button } from "@mui/material";
import { FcApproval } from "react-icons/fc";
import { ETHEREUM_NETWORK } from "../../config";
import Iconify from "../iconify";
import { generateTokenDetailUrl } from "../../utils/token-detail";

const BulkBuyingDialog = ({
  open,
  setOpen,
  numberOfItems,
  totalEthPrice,
  totalUsdPrice,
  status,
  tx,
  setCartModalOpen,
  buyItems,
  tokensData,
  nonPurchasedItems,
}) => {
  // const totalItems = numberOfItems;
  // const ethPrice = totalEthPrice;
  // const usdPrice = totalUsdPrice;
  // const items = buyItems;
  // const tData = tokensData;
  // console.log(
  //   numberOfItems,
  //   totalEthPrice,
  //   totalUsdPrice,
  //   buyItems,
  //   tokensData
  // );
  const [totalItems, setTotalItems] = useState();
  const [ethPrice, setEthPrice] = useState();
  const [usdPrice, setUsdPrice] = useState();
  const [items, setItems] = useState();
  const [tData, setTData] = useState();
  const getValidItems = () => {
    const compare = (a, b) =>
      a.token.tokenId === b.wrappedToken || a.token.tokenId === b.token;
    const validItems = tData?.filter((i) => items.some((d) => compare(i, d)));
    return validItems;
  };
  const handleClose = (event, reason) => {
    if (reason && (reason == "backdropClick" || reason == "escapeKeyDown"))
      return;
    setOpen(false);
  };
  const navigate = useNavigate();
  const goToTokenDetail = (item) => {
    const url = generateTokenDetailUrl({
      token: item?.originalToken,
      name: item.name,
    });
    window.open(url);
  };
  // const { data, isError, isLoading } = useTransaction({
  //   hash: "0x22fc040c5336909fcf710a93e388843786e014337c8bd9799d20b096fdf3b826",
  // });
  // console.log(data.value._hex);
  useEffect(() => {
    setTimeout(() => {
      setTotalItems(numberOfItems);
      setEthPrice(totalEthPrice);
      setUsdPrice(totalUsdPrice);
      setItems(buyItems);
      setTData(tokensData);
    }, 1000);
  }, [open]);
  return (
    <>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="purchase-dialog"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            background:
              "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
            padding: "10px 20px",
            alignItems: "center",
            color: "white",
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {status === "complete"
              ? "Purchase Successful"
              : "Complete Checkout"}
          </DialogTitle>
          <Button
            onClick={handleClose}
            sx={{
              fontSize: "1.5rem",
              padding: "0px",
              height: "27px",
              color: "white",
              minWidth: "unset",
            }}
          >
            &times;
          </Button>
        </Box>
        <DialogContent
          sx={{ padding: "20px 15px", position: "relative", height: "80%" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              // justifyContent: "space-between",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              alignContent: "flex-start",
              marginBottom: "15px",
              height: "39%",
              marginTop: "10px",
              overflowY: "auto",
            }}
          >
            {getValidItems()?.map(
              (d, i) => (
                <Box
                  style={{
                    padding: "10px",
                    borderRadius: "20px",
                    border: "1px solid lightgray",
                    fontSize: "13px",
                    marginRight: "20px",
                    marginBottom: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => goToTokenDetail(d)}
                  key={i}
                >
                  {status !== "complete" ? (
                    ""
                  ) : nonPurchasedItems?.find(
                      (p) => p.token === d.token.tokenId
                    ) ? (
                    <Iconify
                      icon={"gridicons:cross-circle"}
                      sx={{
                        width: 24,
                        height: 24,
                        color: "#F71717",
                        marginRight: "10px",
                      }}
                    />
                  ) : (
                    <Iconify
                      icon={"ep:success-filled"}
                      sx={{
                        width: 24,
                        height: 24,
                        color: "#32CE5E",
                        marginRight: "10px",
                      }}
                    />
                  )}
                  <span>{d?.name || d.token?.name || "!"}</span>
                  <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                    {parseFloat(
                      d?.market?.floorAsk?.price?.amount?.decimal ||
                        d?.lowestTokenListing?.price?.amount?.decimal
                    ).toFixed(4)}{" "}
                    Ξ{" "}
                  </span>
                </Box>
              )
              //   <Box
              //     sx={{
              //       display: "flex",
              //       flexDirection: "row",
              //       width: "fit-content",
              //       fontSize: { xs: "14px", sm: "16px" },
              //       border: "1px solid #B0B0B0",
              //       borderRadius: "100px",
              //     }}
              //     key={i}
              //   >
              //     <p style={{ margin: "0px 10px" }}>
              //       {d.token.name || "!"} {
              //       d?.market?.floorAsk?.price?.amount?.decimal
              //       }Ξ
              //       {d.token.name || "!" + d?.market?.floorAsk?.price?.amount?.decimal
              //         //   +
              //         // `${d.token.tokenId.slice(0, 3)}...${d.token.tokenId.slice(
              //           //   d.token.tokenId.length - 3,
              //           //   d.token.tokenId.length
              //           // )}`
              //         }
              //     </p>
              //   // `${d.token.name || "!"} (${
              //   //   d?.market?.floorAsk?.price?.amount?.decimal
              //   // }Ξ), `
              // </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-between",
              // borderBottom: "1px solid rgba(0,0,0,0.1)",
              alignItems: "center",
              justifyContent: "center",
              height: "5%",
              // marginBottom: "15px",
            }}
          >
            <Box sx={{ marginRight: "4px" }}>
              <strong>{totalItems} items </strong>for
              <strong> {parseFloat(ethPrice).toFixed(4)} Ξ</strong> (~$
              {parseFloat(usdPrice).toFixed(2)})
            </Box>
            {/* <div
                style={{
                  fontWeight: 600,
                  marginRight: "4px",
                }}
              >
                {parseFloat(ethPrice).toFixed(4)} ETH
              </div>
              <div style={{ marginRight: "4px" }}>
                (${parseFloat(usdPrice).toFixed(4)})
              </div> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "16%",
              marginTop: "10px",
              position: "relative",
              // bottom: "16px",
            }}
          >
            <button
              style={{
                width: "100%",
                border: "0px",
                textAlign: "center",
                background:
                  "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                borderRadius: "4px",
                color: "#fff",
                height: "60px",
                position: "absolute",
                bottom: "-4px",
                opacity: status === "complete" ? 1 : 0.5,
              }}
              onClick={() => {
                handleClose();
                setCartModalOpen(false);
                navigate("/ens");
              }}
              disabled={status === "complete" ? false : true}
            >
              {!status && <CircularProgress size={15} color={"inherit"} />}
              {status === "incomplete" && (
                <>
                  {/* <CircularProgress size={15} color={"inherit"} /> */}
                  Waiting for approval...
                </>
              )}
              {status === "validating" && (
                <>
                  <CircularProgress size={15} color={"inherit"} /> Finalizing
                </>
              )}
              {status === "complete" && <>My ENS</>}
            </button>
          </Box>
          <Box height={"20%"} sx={{ textAlign: "center", marginTop: "20px" }}>
            {!status && <CircularProgress size={15} color={"inherit"} />}
            {status === "incomplete" && (
              <>
                <strong>Go to your wallet</strong> <br />
                You'll be asked to approve this purchase from your wallet <br />
              </>
            )}
            {status === "validating" && (
              <>
                {/* <strong>Finalizing</strong> <br /> */}
                Finalizing your purchase on the chain…
                <br />
              </>
            )}
            {status === "complete" && (
              <>
                {/* <strong>Congratulations!</strong> <br /> */}
                {/* <FcApproval
                  style={{ fontSize: "18px", verticalAlign: "text-top" }}
                /> */}
                <Iconify
                  icon={"ep:success-filled"}
                  sx={{
                    width: 24,
                    height: 24,
                    color: "#32CE5E",
                    marginRight: "10px",
                  }}
                />
                Your purchase was successful!{" "}
                {nonPurchasedItems?.length === 0
                  ? ""
                  : `{except for ${nonPurchasedItems?.length} names}`}{" "}
                <br />
                <a
                  href={
                    ETHEREUM_NETWORK === "goerli"
                      ? `https://goerli.etherscan.io/tx/${tx}`
                      : `https://etherscan.io/tx/${tx}`
                  }
                  target="_blank"
                  style={{ textAlign: "center", color: "#0d6efd" }}
                >
                  View on Etherscan
                </a>{" "}
                <br />
              </>
            )}
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default BulkBuyingDialog;
