import { Box, Button, CircularProgress, InputAdornment, Modal, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ethers } from 'ethers';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useDebounce } from 'use-debounce';
import { getInvited, inviteMember } from '../../api/requests';
import moment from 'moment';
import { useProvider } from 'wagmi';
import { toast } from 'react-hot-toast';
import { useCallback } from 'react';
import { useContext } from 'react';
import { APIContext } from '../../utils/APIContext';
import { normalize } from '../../utils/ens-service';

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    maxWidth: '700px',
    maxHeight: '80%',
    boxShadow: 24,
    padding: "20px"
};

const MemberInviteModal = ({ open, handleClose, paramsAddress, parentCallback }) => {
    const [ensName, setENSName] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [debouncedText] = useDebounce(searchText, 500)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [errorAddress, setErrorAddress] = useState('')
    const [myInvityData, setMyInvityData] = useState([])
    const { signOutWithEthereum } = useContext(APIContext);

    const provider = useProvider();

    const resolveENS = useCallback(async () => {
        setIsLoading(true);
        if (debouncedText && ethers.utils.isAddress(debouncedText)) {
            try {
                let ensname = await provider.lookupAddress(debouncedText);
                const alreadyInvited = myInvityData?.some((item) => item.to?.toLowerCase() === debouncedText?.toLowerCase())
                if (alreadyInvited) {
                    // eslint-disable-next-line array-callback-return
                    setIsLoading(false);
                    setErrorAddress(debouncedText)
                    setErrorText("Already Invited")
                } else {
                    setIsLoading(false);
                    setErrorText("")
                    setErrorAddress("")
                    setENSName({ name: ensname, address: debouncedText });
                }
            } catch {
                setIsLoading(false);
            }
        }
        else {
            let searchAddress = normalize(`${debouncedText.replace(".eth", "")}.eth`);
            let address = await provider.resolveName(searchAddress?.beautifiedName)
            if (!address) {
                setENSName(null)
                setIsLoading(false);
                setErrorText("No address has been set for this name.")
                setErrorAddress("")
            } else {
                const alreadyInvited = myInvityData?.some((item) => item?.to?.toLowerCase() === address?.toLowerCase())
                if (alreadyInvited) {
                    // eslint-disable-next-line array-callback-return
                    setIsLoading(false);
                    setErrorAddress(address)
                    setErrorText("Already Invited")
                }
                else {
                    setIsLoading(false);
                    setErrorText("")
                    setErrorAddress("")
                    setENSName({ name: debouncedText, address: address });
                }
            }
        }
    }, [debouncedText, myInvityData, provider]);

    useEffect(() => {
        if (debouncedText) {
            resolveENS()
        }
    }, [debouncedText, resolveENS]);

    const fetchMyInvitedData = useCallback(async () => {
        setIsLoadingData(true)
        await getInvited().then(async (res) => {
            const _result = await Promise.all(res?.data?.invited?.map(async (s) => {
                const ensname = await provider.lookupAddress(s.to).then(function (name) {
                    if (name === null) {
                        return ''
                    } else {
                        return name
                    };
                });
                let _s = s
                _s.toname = ensname
                return _s
            }))
            setIsLoadingData(false)
            setMyInvityData(_result)
        })
            .catch((err) => {
                toast.error(err.response.data.message);
                if (err.response.status === 401) {
                    setIsLoadingData(false)
                    handleClose()
                    signOutWithEthereum()
                }
                return
            });
    }, [handleClose, provider, signOutWithEthereum])

    useEffect(() => {
        fetchMyInvitedData()
    }, [fetchMyInvitedData, open])

    const onInvite = (address) => {
        let _body = {}
        _body.from = paramsAddress;
        _body.to = address;
        inviteMember(_body).then((res) => {
            if (res?.data?.success) {
                toast.success("sucessfully invited")
                handleClose()
                fetchMyInvitedData()
            }
        })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
                if (err.response.status === 401) {
                    signOutWithEthereum()
                }
            });
    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper variant="outlined" sx={{
                    ...style, display: 'flex', flexDirection: 'column',
                    width: { xl: "40%", md: "60%", xs: "90%" }
                }}>
                    {
                        myInvityData?.length < 3 &&
                        <>
                            <TextField
                                type='text'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FiSearch />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        (!isLoadingData && (myInvityData?.length > 0 || ensName)) &&
                                        <InputAdornment position="end" >
                                            < Typography sx={{ textAlign: "right" }} >{`Invited: ${myInvityData?.length ? myInvityData?.length : 0}/3`}</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                autoFocus
                                size='small'
                                className='mb-3'
                                placeholder="Search, public address (0x), or ENS"
                                fullWidth
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setSearchText(e.target.value)
                                    }
                                    else {
                                        setENSName(null)
                                        setErrorText("")
                                        setSearchText('')
                                        setErrorAddress("")
                                    }
                                }}
                                value={searchText}
                                aria-labelledby="search-label"
                            />
                            {/* {(!isLoadingData && (myInvityData?.length > 0 || ensName)) &&
                                < Typography sx={{ textAlign: "right" }} gutterBottom>{`Invited: ${myInvityData?.length ? myInvityData?.length : 0}/3`}</Typography>
                            } */}
                        </>
                    }
                    {isLoading ? <CircularProgress size="1.5rem" style={{ alignSelf: "center", marginTop: "15px" }} />
                        : errorText ?
                            <>
                                {errorAddress && <Typography sx={{ fontFamily: "Inter", color: "#8E8C84", fontSize: "13px", wordBreak: "break-all", textAlign: "center" }}>{errorAddress}</Typography>}
                                <Typography sx={{ color: "#f44336", marginBottom: "10px", textAlign: "center" }}>{errorText}</Typography>
                            </>
                            : null
                    }
                    {
                        (ensName && !isLoading && !errorText) &&
                        <>
                            <Typography sx={{ fontWeight: "bold", marginBottom: "5px" }}>Address</Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <Stack direction={"column"}>
                                        <Typography sx={{ fontFamily: "Inter", color: "#8E8C84", fontSize: "13px", wordBreak: "break-all" }}>{ensName?.name ? <><Typography variant='span' sx={{ fontWeight: "700" }}>{ensName?.name}</Typography> | </> : ""}{ensName?.address}</Typography>
                                    </Stack>
                                    <Button
                                        variant="contained"
                                        onClick={() => onInvite(ensName?.address)}
                                        sx={{
                                            background:
                                                "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                                            color: "#FFF",
                                            padding: "5px 20px",
                                            borderRadius: "4px",
                                            marginLeft: "5px"
                                        }}
                                    >Invite</Button>
                                </Box>
                            </Box>
                        </>
                    }
                    {
                        isLoadingData ?
                            <CircularProgress size="1.5rem" style={{ alignSelf: "center", marginTop: "15px" }} />
                            :
                            <>
                                {
                                    myInvityData?.length > 0 &&
                                    <>
                                        <Box>
                                            <Typography sx={{ fontWeight: "bold", marginBottom: "5px", paddingBottom: myInvityData?.length < 3 ? "" : "5px", borderBottom: myInvityData?.length < 3 ? "" : "1px solid #dddddd" }}>Your Invited Address</Typography>
                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                {myInvityData?.length > 0 &&
                                                    <>
                                                        {myInvityData?.map((data) => (
                                                            <>
                                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "5px" }}>
                                                                    <Stack direction={"column"}>
                                                                        <Typography sx={{ fontFamily: "Inter", color: "#8E8C84", fontSize: "13px", wordBreak: "break-all" }}>{data.toname ? <><Typography variant='span' sx={{ fontWeight: "700" }}>{data.toname}</Typography> | </> : ""}{data?.to}</Typography>
                                                                    </Stack>
                                                                    <Tooltip
                                                                        edge="end"
                                                                        placement="top"
                                                                        title={moment(data?.createdAt).format("DD/MM/YYYY")}
                                                                    >
                                                                        <Box>
                                                                            <Typography color="#D190FC" sx={{ marginLeft: "5px" }}>Invited</Typography>
                                                                        </Box>
                                                                    </Tooltip>
                                                                </Box>
                                                            </>
                                                        )
                                                        )}
                                                    </>
                                                }
                                            </Box>
                                        </Box>
                                    </>
                                }
                            </>
                    }
                </Paper>
            </Modal >
        </>
    );
};

export default MemberInviteModal;