import { ethers } from "ethers";
import { toHex } from "./misc-utils.service";
import { registrationService } from "./index";
// import { calculateDomainsPrice } from "./ens-service";
// import { generalConfigurations } from "../configurations/general-configs";
// import { addPayment } from "./payment-service";
import { ETHEREUM_NETWORK } from "../config";

const payNoMarketAddress = "0x0000000000000000000000000000000000000000";

const ENSContracts = {
  registrar: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
  resolver: "0x4976fb03C32e5B8cfe2b6cCB31c09Ba78EBaBa41",
  resolverTestnet: "0xd7a4F6473f32aC2Af804B3686AE8F1932bC35750",
  registrarController: "0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5",
  migrationContract: "0x6109DD117AA5486605FC85e040ab00163a75c662",
  token: "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85",
  nullResolver: payNoMarketAddress,
};
// ------------del-------
// const PaymentTypesEnum = {
//   PAY: 0,
//   REGISTER: 1,
//   COMMIT: 2,
//   TX_APPROVAL: 3,
//   TX_TRANSFER: 4,
//   TX_RENEW: 5,
// };

let paymentCommitent;
let priceRangesofRegistration;
export async function commitRegistration(
  provider,
  domains,
  payer,
  registrant,
  resolverSet,
  duration,
  ethUsdPrice,
  ownerEthName,
  walletAddress,
  chainId,
  user,
  signer
) {
  const secret = "0x" + toHex(produceNonce());
  const ensResolver =
    ETHEREUM_NETWORK === "goerli"
      ? ENSContracts.resolverTestnet
      : ENSContracts.resolver;

  const compiledPacket =
    registrationService.registrationDomainsToCommitmentPacket(
      registrant,
      duration,
      secret,
      resolverSet === true ? ensResolver : ENSContracts.nullResolver,
      domains
    );
  return await registrationService
    .createRegistrationCommitmentPacket(compiledPacket, payer, provider, signer)
    .then(async (commitmentPacketAndPaymentResult) => {
      // console.log(commitmentPacketAndPaymentResult);
      if (commitmentPacketAndPaymentResult === false) {
        const err = "gas limit error";
        throw err;
      }
      const [commitmentPacket, paymentResult] =
        commitmentPacketAndPaymentResult;
      const registrationPriceRanges = commitmentPacket.priceRanges;
      priceRangesofRegistration = registrationPriceRanges;
      paymentCommitent = compiledPacket;
      return [compiledPacket, paymentResult.hash, priceRangesofRegistration];
    })
    .catch((e) => {
      throw e;
    });
}

export async function completeRegistration(
  provider,
  rowData,
  payer,
  ethUsdPrice,
  ownerEthName,
  walletAddress,
  chainId,
  user,
  signer,
  totalEthPrice
) {
  // const contract = await registrationService.getEnsBulkRegistrationContract(
  //   provider
  // );
  const commitedRegistration = paymentCommitent;
  const rawRegistrationRecord = rowData?.find((c) => "reserveData" in c)
    ? rowData?.find((c) => "reserveData" in c)?.reserveData
    : commitedRegistration;
  const registrationsList = rowData?.find((c) => "reserveData" in c)
    ? rowData?.filter((c) => "reserveData" in c)
    : commitedRegistration?.map((d) => {
        return d.metadata;
      });

  const resolver = rawRegistrationRecord.resolver;
  const registrantAddress = rawRegistrationRecord.owner;
  const secret = rawRegistrationRecord.secret;
  const duration = rawRegistrationRecord.duration;
  const compiledPacket =
    registrationService.registrationDomainsToCommitmentPacket(
      registrantAddress,
      duration,
      secret,
      resolver,
      registrationsList
    );

  let finalTotal = ethers.utils.parseUnits(totalEthPrice.toFixed(6), 18);

  return registrationService
    .completeRegistration(
      compiledPacket,
      payer,
      // priceRanges,
      finalTotal.toHexString(),
      provider,
      signer
    )
    .then((registrationPacketAndGasLimit) => {
      let [registrationPacket] = registrationPacketAndGasLimit;
      return registrationPacket;
    })
    .catch((e) => {
      console.log(e);
      throw e;
    });
}

export function produceNonce(type = "TOKEN") {
  const nonceLength = type === "TOKEN" ? 32 : 15;
  const charLibrary =
    "0123456789" +
    (type === "TOKEN"
      ? "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz"
      : "");
  let nonceString = "";
  for (let i = 0; i < nonceLength; i++) {
    const rnum = Math.floor(Math.random() * charLibrary.length);
    nonceString += charLibrary.substring(rnum, rnum + 1);
  }
  return nonceString;
}
