import  React,{ createContext, useState, useEffect } from "react";
import { useAccount, useNetwork, useSigner } from "wagmi";
import { getPopularClubs, getMyEns, checkAuth, authentication, getNonce, logOut } from "../api/requests";
import { useCallback } from "react";
import { SiweMessage } from "siwe";
import { toast } from "react-hot-toast";

const domain = window.location.host;
const origin = window.location.origin;

export const APIContext = createContext({
    clubs: [],
});

export function APIProvider({ children }) {
    const [clubs, setClubs] = useState([]);
    const { address, isConnected } = useAccount();
    const { data: signer } = useSigner();
    const { chain } = useNetwork()
    const [myEns, setMyEns] = useState({})
    const [isSignedIn, setIsSignedIn] = useState(false)
    useEffect(() => {
        if (!clubs || (clubs && clubs.length === 0)) {
            fetchPopularClubs();
        }
    }, []);
    const fetchPopularClubs = async () => {
        try {
            const dt = await getPopularClubs();
            let mergedData = dt?.data?.data.map((c) => {
                c.minted = c?.totalNames > 0 ? (((c?.totalNames - c?.availableNames) / c?.totalNames) * 100)?.toFixed(0) : 0;
                return c;
            });
            setClubs(mergedData);
        } catch (error) {
            setClubs([]);
        }
    };

    const fetchMyEns = useCallback(async () => {
        try {
            const myEnsData = await getMyEns(address, { limitPerPage: 5, club: 'all' });
            setMyEns(myEnsData?.data)
        } catch (error) {
            setMyEns(null)
        }
    }, [address])

    const getTokens = () => {
        const _tokens = localStorage.getItem("TOKENS");
        return _tokens ? JSON.parse(_tokens) : {};
    }
    const removeUserFromLocalStorage = useCallback(() => {
        localStorage.removeItem("USER_ADDRESS");
        localStorage.removeItem("USER_TOKEN");
        const tokens = getTokens();
        if (address)
            delete tokens[address?.toLowerCase()];
        localStorage.setItem("TOKENS", JSON.stringify(tokens));
    }, [address])

    const checkAccount = useCallback(() => {
        const tokens = getTokens();
        const _address = address.toLowerCase();
        if (tokens[_address]) {
            localStorage.setItem("USER_ADDRESS", _address);
            localStorage.setItem("USER_TOKEN", tokens[_address]);
            setIsSignedIn(true)
        } else {
            setIsSignedIn(false)
            localStorage.removeItem("USER_ADDRESS");
            localStorage.removeItem("USER_TOKEN");
        }
    }, [address])

    const signOutWithEthereum = useCallback(async (type) => {
        const { data } = await logOut();
        if (data?.success) {
            if (type === "signout") {
                toast.success("Sucessfully Sign Out")
            }
            setIsSignedIn(false)
            removeUserFromLocalStorage()
        }
    }, [removeUserFromLocalStorage, isSignedIn])

    const checkAuthToken = useCallback(async () => {
        try {
            const { data } = await checkAuth();
            if (!data.success) {
                signOutWithEthereum()
            }
        } catch (error) {
            if (error.response.status === 401) {
                signOutWithEthereum()
            }
        }
    }, [signOutWithEthereum])

    useEffect(() => {
        if (address && isConnected) {
            checkAccount()
            checkAuthToken()
            fetchMyEns()
        } else {
            setIsSignedIn(false)
            setMyEns(null)
        }
    }, [address, checkAccount, checkAuthToken, fetchMyEns, isConnected]);

    const createSiweMessage = async (address, statement) => {
        const nonceRes = await getNonce();
        const message = new SiweMessage({
            domain,
            address,
            statement,
            uri: origin,
            version: '1',
            chainId: chain?.id,
            nonce: nonceRes?.data
        });
        return { message: message.prepareMessage(), nonce: nonceRes?.data }
    }
    const signInWithEthereum = async () => {
        try {
            const siweMessage = await createSiweMessage(await signer.getAddress(), 'Sign in with Ethereum to the app.');
            const { nonce, message } = siweMessage;
            const signature = await signer.signMessage(message);
            if (signature) {
                const { data } = await authentication(
                    {
                        signature,
                        message,
                        nonce,
                    },
                );
                setIsSignedIn(true)
                toast.success("Upgrade Wallet Connect to Sign In With Ethereum")
                const _address = address.toLowerCase();
                localStorage.setItem("USER_ADDRESS", _address);
                localStorage.setItem("USER_TOKEN", data.jwt);
                const tokens = getTokens();
                tokens[_address] = data.jwt;
                localStorage.setItem("TOKENS", JSON.stringify(tokens),
                );
            } else {
                return Promise.reject('Signing failed');
            }
        } catch (error) {
            return Promise.reject('Signing failed');
        }
    };

    const contextValue = {
        clubs: clubs,
        myEns: myEns,
        isSignedIn: isSignedIn,
        signInWithEthereum,
        signOutWithEthereum,
    };
    return (
        <APIContext.Provider value={contextValue}>{children}</APIContext.Provider>
    );
}
export default APIProvider;