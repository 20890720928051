import { Box, Tooltip } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { getIconUrl } from '../../utils/ens-service'
import { useState } from 'react'

export const MarketPriceCircle = ({ market, price }) => {
    const [img, setImg] = useState()
    useEffect(() => {
        const url = getIconUrl(market)
        setImg(url)
    }, [market])
    return (
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}>
                <Box sx={{
                    width: "30px",
                    height: "30px",
                    lineHeight: "30px",
                    borderRadius: "50%",
                    color: "#fff",
                    background: img?.includes("NameApes") ? "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)" : "transparent",
                    marginRight: "3px",
                    marginLeft: "3px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <img src={img} style={{ width: img?.includes("NameApes") ? "20px" : "30px", height: img?.includes("NameApes") ? "20px" : "30px", borderRadius: "50%" }} alt="market" />
                </Box>
                {price &&
                    <Tooltip title={parseFloat(price).toFixed(10)} placement="top" tri arrow>
                        <Box>
                            {parseFloat(price).toFixed(3)}Ξ
                        </Box>
                    </Tooltip>
                }
            </Box>
        </>
    )
}