import React from "react";
import ReactDOM from "react-dom/client";
import ErrorBoundary from "./utils/ErrorBoundary";

import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ErrorBoundary fallback={
        <div style={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
            loading...
        </div>
    }>
        <App />
    </ErrorBoundary>
);
