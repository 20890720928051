import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';

export const AntTabs = styled(Tabs)({
    root: {
        width: 28,
        overflow: 'hidden',
        transition: 'width 0.5s',
        '&.Mui-disabled': {
            width: 0,
        },
    },
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#d190fc',
    },

});

export const AntTab = styled((props) => (
    <Box sx={{
                    display: 'flex',
                }}>
                   <Tab disableRipple {...props} />
                    <Box sx={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        // fontWeight: '600',
                        fontSize: {
                            xs: '8px',
                            md: '12px'
                        },
                        lineHeight: {
                            xs: '10px',
                            md: '15px'
                        },
                        color: '#FFFFFF',
                        background: props.currentClubType === props.value ? '#ACA2FD' : 'grey',
                        borderRadius: '4px',
                        padding: {
                            xs: '4px 4px',
                            md: '4px 8px',
                        },
                        alignSelf: 'flex-start'

                    }}>{props.totalItems || 0}

                    </Box>
                </Box>
    
))(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
        'Inter',
        'sans-serif',
    ].join(','),
    '&.Mui-selected': {
        color: 'rgba(0, 0, 0, 0.85)',
        // fontWeight: theme.typography.fontWeightMedium,
        fontWeight: "normal",
    },
}));

