export const ensRegistrarAddr = "0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5";
export const ensNewRegistrarMainnetAddr =
  "0x253553366Da8546fC250F225fe3d25d0C782303b";
export const ensNewRegistrarGoerliAddr =
  "0xCc5e7dB10E65EED1BBD105359e7268aa660f6734";
export const ensBaseRegistrarAddr =
  "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85";

export const ensRegistrarAbi = require("./ens-registrar-abi.json");
export const ensBaseRegistrarAbi = require("./ens-base-registrar-abi.json");

export const ensFairyVault = "0x481f50a5BdcCC0bc4322C4dca04301433dED50f0";

export const ensRegistrarConfig = {
  addressOrName: ensRegistrarAddr,
  contractInterface: ensRegistrarAbi,
};

export const ensResolver = "0x4976fb03C32e5B8cfe2b6cCB31c09Ba78EBaBa41";
export const ensResolverRinkeby = "0xf6305c19e814d2a75429Fd637d01F7ee0E77d615";
