import { Avatar, Box, Button, Link, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react'
import { GoVerified } from 'react-icons/go';
import Iconify from '../iconify/Iconify';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAccount, useProvider } from 'wagmi';
import moment from 'moment';
import { useContext } from 'react';
import { APIContext } from '../../utils/APIContext';
import { FaDiscord, FaGithub, FaTwitter } from 'react-icons/fa';
import { BsCheck } from 'react-icons/bs';
import MemberInviteModal from './MemberInviteModal';
import { UilListOlAlt } from '@iconscout/react-unicons';
import { UilShoppingCart } from '@iconscout/react-unicons';
import { UilFolderHeart } from '@iconscout/react-unicons';

import { toast } from 'react-hot-toast';
import { ETHEREUM_NETWORK } from '../../config';
import useFetch from '../../hooks/fetch';

const iconstyle = {
    border: '1px solid #bdbdbd',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    borderRadius: '50%',
    color: "rgba(0, 0, 0, 0.87)",
    height: '28px',
    width: '28px',
    "&:hover": {
        color: "#007fff",
        border: '1px solid #007fff',
    },
}

export const EnsProfileInfo = ({ ensName, paramsAddress, membershipDetails, myEnsCount, myEnsListingCount, allClubs }) => {
    const provider = useProvider();
    const { address } = useAccount();
    const { isSignedIn, signInWithEthereum } = useContext(APIContext);
    const [showInviteModal, setShowInviteModal] = useState(false)
    const [isCopied, setIsCopied] = useState(false)
    const [topClub, setTopClub] = useState()
    const [profileData, setProfileData] = useState({
        name: "",
        avtar: "",
        github: "",
        twitter: "",
        discord: ""
    })
    useEffect(() => {
        const ensname = ensName?.replace('-eth', '.eth') || paramsAddress
        const getProfileInfo = async () => {
            if (ensName) {
                const resolver = await provider.getResolver(ensname);
                if (resolver) {
                    const _data = { ...profileData }
                    const twitter = await resolver.getText("com.twitter");
                    const github = await resolver.getText("com.github");
                    const discord = await resolver.getText("com.discord");
                    _data.twitter = twitter?.replace("https://twitter.com/", "")
                    _data.github = github
                    _data.discord = discord
                    setProfileData(_data)
                }
            }
        }
        getProfileInfo()
    }, [ensName, paramsAddress, provider])
    const onInviteMember = () => {
        if (isSignedIn) {
            setShowInviteModal(true)
        }
        else {
            signInWithEthereum()
        }
    }
    const onCopieText = async (text, msg) => {

        try {
            if (!navigator.clipboard) {
                const textarea = document.createElement('textarea');
                textarea.value = text;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
                return;
            }

            //await navigator.clipboard.writeText(text);
            //readTextFromClipboard()
            toast.success(msg)
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    }
    const IconLink = ({ href, icon }) => {
        return <Link
            href={href}
            target="_blank"
            sx={iconstyle}
        >
            {icon}
        </Link>
    }
    useEffect(() => {
        const maxdata = Math.max(...allClubs.filter(o => o.name !== "Members Club").map(o => o.totalNames))
        const clubname = allClubs.filter(club => club?.totalNames === maxdata)
        setTopClub(clubname[0])
    }, [allClubs])

    const avtarUrl = useFetch(`https://metadata.ens.domains/${ETHEREUM_NETWORK}/avatar/${ensName}`);

    const formatHex = (hexString) => {
        if (hexString.length <= 13) {
            return hexString;
        }
        const start = hexString.slice(0, 6);
        const end = hexString.slice(-5);
        return `${start}...${end}`;
    }
    const readTextFromClipboard = () => {
        navigator.clipboard.readText()
            .then(text => {
                setIsCopied(true)
                const timer = setTimeout(() => {
                    setIsCopied(false)
                }, 5000);
                return () => clearTimeout(timer);
            })
            .catch(err => {
                console.error('Failed to read clipboard contents: ', err);
            });
    };


    return (
        <>
            <Stack
                direction="row"
                alignItems="flex-start"
                spacing={{ xs: 1, md: 1 }}
                sx={{ alignSelf: "flex-start" }}
            >
                {(!avtarUrl?.isLoading && !avtarUrl?.data && ensName) &&
                    <Box
                        sx={{
                            backgroundImage: 'linear-gradient(91.87deg, #88b1fd -5.48%, #d190fc 100%)',
                            borderRadius: '5px',

                        }}
                    >
                        <Avatar
                            variant="rounded"
                            src={`https://metadata.ens.domains/${ETHEREUM_NETWORK}/avatar/${ensName}`}
                            sx={{ width: 100, height: 100, margin: '2px' }}
                        />
                    </Box>
                }

                <Stack spacing={1} direction="column">
                    <Stack spacing={1} direction="row" alignItems="center">
                        {ensName || paramsAddress ?
                            <Typography variant="h5" style={{ fontSize: "30px" }}>
                                {ensName?.replace('-eth', '.eth') || formatHex(paramsAddress)}
                            </Typography>
                            :
                            <Skeleton
                                variant="rectangular"
                                sx={{
                                    height: 30,
                                    width: 300
                                }}
                            />
                        }
                    </Stack>

                    <Stack direction={'row'} spacing={1} >
                        {
                            paramsAddress &&
                            <Tooltip
                                arrow
                                title={<Typography variant="subtitle2">{paramsAddress}</Typography>}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            textAlign: 'center'
                                        },
                                    },
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        padding: "5px",
                                        width: "28px",
                                        height: "28px",
                                        borderRadius: "50%",
                                        border: "1px solid #bdbdbd",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        "&:hover": {
                                            background: '#e8e8e8',
                                        },

                                    }}
                                    onClick={() => onCopieText(paramsAddress, "Address Copied!")}
                                >
                                    {
                                        !isCopied ?
                                            <Iconify
                                                icon={"material-symbols:file-copy-outline"}
                                                sx={{ width: 14, height: 14, cursor: 'pointer', color: '#7d7d7d' }}
                                            />
                                            :
                                            <BsCheck color='#7d7d7d' size={28} />
                                    }
                                </Typography>
                            </Tooltip>
                        }
                        <IconLink href={`https://app.ens.domains/name/${ensName || paramsAddress}/details`} icon={<Avatar alt="ens" src="/ens.svg" sx={{ width: 18, height: 18 }} />} />
                        {
                            ensName &&
                            <>
                                {(profileData?.twitter || profileData?.github || profileData?.discord) &&
                                    <>
                                        {profileData?.twitter && <IconLink href={`https://twitter.com/${profileData?.twitter}`} icon={<FaTwitter />} />}
                                        {profileData?.github && <IconLink href={`https://github.com/${profileData?.github ? profileData?.github : ""}`} icon={<FaGithub />} />}
                                        {profileData?.discord &&
                                            <Box sx={iconstyle} onClick={() => onCopieText(profileData?.discord, "Discord Code Copied!")}>
                                                <FaDiscord />
                                            </Box>
                                        }
                                    </>}
                            </>
                        }


                        {membershipDetails?.isMember &&
                            <Tooltip
                                arrow
                                enterTouchDelay={0}
                                title=
                                {
                                    <>
                                        <Typography>{membershipDetails?.memberSince ? `NameApes Member Since ${moment(membershipDetails?.memberSince).format("DD/MM/YYYY")}` : `NameApes Member`}</Typography>
                                        {
                                            (address?.toLowerCase() === paramsAddress?.toLowerCase()) &&
                                            <>
                                                <Button
                                                    sx={{ textDecoration: "underline", cursor: "pointer", color: "#FFF", width: "100%", height: { xs: "55px", sm: "37px" } }}
                                                    onClick={() => onInviteMember()}
                                                >
                                                    Give Membership
                                                </Button>
                                                {!isSignedIn && <Typography>(Sign In Ethereum Needed)</Typography>}
                                            </>
                                        }
                                    </>
                                }
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            textAlign: 'center'
                                        },
                                    },
                                }}
                            >
                                <Box>
                                    <GoVerified color="#007FFF" size={28} />
                                </Box>
                            </Tooltip>
                        }
                    </Stack>
                </Stack>
            </Stack >
            <Stack direction="row" justifyContent="space-between" flexWrap="nowrap" p={2}
                sx={{
                    alignItems: "center",
                    border: "1px solid rgba(224,224,224,1)",
                    background: "#ffffff",
                    margin: "20px 0 30px",
                    width: "100%",
                    borderRadius: "5px",
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontSize: {
                        xs: '20px',
                        md: '24px'
                    },
                    lineHeight: {
                        xs: '24px',
                        md: '28px'
                    },
                    color: '#453F3F'
                }}>
                <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                    <UilListOlAlt
                        color="#000"
                        strokeWidth="1"
                    />
                    <Stack direction="column" ml={1} sx={{ fontSize: { xs: '11px', sm: '14px' }, lineHeight: { xs: '16px', sm: '20px' } }}>
                        <Box variant="span" sx={{ fontSize: '14px', color: '#000' }}>{myEnsCount}</Box>
                        <Box variant="span" sx={{ color: '#7d7d7d' }}>ENS Owned</Box>
                    </Stack>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                    <UilFolderHeart color="#000" />
                    <Stack direction="column" ml={1} sx={{ fontSize: { xs: '11px', sm: '14px' }, lineHeight: { xs: '16px', sm: '20px' } }}>
                        <Box variant="span" sx={{ fontSize: '14px', color: '#000' }}>{`${topClub?.name ? topClub?.name?.length > 15 ? `${topClub?.name?.slice(0, 15)}...` : topClub?.name : "-"}`}</Box>
                        <Box variant="span" sx={{ color: '#7d7d7d' }}>Fav Club</Box>
                    </Stack>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                    <UilShoppingCart color="#000" />
                    <Stack direction="column" ml={1} sx={{ fontSize: { xs: '11px', sm: '14px' }, lineHeight: { xs: '16px', sm: '20px' } }}>
                        <Box variant="span" sx={{ fontSize: '14px', color: '#000' }}>{myEnsListingCount ? myEnsListingCount : 0}</Box>
                        <Box variant="span" sx={{ color: '#7d7d7d' }}>Listed ENS</Box>
                    </Stack>
                </Box>
            </Stack>
            {
                showInviteModal &&
                <>
                    <MemberInviteModal
                        open={showInviteModal}
                        handleClose={() => setShowInviteModal(false)}
                        paramsAddress={paramsAddress}
                    />
                </>
            }
        </>
    )
}