import axios from "axios";
import { getReservoirApiUrl } from "../utils";
import { ENS_COLLECTION_ID, ENS_WRAPPED_COLLECTION_ID } from "../constants";
import { RESERVOIR_API_KEY } from "../config";
const RESERVOIR_API_URL = getReservoirApiUrl();
const collectionId = ENS_COLLECTION_ID;

const config = {
  headers: {accept: '*/*', 'x-api-key': RESERVOIR_API_KEY}
}

const getCollectionAndToken = (token, wrappedToken) => {
  return wrappedToken
    ? `${ENS_WRAPPED_COLLECTION_ID}:${wrappedToken}`
    : `${ENS_COLLECTION_ID}:${token}`
}

export const getTokenInfo = (token, wrappedToken) => {
  const collectionAndToken = getCollectionAndToken(token, wrappedToken)
  return axios.get(
    `${RESERVOIR_API_URL}/tokens/v6?tokens=${collectionAndToken}&includeTopBid=true&includeAttributes=true`, config
  );
};

export const getTokensInfo = (tokens) => {
  let allTokens = "";
  tokens.map((token) => {
    allTokens += `tokens=${collectionId}:${token}&`;
  });
  return axios.get(
    `${RESERVOIR_API_URL}/tokens/v6?${allTokens}includeTopBid=true&includeAttributes=true&limit=100`, config
  );
};

export const getTokensInfoV2 = (tokens) => {
  let allTokens = "";
  tokens.forEach(([token, wrappedToken]) => {
    const collectionAndToken = getCollectionAndToken(token, wrappedToken)
    allTokens += `tokens=${collectionAndToken}&`;
  });
  return axios.get(
    `${RESERVOIR_API_URL}/tokens/v6?${allTokens}includeTopBid=true&includeAttributes=true&limit=100`, config
  );
};

// https://api.reservoir.tools/tokens/token/activity/v2?limit=20&sortBy=eventTimestamp
export const getTokenActivity = (token, wrappedToken) => {
  const collectionAndToken = getCollectionAndToken(token, wrappedToken)
  return axios.get(
    `${RESERVOIR_API_URL}/tokens/${collectionAndToken}/activity/v2?limit=20`, config
  );
};

export const getTokenActivityV5 = (token, wrappedToken,limit) => {
  const collectionAndToken = getCollectionAndToken(token, wrappedToken)

  return axios.get(
    `${RESERVOIR_API_URL}/tokens/${collectionAndToken}/activity/v5?types=sale&types=transfer&types=mint`, config
  );
};


//${RESERVOIR_API_URL}orders/asks/v3?token=${collectionId}:${token}&includePrivate=false&includeMetadata=false&includeRawData=false&sortBy=createdAt&limit=50
//${RESERVOIR_API_URL}orders/asks/v3?token=${collectionId}:${token}&includePrivate=false&includeMetadata=false&includeRawData=false&sortBy=createdAt&limit=50
export const getTokenListing = (token, contract) => {
  return axios.get(
    `${RESERVOIR_API_URL}/orders/asks/v4?token=${contract}:${token}&includePrivate=false&includeMetadata=true&includeRawData=true&sortBy=createdAt&limit=50`, config
  );
};

// ${RESERVOIR_API_URL}orders/bids/v4?token=${collectionId}:${token}&includeMetadata=false&includeRawData=false&sortBy=createdAt&limit=50
export const getTokenOffers = (token, wrappedToken) => {
  const collectionAndToken = getCollectionAndToken(token, wrappedToken)
  return axios.get(
    `${RESERVOIR_API_URL}/orders/bids/v4?token=${collectionAndToken}&includeMetadata=false&includeRawData=false&sortBy=createdAt&limit=50`, config
  );
};

export const getTokenSales = (token, wrappedToken) => {
  const collectionAndToken = getCollectionAndToken(token, wrappedToken)
  return axios.get(
    `${RESERVOIR_API_URL}/sales/v4?token=${collectionAndToken}`, config
  );
};

export const getUserTokens = (address) => {
  return axios.get(`${RESERVOIR_API_URL}/users/${address}/tokens/v5`, config);
};

export const refreshTokenData = (token, wrappedToken) => {
  const collectionAndToken = getCollectionAndToken(token, wrappedToken)
  const options = {
    method: "POST",
    url: `${RESERVOIR_API_URL}/tokens/refresh/v1`,
    headers: {
      accept: "*/*",
      "content-type": "application/json",
      "x-api-key": RESERVOIR_API_KEY,
    },
    data: {
      overrideCoolDown: false,
      token: `${collectionAndToken}`,
    },
  };

  return axios
    .request(options)
    .then(function (response) {
      // console.log(response.data);
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
};
