import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Link, Stack, Typography } from '@mui/material';
import { getClient } from '@reservoir0x/reservoir-sdk';
import moment from 'moment';
import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import useStateRef from 'react-usestateref';
import styled from 'styled-components';
import { useSigner } from 'wagmi';
import { APIContext } from '../../utils/APIContext';
import { ENS_COLLECTION_ID } from '../../constants';
import { normalizeEth } from '../../utils/eth';
import { CLEARANCE_FEE_RECIPIENT, CLEARANCE_SALT } from '../../config';
import { toast } from 'react-hot-toast';

const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #635e5e;
`;

const Th = styled.th`
  font-size: 14px;
  line-height: 10px;
  padding: 2px;
  padding-left: 15px;
  color: #484343;
`;

const Td = styled.td`
  font-size: 14px;
  line-height: 10px;
  padding: 2px;
  padding-left: 15px;
  color: #484343;
  height: 32px;
`;

export const StartClearanceDialog = ({ open, tokenInfos, cart, clearanceCalculation, handleClose }) => {
    const [transactionStatus, setTransactionStatus, transactionStatusRef] = useStateRef("none");

    const { data: signer } = useSigner();
    const { myEns } = useContext(APIContext);

    const startListing = async () => {
        setTransactionStatus('processing');

        const orderBook = 'opensea'
        const isMember = !!myEns?.isMember;
        const fee = isMember ? 0.05 : 0.1;
        let listingParams = []
        let isAllSuccess = true;

        tokenInfos?.forEach((tokenInfo) => {
            clearanceCalculation.forEach((c) => {
                listingParams.push({
                    token: `${ENS_COLLECTION_ID}:${tokenInfo?.token}`,
                    weiPrice: normalizeEth(parseFloat(c.price).toFixed(5)),
                    orderbook: orderBook,
                    orderKind: "seaport-v1.5",
                    fees: orderBook === 'reservoir' ? [`${CLEARANCE_FEE_RECIPIENT}:${fee * 10000}`] : undefined,
                    listingTime: moment(c.startAt).format('X'),
                    expirationTime: moment(c.endAt).format('X'),
                    salt: CLEARANCE_SALT
                })
            })
        })
        try {
            await getClient()?.actions.listToken({
                listings: listingParams,
                signer,
                onProgress: (steps) => {
                    let status = steps[0].items[0]?.status;
                    if (status === "incomplete") {
                        setTransactionStatus('approving failed');
                    }
                    if (steps[1].items?.every((item) => item.status === 'complete')) {
                        setTransactionStatus("done")

                    }
                }
            });
        } catch (err) {
            isAllSuccess = false;
            setTransactionStatus('none');
            handleClose()

            toast.error(err.message, {
                style: {
                    maxWidth: "100%",
                },
            });
        }
        if (isAllSuccess) {
            handleClose()
            cart.deleteAllFromSellBag();
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }
    }
    useEffect(() => {
        if (tokenInfos.length > 0) {
            startListing()
        }
    }, [open])
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="purchase-dialog"
                PaperProps={{ sx: { height: 'unset !important', maxHeight: "75%" } }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid rgba(0,0,0,0.1)",
                        background: "linear-gradient(91.87deg, #88B1FD -5.48%, #D190FC 100%)",
                        padding: "10px 20px",
                        alignItems: "center",
                        height: "68px",
                    }}
                >
                    <DialogTitle id="alert-dialog-title"
                        sx={{
                            display: "flex",
                            fontSize: "20px",
                            color: "#FFFFFF",
                            fontWeight: "500",
                            width: "95%",
                            wordBreak: "break-all"
                        }}
                    >
                        Clearance ({tokenInfos.length} names)
                    </DialogTitle>
                    <Button
                        onClick={handleClose}
                        sx={{
                            fontSize: "20px",
                            color: "#FFFFFF",
                            fontWeight: "500",
                            padding: "0px",
                            height: "27px",
                            minWidth: "unset",
                        }}
                    >
                        &times;
                    </Button>
                </Box>
                <DialogContent
                    sx={{ padding: "20px 15px", position: "relative", height: "75%" }}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        mb={0}
                        sx={{
                            borderBottom: "1px solid lightgray",
                            padding: "0px 0px 15px 0px",
                            marginBottom: "15px",
                            overflow: "hidden",
                            overflowX: "scroll",
                            '&::-webkit-scrollbar': {
                                height: '0.4em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0,0,0,.1)',
                            }
                        }}
                    >
                        {tokenInfos?.map((token, index) => (
                            <Chip
                                label={token?.name}
                            />
                        ))}
                    </Stack>
                    <Typography variant="body2" style={{ fontSize: '12px' }} gutterBottom>
                        Price drop automatically from 0.2 ETH to 0.01 ETH over 4 days to help match to buyers.
                        <Link
                            href="https://blog.ensdom.com/blog/clearance-club"
                            target="_blank"
                            variant="body2"
                            style={{
                                fontSize: '12px',
                                textDecoration: "none",
                                marginLeft: "5px",
                            }}>
                            Read more…
                        </Link>
                    </Typography>
                    <Box className="table-responsive">
                        <table
                            style={{
                                width: "100%",
                                border: "1px solid rgba(145, 145, 145, 0.37)",
                                borderRadius: "8px",
                                borderCollapse: "separate",
                                marginBottom: "10px",
                                whiteSpace: "nowrap"
                            }}
                        >

                            <thead style={{ background: "rgba(242, 242, 242, 0.33)" }}>
                                <tr>
                                    <Th>List  (Ξ)</Th>
                                    <Th>Starts </Th>
                                    <Th>Time</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {clearanceCalculation?.map((c) =>
                                    <tr>
                                        <Td><Text style={{ marginBottom: "0", fontSize: "14px" }}>{c.price}</Text></Td>
                                        <Td><Text style={{ marginBottom: "0", fontSize: "14px" }}>{moment(c.startAt).format('DD-MMM HH:mm')}</Text></Td>
                                        <Td><Text style={{ marginBottom: "0", fontSize: "14px" }}>{c.duration?.replace('minutes', 'mins')?.replace('hours', 'hrs')}</Text></Td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Box>
                    {transactionStatus === "processing" && (
                        <Typography variant="subtitle1" mt={1}>Approving and signing transactions...</Typography>
                    )}
                    {transactionStatus === "approving failed" && (
                        <Typography variant="subtitle1" mt={1}>Please approve contract</Typography>
                    )}
                    {transactionStatus === "done" && (
                        <Typography variant="subtitle1" mt={1}>Clearance started successfully...Reloading page in 3 seconds…Please wait a few minutes for {tokenInfos?.map((token, index) => (<>{(index + 1) === tokenInfos?.length ? <>{token?.name}</> : <>{token?.name},</>}</>))} to show up in Clearance Club.</Typography>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};