import moment from 'moment';
import { CLEARANCE_SALT_IN_HEX, FEATURE_FLAG_SALE_CLEARANCE_USE_TESTING_DATA } from '../config';

const clearancePricePeriods = [
    {
        price: 0.2,
        duration: '24 hours',
    },
    {
        price: 0.1,
        duration: '12 hours',
    },
    {
        price: 0.07,
        duration: '12 hours',
    },
    {
        price: 0.035,
        duration: '8 hours',
    },
    {
        price: 0.0245,
        duration: '8 hours',
    },
    {
        price: 0.01715,
        duration: '8 hours',
    },
    {
        price: 0.01,
        duration: '24 hours',
    }
]

const clearancePricePeriodsTest = [
    {
        price: 0.2,
        duration: '20 minutes',
    },
    {
        price: 0.1,
        duration: '20 minutes',
    },
    {
        price: 0.07,
        duration: '20 minutes',
    },
    {
        price: 0.035,
        duration: '20 minutes',
    },
    {
        price: 0.0245,
        duration: '20 minutes',
    },
    {
        price: 0.01715,
        duration: '20 minutes',
    }
    ,
    {
        price: 0.01,
        duration: '20 minutes',
    }
]


export const generateNewClearanceCalculation = () => {
    const calculations = [];
    let lastPeriod = moment().toDate();

    console.log("Testing ", FEATURE_FLAG_SALE_CLEARANCE_USE_TESTING_DATA);
    const data = FEATURE_FLAG_SALE_CLEARANCE_USE_TESTING_DATA
        ? clearancePricePeriodsTest
        : clearancePricePeriods

    data.forEach((c, i) => {
        let startAt;
        if (i > 0) {
            startAt = moment(lastPeriod).add(-5, 'minutes').toDate();
        } else {
            startAt = moment(lastPeriod).toDate();
        }
        const [durationValue, durationUnit] = c.duration.split(' ');
        const endAt = moment(startAt).add(parseInt(durationValue), durationUnit).toDate();
        lastPeriod = endAt;

        calculations.push({
            ...c,
            startAt,
            endAt,
        })
    });

    return calculations;
}

export const isClearanceListing = (listing) => {
    const data = FEATURE_FLAG_SALE_CLEARANCE_USE_TESTING_DATA
        ? clearancePricePeriodsTest
        : clearancePricePeriods;

    let isClearance = data?.some(slot => {
        const seconds = durationToSeconds(slot.duration);
        return slot.price === listing?.price?.amount?.decimal && seconds === listing.validUntil - listing.validFrom
    })

    return listing?.rawData?.salt === CLEARANCE_SALT_IN_HEX || isClearance;
}

export const getNextPriceDropAmount = (currentPrice) => {
    const priceDropAmount = FEATURE_FLAG_SALE_CLEARANCE_USE_TESTING_DATA
        ? clearancePricePeriodsTest
        : clearancePricePeriods;

    const prices = priceDropAmount.map(obj => obj?.price);
    const closestPrices = prices.filter(price => price < currentPrice);
    const closestPrice = Math.max(...closestPrices);
    const index = prices.indexOf(closestPrice);
    return priceDropAmount[index]?.price;
}

const durationToSeconds = (durationString) => {
    const [durationValue, durationUnit] = durationString.trim().toLowerCase().split(" ");
    const unitFactors = {
        seconds: 1,
        minutes: 60,
        hours: 3600,
        days: 86400,
        weeks: 604800,
        months: 2592000,
        years: 31536000,
    };
    return unitFactors[durationUnit] ? durationValue * unitFactors[durationUnit] : 0;
};