import { ethers, Contract } from "ethers";
// import ethPrice from "eth-price";
import { ens_beautify, ens_normalize } from "@adraffy/ens-normalize";
import request, { gql } from "graphql-request";
import { ETHEREUM_NETWORK } from "../config";
import moment from "moment";
import { getTokensData } from "../api/requests";
import { ensContractAbi } from "../configurations/ens-contract-abi";
import {
  ensNewRegistrarGoerliAddr,
  ensNewRegistrarMainnetAddr,
  ensRegistrarAddr,
} from "../lib/constants";
import { convertTokenToHex } from "../utils/misc-utils.service";
import lg from "../components/assets/NameApes-icon.png";
import { newEnsGoerliContractAbi } from "../configurations/newEnsGoerliContractAbi";
import { newEnsMainnetContractAbi } from "../configurations/newEnsMainnetContractAbi";

const invalidChars = [
  "!",
  "@",
  "#",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "_",
  "+",
  "=",
  ".",
];

const url =
  ETHEREUM_NETWORK === "goerli"
    ? "https://api.thegraph.com/subgraphs/name/ensdomains/ensgoerli"
    : "https://api.thegraph.com/subgraphs/name/ensdomains/ens";

export function isDomainNameNotValid(
  name,
  prefixedOrSuffixed = false,
  prefixedAndSuffixed = false,
  minLengthOverride = 3,
  skipNormalisation = false
) {
  if (skipNormalisation === true) {
    return true;
  }
  let minLength = minLengthOverride;
  if (prefixedOrSuffixed === true) {
    minLength = 2;
  }
  if (prefixedAndSuffixed === true) {
    minLength = 1;
  }
  try {
    if (name.length < 3 || name === undefined) {
      return false;
    }
    if (name === "" || getNameLength(name) < minLength) {
      return false;
    }
    const invalidCharsForcedFilter = invalidChars.join("");
    const invalidCharDetect = [...name].filter((c) => {
      if (invalidCharsForcedFilter.includes(c) === true) {
        return true;
      }
      return false;
    });
    if (invalidCharDetect.length > 0) {
      return false;
      // throw "invalid charcher detected";
    }
    // const normed = ens_normalize(name);
    // console.log("normed", normed);
    return true;
  } catch (e) {
    throw e;
  }
}
export function getNameLength(name) {
  try {
    const count = [...ens_normalize(name)].length;
    return count;
  } catch (e) {
    return 0;
  }
}
export async function calculateDomainsPrice(
  ethToUsdRate,
  // name,
  durationInYears = 1,
  unit = "year",
  provider
) {
  const duration =
    unit === "month"
      ? (durationInYears / 12).toFixed(6)
      : durationInYears.toFixed(6);
  const ethUsdRate = parseInt(ethToUsdRate, 10);
  const namesSamples = ["mos", "most", "hello"];
  const threeCharPrice = await calculateRentPrice(
    namesSamples[0],
    duration,
    provider
  );
  const fourCharPrice = await calculateRentPrice(
    namesSamples[1],
    duration,
    provider
  );
  const fiveCharPrice = await calculateRentPrice(
    namesSamples[2],
    duration,
    provider
  );
  return {
    threeCharEthCost: parseFloat(ethers.utils.formatEther(threeCharPrice.base)),
    fourCharEthCost: parseFloat(ethers.utils.formatEther(fourCharPrice.base)),
    fiveCharEthCost: parseFloat(ethers.utils.formatEther(fiveCharPrice.base)),
    threeCharUsdCost: parseFloat(
      (ethers.utils.formatEther(threeCharPrice.base) * ethUsdRate).toFixed(6)
    ),
    fourCharUsdCost: parseFloat(
      (ethers.utils.formatEther(fourCharPrice.base) * ethUsdRate).toFixed(6)
    ),
    fiveCharUsdCost: parseFloat(
      (ethers.utils.formatEther(fiveCharPrice.base) * ethUsdRate).toFixed(6)
    ),
  };
}

const YEARS_IN_SECONDS = 31556952;

async function calculateRentPrice(name, duration, provider) {
  const c = new Contract(EnsContractAddress(), EnsContractABI(), provider);
  const price = await c.rentPrice(name, parseInt(duration * YEARS_IN_SECONDS));
  // console.log(price);
  return price;
}
export function EnsContractABI() {
  if (ETHEREUM_NETWORK === "goerli") {
    return newEnsGoerliContractAbi;
  }
  return newEnsMainnetContractAbi;
  // return ENSBulkRegistrationContractTestnetABI;
}

export function EnsContractAddress() {
  if (ETHEREUM_NETWORK === "goerli") {
    return ensNewRegistrarGoerliAddr;
  }
  return ensNewRegistrarMainnetAddr;
  // return bulkRegistrationTestnet;
}
export async function calculatePremiumPrice(name, duration, provider) {
  try {
    let price;
    const c = new Contract(EnsContractAddress(), EnsContractABI(), provider);
    price = await c.rentPrice(name, parseInt(duration * YEARS_IN_SECONDS));
    return {
      name,
      ...price,
      premiumPrice: parseFloat(ethers.utils.formatEther(price.premium)),
      rentPrice: parseFloat(ethers.utils.formatEther(price.base)),
    };
  } catch (e) {
    console.log(e);
  }
}

let skipNormalisation = false;

export function calculateGracePeriodPercentage(expiryDate) {
  const now = new Date().getTime();
  const gracePeriod = 7889400000;
  const timeUtilGraceEnds = expiryDate * 1000 + gracePeriod;
  const gracePeriodExact = timeUtilGraceEnds - now;
  const gInPercent = gracePeriod / 100;
  return gracePeriodExact / gInPercent;
}
function checkFilters(
  name,
  startWith,
  contains,
  notContains,
  endWith,
  minLength,
  maxLength
) {
  const isNotContains = notContains ? !name.includes(notContains) : true;
  const isMinLength = minLength ? name.length >= minLength : true;
  const isMaxLength = maxLength ? name.length <= maxLength : true;
  if (
    name.startsWith(startWith) &&
    name.endsWith(endWith) &&
    name.includes(contains) &&
    isNotContains &&
    isMinLength &&
    isMaxLength
  ) {
    return name;
  }
}

export async function performBulkSearch(searchCriteria) {
  try {
    let bulkSearchResults = [];

    // const invalidCharsForcedFilter = invalidChars.join("");

    const suffix = searchCriteria?.suffix;
    const prefix = searchCriteria?.prefix;
    const startWith = searchCriteria?.startWith || "";
    const contains = searchCriteria?.contains || "";
    const notContains = searchCriteria?.notContains || "";
    const endWith = searchCriteria?.endWith || "";
    const minLength = +searchCriteria?.minLength || 0;
    const maxLength = +searchCriteria?.maxLength || 50;
    const toFind = searchCriteria?.names
      ?.replaceAll("\n", ",")
      ?.replaceAll(" ", "")
      ?.replaceAll(";", ",")
      ?.replaceAll("<br>", ",")
      ?.replaceAll(".eth", "")
      ?.split(",")
      ?.map((d) => {
        // const invalidCharDetect = [...d].filter((c) => {
        //   if (invalidCharsForcedFilter.includes(c) === true) {
        //     return true;
        //   }
        //   return false;
        // });
        // if (invalidCharDetect.length > 0) {
        //   [...d].forEach((c) => {
        //     if (invalidCharsForcedFilter.includes(c)) {
        //       d = d.replaceAll(c, "");
        //     }
        //   });
        // }
        // if ([...d].at(-1) === ".") d = d.replaceAll(".", "");
        if (skipNormalisation === true && suffix && prefix && d) {
          return prefix + d + suffix;
        } else if (skipNormalisation === true && suffix && d) {
          return d + suffix;
        } else if (skipNormalisation === true && prefix && d) {
          return prefix + d;
        } else if (!skipNormalisation && suffix && prefix && d) {
          return prefix + d.toLowerCase() + suffix;
        } else if (!skipNormalisation && suffix && d) {
          return d.toLowerCase() + suffix;
        } else if (!skipNormalisation && prefix && d) {
          return prefix + d.toLowerCase();
        } else {
          return skipNormalisation === true ? d : d.toLowerCase();
        }
      })
      ?.filter((d) => d.length > 2);

    const filteredToFind = toFind
      ?.map((d) => {
        const nameStartWith = checkFilters(
          d,
          startWith,
          contains,
          notContains,
          endWith,
          minLength,
          maxLength
        );
        const normalizeData = normalize(nameStartWith);
        return normalizeData.isNameValid
          ? normalizeData.normalizedName
          : normalizeData.orignalName;
      })
      ?.filter((d) => d.length > 2);
    for (const f of filteredToFind) {
      const normalizeData = normalize(f.toLowerCase());
      const fData = {
        labelName: normalizeData.isNameValid
          ? normalizeData.normalizedName
          : normalizeData.orignalName,
      };
      let theToken;

      const cleanedEnsName = fData.labelName;
      theToken = ethers.BigNumber.from(
        ethers.utils.keccak256(ethers.utils.toUtf8Bytes(cleanedEnsName))
      ).toString();
      fData.token = theToken;
      bulkSearchResults.push(fData);
    }

    const allTokens = bulkSearchResults.map((d) => d.token);
    let tokensInfoPromise;

    if (allTokens.length > 1000) {
      let arrsOfTokens = [];
      let i = 0;
      while (i < allTokens.length) {
        arrsOfTokens.push(allTokens.slice(i, (i += 100)));
      }

      tokensInfoPromise = Promise.all(
        arrsOfTokens.map((arr) => getTokensData(arr))
      );
    } else {
      tokensInfoPromise = getTokensData(allTokens);
    }

    const [searchResult, tokensData] = await Promise.all([
      findDomains(filteredToFind),
      tokensInfoPromise,
    ]);
    const allTokensData =
      tokensData?.length > 1
        ? tokensData.map((t) => t.data.data).flat()
        : tokensData.data.data;
    const listedItems = allTokensData?.filter((t) => t?.listing);
    bulkSearchResults.forEach((d) => {
      const isNotAvailable = searchResult.find(
        (r) => r.labelName === d.labelName
      );
      const tokenData = allTokensData?.find((a) => a?._id === d.token);
      const normalizeData = normalize(d.labelName);
      if (isNotAvailable) {
        d.expiry = moment.utc(isNotAvailable.expiryDate * 1000);
        const premiumPeriodEndsAt = moment
          .utc(isNotAvailable.expiryDate * 1000)
          .add(111, "days")
          .toDate();
        const gracePeriodEndsAt = moment
          .utc(isNotAvailable.expiryDate * 1000)
          .add(90, "days")
          .toDate();
        const isInGrace =
          moment() > moment.utc(isNotAvailable.expiryDate * 1000) &&
          moment() < gracePeriodEndsAt;
        const isPremium =
          gracePeriodEndsAt < moment() && moment() < premiumPeriodEndsAt;
        d.isAvailable = gracePeriodEndsAt < moment() ? true : false;
        d.isPremium = isPremium;
        d.premiumDate = premiumPeriodEndsAt;
        d.isValid = normalizeData.isNameValid;
        d.isInGrace = isInGrace;

        // const gPeriod = calculateGracePeriodPercentage(parseInt(d.expiry, 10));
        // d.gracePeriodPercent =
        //   gPeriod < -100 ? undefined : 100 - Math.abs(gPeriod);
      } else {
        d.isAvailable = true;
        d.isValid = normalizeData.isNameValid;
      }
      d.clubs = tokenData?.clubs || null;
      d.listing = tokenData?.listing || null;
    });
    return [bulkSearchResults, listedItems];
  } catch (e) {
    console.log(e);
    throw e;
  }
}
export async function findDomains(
  domains,
  previousResult = [],
  skip = 0,
  sliceDomains = false
) {
  let searchDomains = domains;
  const start = (skip - 1) * 1000;
  if (sliceDomains) {
    searchDomains = domains.slice(start, skip * 1000);
    if (start > domains.length) {
      sliceDomains = false;
    }
  }
  const query = gql`
    # query ($domainNames: [String!], $expiryDate: Int) {
    query ($domainNames: [String!]) {
      registrations(
        first: 1000
        where: { labelName_in: $domainNames } # where: { labelName_in: $domainNames, expiryDate_gt: $expiryDate }
      ) {
        id
        labelName
        expiryDate
        registrationDate
        domain {
          id
          createdAt
          labelName
          labelhash
          events {
            id
            blockNumber
            transactionID
          }
        }
      }
    }
  `;
  const names = await request(url, query, {
    domainNames: searchDomains,
    // expiryDate: moment().subtract(90, "days").unix(),
  });
  let allTheResult = [...previousResult, ...names.registrations];
  if (
    (names?.registrations.length === 1000 || sliceDomains) &&
    start < domains.length
  ) {
    if (names?.registrations.length === 1000 && skip === 0) {
      allTheResult = [...previousResult];
    }
    return findDomains(domains, allTheResult, skip + 1, true);
  }
  return allTheResult;
}

export async function findDomainByToken(token) {
  const labelHash = convertTokenToHex(token);
  // if the name wrapped it is not labelHash actually. it is id/nameHash.
  // if the name is not wrapped then it is labelHash.
  const GET_LABEL_NAME = gql`
    query($tokenId: String!){
        domains(first:1, where:{
                and: [
                  { parent_: {name: "eth"} },
                  {
                    or: [
                      { id: $tokenId},
                      { labelhash: $tokenId }
                    ]
                  }
                ]
              }){
            id
            name
            labelhash
            labelName
            expiryDate
            registration {
              id
              expiryDate
              registrationDate
            }
            wrappedDomain {
              expiryDate
              id
              name
              owner {
                id
              }
            }
            parent {
              id
              labelName
              labelhash
            }
        }
    }`;

  // { domains: [ { name: 'vitalik.eth' } ] }
  let ensname = await request(url, GET_LABEL_NAME, {tokenId: labelHash}).catch((e) => console.log(e));
  return ensname;
}

export async function findDomainsByWalletAddress(address) {
  const theAddress = address?.toLowerCase();
  const query = gql`
    query ($address: String!) {
      domains(where: { owner_: { id: $address } }) {
        name
      }
    }
  `;
  const names = await request(url, query, {
    address: theAddress,
  });
  return names;
}

export async function resolveOwnerAddress(ensName) {
  if (!ensName.includes(".eth")) {
    ensName = `${ensName}.eth`;
  }
  const query = gql`
    query ($name: String!) {
      domains(where: { name: $name }) {
        id
        name
        wrappedDomain {
          owner {
            id
          }
        }
        registration {
          registrant {
            id
          }
        }
        resolvedAddress {
          id
        }
      }
    }
  `;
  const domains = await request(url, query, {
    name: ensName,
  });

  let ownerAddress;
  if (domains?.domains?.length > 0) {
    ownerAddress = domains.domains[0].resolvedAddress?.id;
    if (domains.domains[0].wrappedDomain?.owner) {
      ownerAddress = domains.domains[0].wrappedDomain?.owner.id;
    }
  }
  return { ownerAddress, data: domains };
}

export async function resolveOwnerAddresses(ensNames) {
  const list = ensNames?.map((ensName) => {
    if (!ensName.includes(".eth")) {
      ensName = `${ensName}.eth`;
    }
    return ensName;
  });
  const query = gql`
    query ($names: [String!]!) {
      domains(where: { name_in: $names }) {
        id
        labelhash
        name
        wrappedDomain {
          owner {
            id
          }
        }
        registration {
          registrant {
            id
          }
        }
        resolvedAddress {
          id
        }
      }
    }
  `;
  const domains = await request(url, query, {
    names: list,
  });
  return list.map((name) => {
    const domain = domains.domains.find((d) => d.name === name);
    const ownerAddress = domain.wrappedDomain?.owner
      ? domain.wrappedDomain?.owner.id
      : domain.registration.registrant?.id;
    return { labelhash: domain.labelhash, name, ownerAddress };
  });
}

export function normalize(name) {
  const filtername = name
    ?.replace("-eth", ".eth")
    ?.replace(".eth⚠️", ".eth")
    ?.replace(".eth ⚠️", ".eth");
  let isNameValid = false;
  let isNameNormalized = true;
  let nameNeedsBeautification = false;
  let normalizedName = "";
  let beautifiedName = "";
  let normalizationError = "";
  let bestDisplayName = filtername;

  try {
    normalizedName = ens_normalize(filtername);
    bestDisplayName = normalizedName;
    isNameValid = true;
    isNameNormalized = filtername === normalizedName;
    beautifiedName = ens_beautify(normalizedName);
    nameNeedsBeautification = normalizedName !== beautifiedName;
    if (nameNeedsBeautification) {
      bestDisplayName = beautifiedName;
    }
  } catch (e) {
    normalizationError = e.toString();
  }

  return {
    isNameValid,
    isNameNormalized,
    nameNeedsBeautification,
    normalizedName,
    beautifiedName,
    normalizationError,
    bestDisplayName,
    orignalName: filtername,
  };
}

export const getIconUrl = (sourceDomain) => {
  switch (sourceDomain) {
    case "opensea.io":
    case "OpenSea":
      return "/opensea.svg";
    case "looksrare.org":
    case "LooksRare":
      return "https://raw.githubusercontent.com/reservoirprotocol/indexer/v5/src/models/sources/looksrare-logo.svg";
    case "x2y2.io":
    case "X2Y2":
      return "/x2.svg";
    // return "https://raw.githubusercontent.com/reservoirprotocol/indexer/v5/src/models/sources/x2y2-logo.png";
    case "Didex.xyz":
      return "un.svg";
    case "Powersweep.metastreet.xyz":
      return "un.svg";
    case "atomic0":
      return "atomic0.svg";
    case 'element.market"':
      return "element.png";
    case "ens.domains":
      return "ens.svg";
    case "rarible.com":
      return "rarible.svg";
    case "Element: Buy NFTs, Crypto Collectibles, GameFi Asset":
      return "element.png";
    case "Rarible – Create, sell or collect digital items secured with #blockchain":
      return "rarible.svg";
    default:
      return lg;
  }
};
