import axios from "axios";
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getdata = (searchCriteria, page, searchClubMode, s) => {
  const options = s ? { ...config, signal: s } : config;

  const endpoint =
    searchClubMode === "available"
      ? "availableNames"
      : searchClubMode === "listing"
        ? "listings"
        : "";
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}${endpoint}?${new URLSearchParams(
      searchCriteria
    ).toString()}`,
    options
  );
};

export const sentEmail = (email) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}user`, {
    email,
  });
};
export const getOptionsData = (club) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}clubInfo?club=${club}`);
};

export const getAvailableData = (searchCriteria, page) => {
  searchCriteria.page = page;
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}availableNames?${new URLSearchParams(
      searchCriteria
    ).toString()}`,
    config
  );
};

export const getAverageSellPrice = (searchCriteria) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}averageSale?${new URLSearchParams(
      searchCriteria
    ).toString()}`,
    config
  );
};

export const getTopClubs = () => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}topClubs`);
};

export const getNewClubs = () => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}newClubs`);
};

export const getTopSales = (searchCriteria) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}topSales?${new URLSearchParams(
      searchCriteria
    ).toString()}`
  );
};

export const getTopGrowingClub = () => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}topGrowingClub`);
};

export const getPopularClubs = () => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}popularClubs`);
};

export const dailyVolume = (period, clubs) => {
  let clubsParams = clubs.map((c) => "&clubs=" + c).join("");
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}dailyVolume?period=${period}${clubsParams}`
  );
};

export const getTokenInfo = (token) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}token/${token}`);
};

export const getTokenInfoV2 = (token) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}token/${token}/details`);
};

export const getMyEns = (myAddress, searchCriteria = {}, page = 1) => {
  searchCriteria.page = page;
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}myens/${myAddress}?${new URLSearchParams(
      searchCriteria
    ).toString()}`
    // `http://localhost:5000/api/v1/sale/myens/${myAddress}`
  );
};


export const getSearchTerm = (searchValue) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}search?term=${searchValue}`
  );
};

export const getListingStats = (searchCriteria) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}listing-stats?${new URLSearchParams(
      searchCriteria
    ).toString()}`,
    config
  );
};

export const getAvailableStats = (searchCriteria) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}available-stats?${new URLSearchParams(
      searchCriteria
    ).toString()}`,
    config
  );
};

export const getTokensData = (tokens) => {
  // let tokensParams = tokens.map((t) => "&tokens=" + t).join("");
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}tokens`
    // `${process.env.REACT_APP_BASE_URL}tokens?tokens=${tokensParams}`
    // `http://localhost:5000/api/v1/sale/tokens?tokens=${tokensParams}`
    // `http://localhost:5000/api/v1/sale/tokens`
    , { tokens }
  );
};

export const checkTokensRegistrations = (tokenIds) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}token/registration/check`,
    {
      tokenIds
    }
  );
}
// checkauth
export const checkAuth = () => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}checkauth`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("USER_TOKEN")}`
    }
  });
};
export const getNonce = () => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}nonce`);
};
export const authentication = (data) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}authentication`, { ...data });
};
export const logOut = () => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}logout`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("USER_TOKEN")}`
    }
  });
};

export const getInvited = () => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}getInvited`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("USER_TOKEN")}`
    }
  });
};
export const inviteMember = (data) => {
  
  return axios.post(`${process.env.REACT_APP_BASE_URL}invite`, { ...data }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("USER_TOKEN")}`
    }
  });
};
export const updateTokenDescription = (token, data) => {
  
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}token/${token}/updateDetails`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}`,
      },
    }
  );
};
export const updateTokenImage = (token, data) => {
  
  return axios.post(`${process.env.REACT_APP_BASE_URL}token/${token}/updateImage`, data, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("USER_TOKEN")}`,
    }
  });
};