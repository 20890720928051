// import { DomainTypeEnum } from "../models/domains";
// import { PagesEnum } from "../models/states/pages-interfaces";

export const generalConfigurations = {
  version: "0.1.0",
  defaultLanguage: "en",
  //   defaultPage: PagesEnum.HOME,
  maximumDecimalInPrices: 8,
  maxAttemptsToCheckoutData: 10,
  sessionTimeout: 6000 * 15,
  maxRPCCallRetries: 10,
  timeUntilImageLoadErrors: 3000,
  timeToUpdateEthUSDRate: 60000,
  timeToUpdateCheckoutPipe: 3000,
  timeToUpdateRegistrationGasPrice: 5000,
  timeUntilCheckoutExpires: 3000,
  maxBlogsOnHome: 3,
  maxConfirmationsUntilTxFinal: 1,
  declarePaymentStatusUnkownUntilXFailedDataFetches: 5,
  gasLimitBuffer: 1000000,
  maxCheckoutRetryUntilRehydrateProcessId: 5,
  maxIpfsSubscriptionRetries: 5,
  maxDomainsToRegister: 150,
  maxYearsRegistration: 1000,
  maxTotalCostBuffer: 105,
  maxDomainSearch: {
    ENS: 1000,
    LNR: 100,
  },
  defaultCurrency: "ETH",
  enableCustomRPC: true,
  frontpageBlogsFeed:
    "https://s3.amazonaws.com/revue/accounts/rss_feeds/000/527/060/original/rss_feed_527060.xml?1664402575",
  docsLink: "https://docs.predomain.xyz/",
  blockscanLink: "https://chat.blockscan.com/index?a=",
  nftyChatLink: "https://nftychat.xyz/dm/",
  appName: "predomain",
  appStagingName: "staging.predomain",
  trezorManifest: {
    email: "predomain@skiff.com",
    appUrl: "https://predomain.eth.limo",
  },
  // appMeta: {
  //   description: "Decentralised ENS Search, Registration, & Marketplace",
  //   url: "https://predomain.eth.limo",
  //   icons: ["https://ecanvas.eth.limo/assets/logo.png"],
  //   name: "Predomain.eth",
  // },
  enabledTools: {
    category: true,
    management: true,
    canvas: true,
    registration: true,
    profile: true,
    domain: true,
  },
  enablePoapResolution: false,
  poapRequiredTools: {
    category: {
      required: false,
      poapId: null,
      allowedIds: null,
    },
    management: { required: false, poapId: null, allowedIds: null },
    canvas: { required: false, poapId: null, allowedIds: null },
    registration: { required: false, poapId: null, allowedIds: null },
    profile: { required: false, poapId: null, allowedIds: null },
    domain: { required: false, poapId: null, allowedIds: null },
  },
  wallectConnectSupportedWallets: [
    "rainbow",
    "metamask",
    "argent",
    "trust",
    "trezor",
    "imtoken",
    "pillar",
    "coinbase",
  ],
  //   domainsCanBeRegistered: [DomainTypeEnum.ENS],
  domainExtensions: {
    ENS: ".eth",
    LNR: ".og",
  },
  //   domainNormalisationRequired: [DomainTypeEnum.ENS],
  domainTldTitles: {
    ENS: "ENS - Ethereum Name Service",
    LNR: "LNR - Linagee Name Registrar",
  },
  categoiesDataSourceFallback: "https://localhost:42069",
  categoriesUseFallback: true,
  categoryChartTimeRange: 2629800000,
  categoriesDomain: "categories.predomain.eth",
};
