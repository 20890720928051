import { createContext, useState, useEffect } from "react";
export const CartContext = createContext({
  regCartItems: [],
  addOneToRegCart: () => {},
  removeOneFromRegCart: () => {},
  deleteAllFromRegCart: () => {},
  // getTotalCostForRegCart: () => {},
  addAllToRegCart: () => {},
  addFilteredItemsToRegCart: () => {},
  buyCartItems: [],
  addOneToBuyCart: () => {},
  removeOneFromBuyCart: () => {},
  deleteAllFromBuyCart: () => {},
  getTotalCostForBuyCart: () => {},
  addAllToBuyCart: () => {},
  addFilteredItemsToBuyCart: () => {},
  sellBagItems: [],
  addOneToSellBag: () => {},
  removeOneFromSellBag: () => {},
  deleteAllFromSellBag: () => {},
  getTotalCostForSellBag: () => {},
  addAllToSellBag: () => {},
  addFilteredItemsToSellBag: () => {},
  getTotalNetForSellBag: () => {}
});

export function CartProvider({ children }) {
  const [regCartProducts, setRegCartProducts] = useState([]);
  const [buyCartProducts, setBuyCartProducts] = useState([]);
  const [sellBagProducts, setSellBagProducts] = useState([]);

  // [ { id: 1 , quantity: 3 }, { id: 2, quantity: 1 } ]

  // function getProductQuantity(id) {
  //   const quantity = regCartProducts.find(
  //     (product) => product.id === id
  //   )?.quantity;

  //   if (quantity === undefined) {
  //     return 0;
  //   }

  //   return quantity;
  // }
  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === "bulk-reg-cart") {
      setRegCartProducts(JSON.parse(newValue));
    }
    if (key === "buy-cart") {
      setBuyCartProducts(JSON.parse(newValue));
    }
    if (key === "sell-bag") {
      setSellBagProducts(JSON.parse(newValue));
    }
  };

  useEffect(() => {
    const regCartItems = localStorage.getItem("bulk-reg-cart");
    !regCartItems
      ? setRegCartProducts([])
      : setRegCartProducts(JSON.parse(regCartItems));
    const buyCartItems = localStorage.getItem("buy-cart");
    !buyCartItems
      ? setBuyCartProducts([])
      : setBuyCartProducts(JSON.parse(buyCartItems));
    const sellBagItems = localStorage.getItem("sell-bag");
    !sellBagItems
      ? setSellBagProducts([])
      : setSellBagProducts(JSON.parse(sellBagItems));
    window.addEventListener("storage", onStorageUpdate);
    // console.log("main")
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);
  // useEffect(() => {
  //   localStorage.setItem("bulk-reg-cart", JSON.stringify(regCartProducts));
  //   console.log("reg")
  // }, [regCartProducts.length]);
  // useEffect(() => {
  //   localStorage.setItem("buy-cart", JSON.stringify(buyCartProducts));
  //   // window.addEventListener("storage", onStorageUpdate);
  //   console.log("buy")
  //   // return () => {
  //   //   window.removeEventListener("storage", onStorageUpdate);
  //   // };
  // }, [buyCartProducts.length]);
  // useEffect(() => {
  //   localStorage.setItem("sell-bag", JSON.stringify(sellBagProducts));
  //   // window.addEventListener("storage", onStorageUpdate);
  //   console.log("sell")
  //   // return () => {
  //   //   window.removeEventListener("storage", onStorageUpdate);
  //   // };
  // }, [sellBagProducts.length]);

  function addOneToSellBag(token) {
    const newBagItems = [...sellBagProducts, token];
    localStorage.setItem("sell-bag", JSON.stringify(newBagItems));
    setSellBagProducts(newBagItems);
  }
  
  function addFilteredItemsToSellBag(items) {
    setSellBagProducts([...items]);
    localStorage.setItem("sell-bag", JSON.stringify([...items]));
  }
  
  function addAllToSellBag(items) {
    const newBagItems = [...sellBagProducts, ...items];
    setSellBagProducts(newBagItems);
    localStorage.setItem("sell-bag", JSON.stringify(newBagItems));
  }
  
  function removeOneFromSellBag(token) {
    setSellBagProducts((sellBagProducts) =>
    sellBagProducts.filter((currentProduct) => {
      return currentProduct.token !== token;
    })
    );
    localStorage.setItem("sell-bag", JSON.stringify(sellBagProducts.filter((currentProduct) => {
      return currentProduct.token !== token;
    })));
  }
  
  function deleteAllFromSellBag() {
    setSellBagProducts([]);
    localStorage.setItem("sell-bag", JSON.stringify([]));
  }

  function getTotalCostForSellBag(prices) {
    let totalCost = 0;
    if (prices) {
      prices?.map((price) => {
        return price ? (totalCost += price) : (totalCost += 0);
      });
    }

    return totalCost;
  }

  function getTotalNetForSellBag() {
    let total = 0;
    const sellBagItems = JSON.parse(localStorage.getItem("sell-bag"));

    sellBagItems?.forEach((item) => {
      total += item.netPrice;
    })
    return total;
  }

  /////////////////////// buy cart functions ///////////////

  function addOneToBuyCart(token) {
    const newCartItems = [...buyCartProducts, token];
    setBuyCartProducts(newCartItems);
    localStorage.setItem("buy-cart", JSON.stringify(newCartItems));
  }
  
  function addFilteredItemsToBuyCart(items) {
    setBuyCartProducts([...items]);
    localStorage.setItem("buy-cart", JSON.stringify([...items]));
  }
  
  function addAllToBuyCart(items) {
    const newCartItems = [...buyCartProducts, ...items];
    setBuyCartProducts(newCartItems);
    localStorage.setItem("buy-cart", JSON.stringify(newCartItems));
  }
  
  function removeOneFromBuyCart(token) {
    setBuyCartProducts((buyCartProducts) =>
    buyCartProducts.filter((currentProduct) => {
      return currentProduct.token !== token && currentProduct.wrappedToken !== token;
    })
    );
    localStorage.setItem("buy-cart", JSON.stringify(buyCartProducts.filter((currentProduct) => {
      return currentProduct.token !== token && currentProduct.wrappedToken !== token;
    })));
  }
  
  function deleteAllFromBuyCart() {
    setBuyCartProducts([]);
    localStorage.setItem("buy-cart", JSON.stringify([]));
  }

  function getTotalCostForBuyCart(prices) {
    let totalCost = 0;
    if (prices) {
      prices?.map((price) => {
        return price ? (totalCost += price) : (totalCost += 0);
      });
    }
    return totalCost;
  }

  ///////////// reg cart functions ///////////////

  function addOneToRegCart(name) {
    const newCartItems = [...regCartProducts, name];
    setRegCartProducts(newCartItems);
    localStorage.setItem("bulk-reg-cart", JSON.stringify(newCartItems));
    // if (quantity === 0) {
      //   // product is not in cart
      //   setRegCartProducts([
        //     ...regCartProducts,
        //     {
          //       id: id,
          //       quantity: 1,
          //     },
          //   ]);
          // } else {
            //   // product is in cart
    //   // [ { id: 1 , quantity: 3 }, { id: 2, quantity: 1 } ]    add to product id of 2
    //   setRegCartProducts(
      //     regCartProducts.map(
        //       (product) =>
        //         product.id === id // if condition
        //           ? { ...product, quantity: product.quantity + 1 } // if statement is true
        //           : product // if statement is false
        //     )
    //   );
    // }
  }
  
  function addFilteredItemsToRegCart(items) {
    setRegCartProducts([...items]);
    localStorage.setItem("bulk-reg-cart", JSON.stringify([...items]));
  }
  
  function addAllToRegCart(items) {
    const newCartItems = [...regCartProducts, ...items];
    setRegCartProducts(newCartItems);
    localStorage.setItem("bulk-reg-cart", JSON.stringify(newCartItems));
  }
  
  function removeOneFromRegCart(labelName) {
    setRegCartProducts((regCartProducts) =>
    regCartProducts.filter((currentProduct) => {
      return currentProduct.labelName !== labelName;
    })
    );
    localStorage.setItem("bulk-reg-cart", JSON.stringify(regCartProducts.filter((currentProduct) => {
      return currentProduct.labelName !== labelName;
    })));
    // const quantity = getProductQuantity(id);
    
    // if (quantity == 1) {
    //   deleteAllFromRegCart(id);
    // } else {
      //   setRegCartProducts(
        //     regCartProducts.map(
          //       (product) =>
          //         product.id === id // if condition
          //           ? { ...product, quantity: product.quantity - 1 } // if statement is true
          //           : product // if statement is false
          //     )
    //   );
    // }
  }
  
  function deleteAllFromRegCart() {
    // [] if an object meets a condition, add the object to array
    // [product1, product2, product3]
    // [product1, product3]
    // setRegCartProducts((regCartProducts) =>
    //   regCartProducts.filter((currentProduct) => {
      //     return currentProduct.id != id;
      //   })
      // );
      setRegCartProducts([]);
      localStorage.setItem("bulk-reg-cart", JSON.stringify([]));
    }
    
  // function getTotalCostForRegCart() {
  //   let totalCost = 0;
  //   regCartProducts.map((cartItem) => {
  //     // const productData = getProductData(cartItem.id);
  //     totalCost += cartItem.price;
  //   });
  //   return totalCost;
  // }

  const contextValue = {
    regCartItems: regCartProducts,
    addOneToRegCart,
    removeOneFromRegCart,
    deleteAllFromRegCart,
    // getTotalCostForRegCart,
    addAllToRegCart,
    addFilteredItemsToRegCart,
    buyCartItems: buyCartProducts,
    addOneToBuyCart,
    removeOneFromBuyCart,
    deleteAllFromBuyCart,
    getTotalCostForBuyCart,
    addAllToBuyCart,
    addFilteredItemsToBuyCart,
    sellBagItems: sellBagProducts,
    addOneToSellBag,
    removeOneFromSellBag,
    deleteAllFromSellBag,
    getTotalCostForSellBag,
    addAllToSellBag,
    addFilteredItemsToSellBag,
    getTotalNetForSellBag,
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
}

export default CartProvider;
// function loadItems(key) {
//   return localStorage.getItem(key);
// }

// export function loadItemsAndFeed(key) {
//   const items = loadItems(key);
//   if (items !== null && items !== "null" && items !== "" && items !== "[]") {
//     return JSON.parse(items);
//   }
//   return [];
// }

// export function addItem(key, name) {
//   const cartItems = loadItemsAndFeed(key);
//   const newCartItems = [...cartItems, name];
//   localStorage.setItem(key, JSON.stringify(newCartItems));
//   return newCartItems;
// }
// export function addFilteredItemsToRegCart(key, items) {
//   localStorage.setItem(key, JSON.stringify(items));
//   return items;
// }

// export function addAllToRegCart(key, items) {
//   const cartItems = loadItemsAndFeed(key);
//   const newCartItems = [...cartItems, ...items];
//   localStorage.setItem(key, JSON.stringify(newCartItems));
// }

// export function deleteAll(key) {
//   localStorage.setItem(key, []);
// }

// export function countItems(key) {
//   const cartItems = loadItemsAndFeed(key);
//   return cartItems.length;
// }
