import { ethers } from "ethers";
import BN from 'bn.js';

export function roundUp(num, roundTo) {
  return Math.round(num / roundTo) * roundTo;
}

export function testJSON(json) {
  const str = json.toString();
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function testUrl() {
  const expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const test = new RegExp(expression);
  return test;
}

export function checksumEtheruemAddress(address) {
  try {
    return (
      ethers.utils.getAddress(address).toLowerCase() === address.toLowerCase()
    );
  } catch (e) {
    return false;
  }
}

export function commafied(num) {
  if (num.toString().indexOf(".") <= -1) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    const parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
}

export function prependToArray(value, array) {
  var newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

export function toHex(str) {
  const rb = new TextEncoder().encode(str);
  let r = "";
  for (const b of rb) {
    r += ("0" + b.toString(16)).slice(-2);
  }
  return r;
}

export function testNumeric() {
  const reg = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);
  return reg;
}

export function testAlphaNumeric() {
  const reg = new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$");
  return reg;
}

export function testAlpha() {
  const reg = new RegExp("^[A-Za-z]+$");
  return reg;
}

export function testEmoji() {
  const reg = new RegExp(/\p{Extended_Pictographic}/u);
  return reg;
}

export function testIntNumeric() {
  const reg = new RegExp("^[0-9]+$");
  return reg;
}

export function parseXml(xml) {
  var dom = null;
  try {
    dom = new DOMParser().parseFromString(xml, "text/xml");
  } catch (e) {
    dom = null;
  }
  return dom;
}

export function convertTokenToHex (token) {
  const hexString = `0x${new BN(token).toString(16).padStart(64, 0)}`;
  return hexString;
};