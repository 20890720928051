import { List } from "react-content-loader";
import Box from "@mui/material/Box";
const Loader = () => {
    return (
        <Box sx={{
            width:'100%',
            display:'flex',
            justifyContent:'center'

            
        }}><List 

        width={300}
        height={160}
        viewBox="0 0 300 160"

          /></Box>
    )
}

export default Loader;
